import React, { Component } from "react";
import { Collapse } from "antd";
import Pusher from "react-pusher";
import VerifyUser from "./VerifyUser";
import PatientDetailForm from "./PatientDetail/index";
import Teleconsultation from "../VideoCall/Teleconsultation";
import AllowPermissionInfoModal from "../VideoCall/AllowPermissionInfoModal";
import { encryptId } from "../../../helpers/utils";
import { ImPhone } from "react-icons/im";
import { BsFillPersonFill } from "react-icons/bs";
import { MdPayment } from "react-icons/md";
import { IoIosVideocam } from "react-icons/io";
import { fetchAppointmentDetail } from "../../Appointments/bookAppointmentService";
import { setPatientBriefDetail } from "../../../helpers/appointments";
import VideoCallPendingPaymentModal from "./VideoCallPendingPaymentModal";

const { Panel } = Collapse;

const genIcon = (type) => {
  if (type === "phone") {
    return <ImPhone size={15} />;
  } else if (type === "patient") {
    return <BsFillPersonFill size={20} />;
  } else if (type === "payment") {
    return <MdPayment size={17} />;
  } else if (type === "appointment") {
    return <IoIosVideocam size={18} />;
  }
};

class VideoCallAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentId: null,
      isProcessing: false,
      appointmentDetail: null,
      currentStep: "verification",
      responseCheckCounter: 0,
      callEnded: false,
      isCovidClinic: false,
      userVerified: false,
      showAllowPerModal: false,
      doctorId: null,
      verifyId: null,
      clinicDoctorId: null,
      clinicId: null,
      mobileNumber: null,
      clinicPatientList: [],
      clinicPatient: null,
      confirmed: false,
      countryCode: "91",
      apptDate: null,
      doctorDetail: null,
      fetchingDoctor: false,
      stepNo: 1,
      paymentComplete: false,
      pendingOrderDetail: null, // in case user closes the payment modal
    };
    this.buttonRef = React.createRef();
  }

  /**
   * @function handleUpdateState
   * @description this function handles updating state value
   */
  handleUpdateState = (stateObj) => {
    this.setState({
      ...this.state,
      ...stateObj,
    });
  };

  handleUpdateStepNo = (stepNo) => {
    this.setState({ stepNo });
  };

  handleRefreshAppointment = (dataObj) => {
    this.setState({ appointmentId: dataObj?.appointment_id }, () => {
      this.handleFetchAppointmentDetail(dataObj?.appointment_id);
    });
  };

  handleFetchAppointmentDetail = (apptId) => {
    fetchAppointmentDetail(apptId).then((response) => {
      this.setState({ isProcessing: false });
      if (response.data.success) {
        const record = response.data.data;

        this.setState({
          appointmentDetail: record,
        });
      }
    });
  };

  /*toggleAllowPerModal
   * @description It will toggle modal
   */
  toggleAllowPerModal = () => {
    const { doctorId, clinicDoctorId, clinicId, apptDate } = this.props;
    const {
      mobileNumber,
      countryCode,
      appointmentId,
      verifyId,
      showAllowPerModal,
    } = this.state;

    if (showAllowPerModal) {
      localStorage.setItem("viewed_permission_modal", "viewed");
      window.open(
        `/video-call?did=${encryptId(doctorId)}&cdid=${encryptId(
          clinicDoctorId
        )}&clid=${encryptId(clinicId)}&vid=${encryptId(
          verifyId
        )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
          countryCode
        )}&dt=${apptDate}&cn=true&apid=${encryptId(appointmentId)}`,
        "_blank"
      );
    }
    this.setState({ showAllowPerModal: !showAllowPerModal });
  };

  handleJoinCall = () => {
    const isUserViewedModal = localStorage.getItem("viewed_permission_modal");
    if (isUserViewedModal === "not_viewed") this.toggleAllowPerModal();
    else {
      const { doctorId, clinicDoctorId, clinicId, apptDate } = this.props;
      const { mobileNumber, countryCode, appointmentId, verifyId } = this.state;

      window.open(
        `/video-call?did=${encryptId(doctorId)}&cdid=${encryptId(
          clinicDoctorId
        )}&clid=${encryptId(clinicId)}&vid=${encryptId(
          verifyId
        )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
          countryCode
        )}&dt=${apptDate}&cn=true&apid=${encryptId(appointmentId)}`,
        "_blank"
      );
    }
  };

  render() {
    const { doctorId, clinicDoctorId, clinicId, apptDate } = this.props;
    const {
      stepNo,
      mobileNumber,
      countryCode,
      verifyId,
      paymentComplete,
      appointmentId,
      showAllowPerModal,
      confirmed,
      appointmentDetail,
      pendingOrderDetail,
    } = this.state;

    console.log("appointmentDetail", appointmentDetail);
    return (
      <div className="verification-page-content">
        <Pusher
          channel={process.env.REACT_APP_PUSHER_CHANNEL}
          event={`appt_paid_${verifyId}`}
          onUpdate={this.handleRefreshAppointment}
        />
        <Collapse
          defaultActiveKey={["1"]}
          activeKey={stepNo}
          className="form-collapse"
        >
          <Panel
            header={verifyId ? `Verified` : "Verify mobile number"}
            key="1"
            showArrow={false}
            extra={genIcon("phone")}
            className="phone-pos"
          >
            <VerifyUser
              doctorId={doctorId}
              clinicDoctorId={clinicDoctorId}
              clinicId={clinicId}
              apptDate={apptDate}
              handleUpdateState={this.handleUpdateState}
              handleUpdateStepNo={this.handleUpdateStepNo}
              countryCode={countryCode}
            />
          </Panel>
          <Panel
            header={
              confirmed && appointmentDetail
                ? setPatientBriefDetail(appointmentDetail.patient_details)
                : "Add patient detail"
            }
            key="2"
            showArrow={false}
            extra={genIcon("patient")}
          >
            <PatientDetailForm
              mobileNumber={mobileNumber}
              countryCode={countryCode}
              clinicId={clinicId}
              clinicDoctorId={clinicDoctorId}
              verifyId={verifyId}
              handleUpdateState={this.handleUpdateState}
              handleUpdateStepNo={this.handleUpdateStepNo}
            />
          </Panel>
          <Panel
            header={
              paymentComplete && appointmentDetail
                ? `Paid ₹${appointmentDetail?.doctor_details?.telemedicine_fees}`
                : "Complete payment"
            }
            key="3"
            showArrow={false}
            extra={genIcon("payment")}
            className="payment-pos"
          >
            <VideoCallPendingPaymentModal
              handleUpdateState={this.handleUpdateState}
              handleUpdateStepNo={this.handleUpdateStepNo}
              pendingOrderDetail={pendingOrderDetail}
              paymentComplete={paymentComplete}
            />
            {/* <div className="d-flex">
              <div>
                {paymentComplete
                  ? "Payment done"
                  : "Redirecting to payment gateway"}
              </div>
              <div className="stage">
                <div className="dot-flashing"></div>
              </div>
            </div> */}
          </Panel>
          <Panel
            header="Start teleconsult"
            key="4"
            showArrow={false}
            extra={genIcon("appointment")}
            className="appt-pos"
          >
            {/* <a
              href=""
              onClick={() => {
                window.open(
                  `/video-call?did=${encryptId(doctorId)}&cdid=${encryptId(
                    clinicDoctorId
                  )}&clid=${encryptId(clinicId)}&vid=${encryptId(
                    verifyId
                  )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
                    countryCode
                  )}&dt=${apptDate}&cn=true&apid=${encryptId(appointmentId)}`,
                  "_blank"
                );
              }}
            >
              Join
            </a> */}
            <Teleconsultation
              appointmentId={appointmentId}
              handleUpdateState={this.handleUpdateState}
              handleJoinCall={this.handleJoinCall}
              mobileNumber={mobileNumber}
              countryCode={countryCode}
            />
          </Panel>
        </Collapse>

        <AllowPermissionInfoModal
          showAllowPerModal={showAllowPerModal}
          toggleAllowPerModal={this.toggleAllowPerModal}
        />
      </div>
    );
  }
}
export default VideoCallAppointment;
