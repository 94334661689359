import React, { Component } from 'react'
import ReviewsView from './ReviewsView';

class ReviewsContainer extends Component {
  render() {
    return (
      <ReviewsView />
    )
  }
}
export default ReviewsContainer;