import React from "react";
import "../reviews.css";
import moment from "moment";
import { perPage } from "../../Appointments/helpers";

export default class ReviewsView extends React.Component {
  render() {
    const { doctorDetails, loadMoreReviews, reviews, totalReviews, pageNo, totalPages, reviewRef } = this.props;
    

    return (
      <div id="review" ref={reviewRef}>
        <h1 className="font-size-16 font-weight-bold mb-15">Reviews</h1>

        {reviews && reviews.length > 0 && (
          <>
            <div className="review-btns-wrapper">
              <div className="review-btn-block">
                <div className="rb-top">
                  <span className="rb-logo kulcare"></span>
                  <span className="rb-rating-count">
                    {doctorDetails?.kulcare_rating}
                  </span>
                </div>
                <div className="rb-bottom">
                  <span className="rb-count">{reviews.length} reviews</span>
                </div>
              </div>

              {/* <div className="review-btn-block">
                <div className="rb-top">
                  <span className="rb-logo google"></span>
                  <span className="rb-rating-count">3.5</span>
                </div>
                <div className="rb-bottom">
                  <span className="rb-count">10 reviews</span>
                </div>
              </div> */}
            </div>

            <div className="reviews_wrapper">
              <ul className="reviews-wrapper">
                {reviews.map((reviewObj, index) => {
                  return (
                    <li className="review-wrapper" key={`${reviewObj.id}_${index}`}>
                      <div className="reviews-top-section">
                        <div
                          className="stars"
                          style={{ "--rating": reviewObj.rating }}
                          aria-label="Rating"
                        ></div>
                        {/* <div className="rating-text">4</div> */}
                        <div className="review-on">
                          via{" "}
                          <span
                            className={`rb-logo ${
                              reviewObj.type === "kulcare"
                                ? "kulcare"
                                : "google"
                            }`}
                          ></span>
                        </div>
                      </div>
                      <div className="review-text font-size-16">
                        {reviewObj.review}
                      </div>
                      <div className="review-by-block">
                        <div className="review-user font-size-14">
                          {reviewObj?.clinic_patient_details?.name}
                        </div>
                        <div className="review-date font-size-14">
                          {moment(reviewObj.created_at).format("DD MMM, YYYY")}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {totalPages > pageNo && (
              <div className="load-more-reviews-wrapper mt-10 font-color-blue">
                <a href="#" onClick={loadMoreReviews} className="font-weight-bold">Read more reviews</a>
              </div>
            )}
          </>
        )}

        {totalReviews === 0 && <div className="font-size-14">No reviews yet!</div>}
      </div>
    );
  }
}
