import React from "react";
import { Modal, Button } from "antd";
import { strings } from "../../helpers/locales";
import moment from "moment";
import VideoCallAppointment from "./VideoCall/VideoCallAppointment";

const BookTeleconsultationModal = ({
  showAvailabilityStatusModal,
  handleCloseStatusModal,
  showDocUnavialableInfo,
  selectedDoctor,
  availabilityDetail,
  showTelemedicineSteps,
  setShowTelemedicineSteps,
  handleGotoProfile,
}) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={showAvailabilityStatusModal}
      onCancel={handleCloseStatusModal}
      maskClosable={false}
      centered
    >
      <div className="appointment_main_wrapper">
        <h1 className="font-size-16 font-weight-bold">
          Book Teleconsult with{" "}
          <a href="#" onClick={handleGotoProfile} className="font-color-blue">
            {selectedDoctor.name ? `Dr. ${selectedDoctor.name}` : ""}
          </a>
        </h1>
        {availabilityDetail && (
          <div className="info_col_heading mb-0 rel-pos lh-18">
            <div className="live-dot-box start">
              <div
                className={`live-dot mr-5 ${
                  availabilityDetail.available ? "online" : "offline"
                }`}
              ></div>
            </div>
            <span>
              {moment(availabilityDetail.next_available_ts).format(
                "hh:mm a"
              ) === moment().format("hh:mm a") && `Next Availability: `}

              {moment(availabilityDetail.next_available_ts).format(
                "hh:mm a"
              ) === moment().format("hh:mm a") && (
                <span className="font-size-18 pl-4">Now</span>
              )}

              {moment(availabilityDetail.next_available_ts).format(
                "hh:mm a"
              ) !== moment().format("hh:mm a") && `Next Availability:`}
              {moment(availabilityDetail.next_available_ts).format(
                "hh:mm a"
              ) !== moment().format("hh:mm a") && (
                <span className="font-size-18 pl-4">
                  {moment(availabilityDetail.next_available_ts).format(
                    "hh:mm a"
                  )}
                </span>
              )}
            </span>
          </div>
        )}
        <div className="modal-sep mt-10 mb-10"></div>
        {showDocUnavialableInfo && (
          <div className="info_col_heading mb-0 rel-pos">
            <div className="live-dot-box start">
              <div className={`live-dot mr-5 offline`}></div>
            </div>
            <span>
              {selectedDoctor
                ? `Dr. ${selectedDoctor?.name} is currently not available`
                : `Doctor not available to take appointments`}
            </span>
          </div>
        )}

        {availabilityDetail && (
          <>
            {showTelemedicineSteps && (
              <VideoCallAppointment
                doctorId={selectedDoctor.id}
                clinicDoctorId={selectedDoctor?.clinic_doctor_id}
                clinicId={selectedDoctor?.clinic_id}
                apptDate={moment().format("YYYY-MM-DD")}
              />
            )}

            {!showTelemedicineSteps && (
              <div className="d-flex mt-20">
                {/* <Button
                  type={"primary"}
                  size="small"
                  onClick={() => {
                    setShowAvailabilityStatusModal(false);
                    window.open(
                      `/patient-teleconsult?did=${
                        selectedDoctor.id
                      }&cdid=${encryptId(
                        selectedDoctor?.clinic_doctor_id
                      )}&clid=${encryptId(
                        selectedDoctor?.clinic_id
                      )}&dt=${moment().format("YYYY-MM-DD")}`,
                      "_blank"
                    );
                  }}
                >
                  Test
                </Button> */}

                <Button
                  type={"primary"}
                  size="small"
                  onClick={() => {
                    setShowTelemedicineSteps(true);
                  }}
                >
                  {strings.ok}, {strings.book_appointment}
                </Button>

                <Button
                  type={"link"}
                  size="small"
                  onClick={handleCloseStatusModal}
                >
                  Close
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default BookTeleconsultationModal;
