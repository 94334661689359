import React, { Component } from "react";
import ProfileTopSectionComponent from "../CommonComponents/ProfileTopSection/ProfileTopSectionComponent";
import ReviewsContainer from "../CommonComponents/Reviews/ReviewsContainer";
import "./profile.css";
import AboutShowComponent from "../CommonComponents/About/AboutShowComponent";
import AwardsContainer from "./Awards/AwardsContainer";
import SpecialitiesView from "./Specialities/SpecialitiesView";
import ServicesShowContainer from "./Services/ServicesShowContainer";
import InsauranceShowContainer from "./Insurance/InsuranceShowContainer";

class HospitalProfile extends Component {
  render() {
    return (
      <div className="hospital-profile-container">
        <div className="hospital-section-left">
          <ProfileTopSectionComponent />
          <AboutShowComponent />
          <AwardsContainer />
          <SpecialitiesView />
        </div>
        <div className="hospital-section-right">
          <ServicesShowContainer />
          <ReviewsContainer />
          <InsauranceShowContainer />
        </div>
      </div>
    );
  }
}
export default HospitalProfile;
