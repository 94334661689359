import React from "react";

class ReviewsView extends React.Component {
  render() {
    const {
      reviewRef,
    } = this.props;

    return (
      <div id="hospital_reviews" ref={reviewRef}>
        <h1 className="font-size-16 font-weight-bold mb-15">Reviews</h1>

        <>
          <div className="review-btns-wrapper">
            <div className="review-btn-block">
              <div className="rb-top">
                <span className="rb-logo kulcare"></span>
                <span className="rb-rating-count">5.0</span>
              </div>
              <div className="rb-bottom">
                <span className="rb-count">100 reviews</span>
              </div>
            </div>
          </div>

          <div className="reviews_wrapper">
            <ul className="reviews-wrapper">
              <li className="review-wrapper">
                <div className="reviews-top-section">
                  <div
                    className="stars"
                    aria-label="Rating"
                    style={{ "--rating": 5 }}
                  ></div>
                  <div className="review-on">
                    via <span className="rb-logo google"></span>
                  </div>
                </div>
                <div className="review-text font-size-16">
                  Dr. Aastha listened my problem patiently and explained the
                  traetment plan very well. I specifically loved the queue and
                  token system with regular updates on my phone. I did not have
                  to wait in the clinic.
                </div>
                <div className="review-by-block">
                  <div className="review-user font-size-16">Lakshay Shama</div>
                  <div className="review-date font-size-16">June 08, 2021</div>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className="load-more-reviews-wrapper mt-10 font-color-blue">
            <a href="#" onClick={loadMoreReviews} className="font-weight-bold">
              Read more reviews
            </a>
          </div> */}
        </>
      </div>
    );
  }
}
export default ReviewsView;
