import React from "react";
import SelectSearch from "react-select-search";
import { Select, Input } from "antd";
import debounce from "lodash/debounce";

const { Search } = Input;
const { Option } = Select;
const appointmentTypes = [
  { name: "Telemedicine", value: "virtual" },
  { name: "In person", value: "in_person" },
];

function FilterContainer({
  selectedSpeciality,
  specialities,
  selectedLanguage,
  languages,
  selectedSortingOption,
  handleResetFilters,
  handleSpecialityChange,
  fuzzySearch,
  selectedAppointmentType,
  handleApptChange,
  handleLanguageChange,
  filterModal,
  isTelemed,
  handleCityChange,
  locations,
  selectedLocation,
  handleNameSearch,
  setSearchText,
  searchText,
  fetchCities,
  getCityOptions,
}) {
  return (
    <div className={`filters-content ${filterModal}`}>
      {isTelemed && (
        <div className={`filter-block sz-40`}>
          <Search
            placeholder="Type doctor's name hit enter to search"
            className="small_input"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onPressEnter={(e) => handleNameSearch(e)}
            onSearch={(e) => handleNameSearch(e)}
          />
        </div>
      )}
      {!isTelemed && (
        <div
          className={`filter-block speciality ${
            selectedSpeciality ? "selected" : ""
          }`}
        >
          <div className={"filter-section"}>
            <SelectSearch
              className="select-search small"
              options={specialities}
              value={selectedSpeciality}
              name="speciality"
              placeholder="Speciality"
              onChange={handleSpecialityChange}
              // filterOptions={fuzzySearch}
              // search
            />
          </div>
        </div>
      )}
      {!isTelemed && (
        <div
          className={`filter-block appointment-type ${selectedAppointmentType} ${
            selectedAppointmentType ? "selected" : ""
          }`}
        >
          <div className={"filter-section"}>
            <SelectSearch
              className="select-search small"
              options={appointmentTypes}
              value={selectedAppointmentType}
              name="appointmentType"
              placeholder="Appointment"
              onChange={handleApptChange}
            />
          </div>
        </div>
      )}

      <div
        className={`filter-block language ${
          selectedLanguage ? "selected" : ""
        }`}
      >
        <div className={"filter-section"}>
          <SelectSearch
            className="select-search small"
            options={languages}
            value={selectedLanguage}
            name="language"
            placeholder="All languages"
            onChange={handleLanguageChange}
            filterOptions={fuzzySearch}
            search
          />
        </div>
      </div>

      {isTelemed && locations && (
        <div
          className={`filter-block location ${
            selectedLocation && selectedLocation.name ? "selected" : ""
          }`}
        >
          <div className={"filter-section"}>
            <Select
              size="small"
              showSearch
              value={selectedLocation?.name}
              filterOption={false}
              className="full-width with-icon location"
              onSearch={debounce(fetchCities, 500)}
              onChange={handleCityChange}
              placeholder="Search city"
            >
              {locations &&
                locations.length > 0 &&
                locations.map((location) => (
                  <Option value={location.value} city={location}>
                    {location.name}
                  </Option>
                ))}
            </Select>
            {/* <SelectSearch
              // options={locations}
              value={selectedLocation && selectedLocation.name}
              name="location"
              placeholder="All cities"
              search
              // filterOptions={fuzzySearch}
              onChange={handleCityChange}
              className="select-search small"
              getOptions={() => fetchCities()}
              debounce={500}
            /> */}
          </div>
        </div>
      )}

      {(selectedAppointmentType ||
        selectedSpeciality ||
        selectedLanguage ||
        selectedSortingOption ||
        searchText ||
        selectedLocation) && (
        <div className="mt-5">
          <a
            href=""
            onClick={(e) => {
              if (e) e.preventDefault();
              handleResetFilters();
            }}
            className="font-color-blue ml-10 filter-reset-btn"
          >
            Clear
          </a>
        </div>
      )}
    </div>
  );
}

export default FilterContainer;
