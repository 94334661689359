import React from "react";
import "../reviews.css";
import ReviewsView from "./ReviewsView";
import { perPage } from "../../Appointments/helpers";
import {logMixpanelEventBasic} from "../../../helpers/utils";

const paginate = (items, page = 1, perPage = 2) => {
  const offset = perPage * (page - 1);
  return items.slice(offset, perPage * page);
};

export default class ReviewsContainer extends React.Component {
  state = {
    totalPages: 0,
    reviews: [],
    totalReviews: [],
    pageNo: 1,
  };

  componentDidMount(){
    if (this.props.doctorDetails) {
      this.setState({
        reviews:
          this.props.doctorDetails.doctor_reviews.length > 0
            ? paginate(this.props.doctorDetails.doctor_reviews)
            : [],
        totalPages: Math.ceil(
          this.props.doctorDetails.doctor_reviews.length / perPage
        ),
        totalReviews: this.props.doctorDetails.doctor_reviews.length,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.doctorDetails !== prevProps.doctorDetails) {
      this.setState({
        reviews:
          this.props.doctorDetails.doctor_reviews.length > 0
            ? paginate(this.props.doctorDetails.doctor_reviews)
            : [],
        totalPages: Math.ceil(
          this.props.doctorDetails.doctor_reviews.length / perPage
        ),
        totalReviews: this.props.doctorDetails.doctor_reviews.length,
      });
    }
  }

  loadMoreReviews = (e) => {
    if(e) e.preventDefault();
    const {reviews} = this.state;
    this.setState({ pageNo: this.state.pageNo + 1 }, () => {
      if (this.state.pageNo <= this.state.totalPages) {
        logMixpanelEventBasic("More Reviews Read", null, null, "Doctor Listing");
        this.setState({
          reviews: [...reviews, ...paginate(this.props.doctorDetails.doctor_reviews, this.state.pageNo)]
        })
        
      }
    });
  };

  render() {
    const { doctorDetails, reviewRef } = this.props;
    const { reviews, totalReviews, pageNo, totalPages } = this.state;
    return (
      <ReviewsView
        doctorDetails={doctorDetails}
        loadMoreReviews={this.loadMoreReviews}
        reviews={reviews}
        totalReviews={totalReviews}
        pageNo={pageNo}
        totalPages={totalPages}
        reviewRef={reviewRef}
      />
    );
  }
}
