import React, { Component } from "react";
import Pusher from "react-pusher";
import qs from "qs";
import { Spin } from "antd";
import {
  decryptId,
  encryptId,
  logMixpanelEventBasic,
} from "../../../helpers/utils";
import VerifyUser from "./VerifyUser";
import PatientDetailForm from "./PatientDetail/index";
import AllowPermissionInfoModal from "./AllowPermissionInfoModal";
import Teleconsultation from "./Teleconsultation/index";
import AppointmentCard from "./VideoAppointmentDetails/AppointmentCard";
import { getEsTeleDoctorDetail } from "../../../services";

class VideoCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentId: null,
      isProcessing: false,
      appointmentDetail: null,
      currentStep: "verification",
      responseCheckCounter: 0,
      callEnded: false,
      isCovidClinic: false,
      userVerified: false,
      showAllowPerModal: false,
      doctorId: null,
      verifyId: null,
      clinicDoctorId: null,
      clinicId: null,
      mobileNumber: null,
      clinicPatientList: [],
      clinicPatient: null,
      confirmed: false,
      countryCode: "91",
      apptDate: null,
      doctorDetail: null,
      fetchingDoctor: false,
    };
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    this.handleUpdateParams();
  }

  handleUpdateParams = () => {
    const parsedParams = qs.parse(this.props.location.search);

    let doctorId = parsedParams["?did"];
    let clinicDoctorId = parsedParams["cdid"];
    let clinicId = parsedParams["clid"];
    let verifyId = parsedParams["vid"];
    let mobileNumber = parsedParams["ph"];
    let confirmed = parsedParams["cn"];
    let appointmentId = parsedParams["apid"];
    let apptDate = parsedParams["dt"];

    this.setState(
      {
        doctorId: doctorId ? decryptId(doctorId) : null,
        clinicDoctorId: clinicDoctorId ? decryptId(clinicDoctorId) : null,
        clinicId: clinicId ? decryptId(clinicId) : null,
        verifyId: verifyId ? decryptId(verifyId) : null,
        mobileNumber: mobileNumber ? decryptId(mobileNumber) : null,
        confirmed: confirmed || false,
        appointmentId: appointmentId ? decryptId(appointmentId) : null,
        apptDate: apptDate || null,
      },
      () => {
        this.handleFetchClinicDoctorDetail();
      }
    );
  };

  handleFetchClinicDoctorDetail = () => {
    const { doctorId } = this.state;
    this.setState({ fetchingDoctor: true });
    getEsTeleDoctorDetail(doctorId)
      .then((response) => {
        if (response && response.data.hits.total.value > 0) {
          const doctorDetail = response.data.hits.hits[0]?._source;
          this.setState({ doctorDetail });
        }
      })
      .finally(() => {
        this.setState({ fetchingDoctor: false });
      });
  };

  handleRefreshAppointment = (dataObj) => {
    const {
      doctorId,
      clinicDoctorId,
      verifyId,
      mobileNumber,
      countryCode,
      clinicId,
      apptDate,
    } = this.state;

    this.setState({ appointmentId: dataObj.appointment_id });

    window.open(
      `/patient-teleconsult?did=${encryptId(doctorId)}&cdid=${encryptId(
        clinicDoctorId
      )}&clid=${encryptId(clinicId)}&vid=${encryptId(verifyId)}&ph=${encryptId(
        mobileNumber
      )}&dt=${apptDate}&cc=${encryptId(countryCode)}&cn=true&apid=${encryptId(
        dataObj.appointment_id
      )}`,
      "_self"
    );
    localStorage.setItem("viewed_permission_modal", "not_viewed");
  };

  handleJoinCall = () => {
    logMixpanelEventBasic(
      "Waiting Room Joined",
      null,
      "Teleconsult Marketplace"
    );
    const isUserViewedModal = localStorage.getItem("viewed_permission_modal");
    if (isUserViewedModal === "not_viewed") {
      this.toggleAllowPerModal();
    } else {
      const {
        appointmentId,
        countryCode,
        mobileNumber,
        doctorId,
        clinicDoctorId,
        clinicId,
        verifyId,
        apptDate,
      } = this.state;

      window.open(
        `/video-call?did=${encryptId(doctorId)}&cdid=${encryptId(
          clinicDoctorId
        )}&clid=${encryptId(clinicId)}&vid=${encryptId(
          verifyId
        )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
          countryCode
        )}&dt=${apptDate}&cn=true&apid=${encryptId(appointmentId)}`,
        "_blank"
      );
    }
  };

  /**
   * @function toggleAllowPerModal
   * @description It will toggle modal
   */
  toggleAllowPerModal = () => {
    const {
      appointmentId,
      countryCode,
      mobileNumber,
      doctorId,
      clinicDoctorId,
      clinicId,
      verifyId,
      showAllowPerModal,
      apptDate,
    } = this.state;

    if (showAllowPerModal) {
      localStorage.setItem("viewed_permission_modal", "viewed");
      window.open(
        `/video-call?did=${encryptId(doctorId)}&cdid=${encryptId(
          clinicDoctorId
        )}&clid=${encryptId(clinicId)}&vid=${encryptId(
          verifyId
        )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
          countryCode
        )}&dt=${apptDate}&cn=true&apid=${encryptId(appointmentId)}`,
        "_blank"
      );
    }
    this.setState({ showAllowPerModal: !showAllowPerModal });
  };

  /**
   * @function handleUpdateState
   * @description this function handles updating state value
   */
  handleUpdateState = (stateObj) => {
    this.setState({
      ...this.state,
      ...stateObj,
    });
  };

  render() {
    const {
      doctorId,
      clinicDoctorId,
      clinicId,
      verifyId,
      mobileNumber,
      confirmed,
      showAllowPerModal,
      appointmentId,
      countryCode,
      apptDate,
      doctorDetail,
      fetchingDoctor,
    } = this.state;

    return (
      <div className="section-container">
        <Spin spinning={fetchingDoctor}>
          <div className="video-call-container">
            <Pusher
              channel={process.env.REACT_APP_PUSHER_CHANNEL}
              event={`appt_paid_${verifyId}`}
              onUpdate={this.handleRefreshAppointment}
            />
            <div className="verification-page-content">
              {!confirmed && !appointmentId && (
                <AppointmentCard
                  appointmentDetail={null}
                  apptDate={apptDate}
                  doctorDetail={doctorDetail}
                />
              )}

              <div className={!confirmed && !appointmentId ? "mt-80" : ""}>
                {!verifyId && (
                  <VerifyUser
                    doctorId={doctorId}
                    clinicDoctorId={clinicDoctorId}
                    clinicId={clinicId}
                    verifyId={verifyId}
                    handleUpdateState={this.handleUpdateState}
                    countryCode={countryCode}
                    apptDate={apptDate}
                  />
                )}
                {verifyId && !confirmed && (
                  <PatientDetailForm
                    clinicId={clinicId}
                    mobileNumber={mobileNumber}
                    clinicDoctorId={clinicDoctorId}
                    verifyId={verifyId}
                    doctorId={doctorId}
                    handleUpdateState={this.handleUpdateState}
                    countryCode={countryCode}
                  />
                )}

                {confirmed && appointmentId && (
                  <Teleconsultation
                    appointmentId={appointmentId}
                    handleUpdateState={this.handleUpdateState}
                    handleJoinCall={this.handleJoinCall}
                    mobileNumber={mobileNumber}
                    countryCode={countryCode}
                  />
                )}
              </div>

              <AllowPermissionInfoModal
                showAllowPerModal={showAllowPerModal}
                toggleAllowPerModal={this.toggleAllowPerModal}
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
export default VideoCall;
