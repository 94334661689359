import React from "react";
import SkeletonView from "./SkeletonView";
import { getMessageDetailSkelton, getMessageListSkelton } from "../../helpers/utils";

export const ProfileSkeleton = () => {
  return (
    <div className="doctor-profile-container">
      <div className="profile-section-left">
        {getMessageListSkelton(1, "inplace-edit-widget-wrap")}
        {getMessageDetailSkelton(4, "inplace-edit-widget-wrap")}
      </div>
      <div className="profile-section-right">
        {getMessageDetailSkelton(1, "inplace-edit-widget-wrap")}
        <div className="inplace-edit-widget-wrap ">
          <SkeletonView />
        </div>
        {/* {getMessageDetailSkelton(1, "inplace-edit-widget-wrap")} */}
      </div>
    </div>
  );
};
