const Button = ({ children, type, size, onClick, className, block }) => {
  return (
    <button
      className={`${"btn"} ${type ? type : ""} ${size ? size : ""} ${
        className ? className : ""
      } ${block ? "block" : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default Button;
