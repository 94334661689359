import React, { Component } from "react";
import ProfileTopSectionComponent from "../CommonComponents/ProfileTopSection/ProfileTopSectionComponent";
import "../HospitalProfile/profile.css";
import AboutShowComponent from "../CommonComponents/About/AboutShowComponent";
import "../HospitalProfile/reviews.css";
import { getClinicDetailES, getClinicDetail } from "../../services";
import Loader from "../Loader";
import DoctorList from "./Doctors/DoctorList";
import LocationShowComponent from "./Locations/LocationShowComponent";
import BookAppointmentContainer from "./BookAppointment/BookAppointmentContainer";
import SkeletonView from "../Appointments/SkeletonView";
import { decryptId, encryptId } from "../../helpers/utils";
import qs from "qs";

class ClinicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinicDetail: null,
      clinicGroupId: null,
      isProcessing: false,
      screenSize: "big",
      locations: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    const params = this?.props?.match?.params;

    // fid = file id
    const { id } = params;
    this.setState({ clinicGroupId: id }, () => {
      this.handleFetchClinicDetailES(id);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  /**
   * @function handleResize
   * @desc This funtion sets the screen type
   * in local state
   */
  handleResize = () => {
    const windowSize = window.innerWidth;
    const screenSize = windowSize > 768 ? "big" : "small";
    this.setState({ screenSize });
  };

  /**
   * @function handleFetchClinicDetailES
   * @desc This funtion sets fetched data for clinic profile from ES
   */
  handleFetchClinicDetailES = (id) => {
    this.setState({ isProcessing: true });
    getClinicDetailES(id)
      .then((response) => {
        if (response?.data?.hits?.hits?.length > 0) {
          let clinicDetail = response?.data?.hits?.hits[0]?._source;
          let locations = clinicDetail.clinic_group_locations;
          if (locations.length > 0) {
            locations = locations.filter((obj) => {
              if (obj.name) return obj;
            });
          }
          this.setState({
            clinicDetail: response?.data?.hits?.hits[0]?._source,
            isProcessing: false,
            locations,
          });
        } else {
          // if data is not available from ES, we can fetch it from kulcare api server
          this.handleFetchClinicDetail(id);
        }
      })
      .catch(() => {
        this.handleFetchClinicDetail(id);
      });
  };

  /**
   * @function handleFetchClinicDetail
   * @desc This funtion sets fetched data for clinic profile from kulcare backend
   */
  handleFetchClinicDetail = (id) => {
    this.setState({ isProcessing: true });
    getClinicDetail(id)
      .then((response) => {
        if (response?.data?.success) {
          let clinicDetail = response?.data?.data;
          let locations = clinicDetail.clinic_group_locations;
          if (locations.length > 0) {
            locations = locations.filter((obj) => {
              if (obj.name && obj?.location?.address_line_1 !== null)
                return obj;
            });
          }
          this.setState({
            clinicDetail: response?.data?.data,
            locations,
          });
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function handleGotoDoctorProfile
   * @desc This funtion redirects user to doctor profile page
   */
  handleGotoDoctorProfile = (doctorObj) => {
    if (doctorObj?.id) {
      window.open(`/doctors/${encryptId(doctorObj.id)}`, "_blank");
    }
  };

  render() {
    const { isProcessing, clinicDetail, screenSize, locations } =
      this.state;
    return (
      <div
        className={`hospital-profile-container`}
      >
        {isProcessing && <Loader />}
        <div className="hospital-section-left">
          <ProfileTopSectionComponent
            parentContainer="clinic"
            providerDetail={clinicDetail}
          />
          {screenSize === "small" && (
            <div className="inplace-edit-widget-wrap">
              {clinicDetail ? (
                <BookAppointmentContainer
                  isProcessing={isProcessing}
                  clinicDetail={clinicDetail}
                />
              ) : (
                <SkeletonView />
              )}
            </div>
          )}
          {clinicDetail?.bio && (
            <div className="mt-10">
              <AboutShowComponent
                profileDetail={clinicDetail}
                parentContainer={"clinic"}
              />
            </div>
          )}
          {clinicDetail?.common_doctors &&
            clinicDetail?.common_doctors?.length > 0 && (
              <div className="mt-10">
                <DoctorList
                  doctors={clinicDetail?.common_doctors || []}
                  handleGotoDoctorProfile={this.handleGotoDoctorProfile}
                />
              </div>
            )}
          {locations.length > 0 && (
            <LocationShowComponent locations={locations} />
          )}
        </div>
        {screenSize === "big" && (
          <div className="hospital-section-right">
            <div className="inplace-edit-widget-wrap">
              {clinicDetail ? (
                <BookAppointmentContainer
                  isProcessing={isProcessing}
                  clinicDetail={clinicDetail}
                />
              ) : (
                <SkeletonView />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ClinicProfile;
