import { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import fuzzySearch from "../../helpers/fuzzySearch";
import { getSpecialities, getBasicSpokenLanguages } from "../../services";
import { logMixpanelEventBasic } from "../../helpers/utils";
import FilterContainer from "../Header/FilterContainer";
import FilterModalContainer from "../Header/FilterModalContainer";
import { MdFilterList } from "react-icons/md";

const Filters = ({
  handleApplyFilters,
  isTelemed,
  locations,
  handleCityChange,
  selectedLocation,
  fetchCities,
  getCityOptions,
}) => {
  const [selectedAppointmentType, setSelectedAppointmentType] = useState("");
  const [specialities, setSpecialities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languages, setLangauges] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const sortingOptions = [
    { name: "Nearest", value: "nearest" },
    { name: "₹ Low", value: "price_low" },
    { name: "₹ High", value: "price_high" },
  ];
  const [selectedSortingOption, setSelectedSortingOption] = useState("");

  useEffect(() => {
    fetchSpecialities();
    fetchLanguages();
  }, []);

  const fetchLanguages = () => {
    getBasicSpokenLanguages().then((response) => {
      const { hits } = response.data.hits;
      // let languages = [{ name: "All", value: "" }];
      let languages = [];

      if (hits.length > 0) {
        hits.map((langObj) => {
          const tempObj = langObj._source;

          languages.push({
            name: tempObj.name,
            value: tempObj.name,
          });
        });
        setLangauges(languages);
      }
    });
  };

  const fetchSpecialities = () => {
    getSpecialities().then((response) => {
      if (response.data && response.data.success) {
        const specialitiesData = response.data.data.specialities.map((sp) => {
          const speciality = {
            name: sp.name,
            value: sp.name,
          };
          return speciality;
        });
        setSpecialities(specialitiesData);
      }
    });
  };

  // const _handleApplyFilters = () => {
  //   const feesArr = selectedPrice === "" ? [] : selectedPrice.split(",");
  //   const filterParams = {
  //     language: selectedLanguage,
  //     speciality: selectedSpeciality,
  //     apptType: selectedAppointmentType,
  //     minFees: feesArr.length == 0 ? null : feesArr[0],
  //     maxFees:
  //       feesArr.length == 0 ? null : feesArr.length == 1 ? null : feesArr[1],
  //   };
  //   setShowFilters(false);
  //   handleApplyFilters(filterParams);
  // };
  // const handleCancelFilters = () => {
  //   setSelectedSpeciality("");
  //   setSelectedAppointmentType("");
  //   setSelectedPrice("");
  //   setSelectedLanguage(null);
  //   setShowFilters(false);
  //   handleApplyFilters(null);
  // };

  const handleSpecialityChange = (value) => {
    setSelectedSpeciality(value);
    const filterParams = {
      language: selectedLanguage,
      speciality: value,
      apptType: selectedAppointmentType,
      sortBy: selectedSortingOption,
    };
    handleApplyFilters(filterParams);
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    const filterParams = {
      language: value,
      speciality: selectedSpeciality,
      apptType: selectedAppointmentType,
      sortBy: selectedSortingOption,
    };
    logMixpanelEventBasic(
      "List Filtered",
      "Languages",
      "Teleconsult Marketplace"
    );
    handleApplyFilters(filterParams);
  };

  const handleApptChange = (value) => {
    setSelectedAppointmentType(value);
    const filterParams = {
      language: selectedLanguage,
      speciality: selectedSpeciality,
      apptType: value,
      sortBy: selectedSortingOption,
    };
    handleApplyFilters(filterParams);
  };

  const handleSortChange = (value) => {
    setSelectedSortingOption(value);
    const filterParams = {
      language: selectedLanguage,
      speciality: selectedSpeciality,
      apptType: selectedAppointmentType,
      sortBy: value,
    };

    logMixpanelEventBasic(
      "List Sorted",
      value == "price_low" ? "Low" : value == "price_high" ? "High" : value,
      "Teleconsult Marketplace"
    );
    handleApplyFilters(filterParams);
  };

  /**
   * Function handleResetFilters()
   * This function is used to clear the fields while clicking on the button
   */

  const handleResetFilters = () => {
    setSearchText("");
    setSelectedSpeciality("");
    setSelectedLanguage("");
    setSelectedAppointmentType("");
    setSelectedSortingOption("");
    // call filter function
    handleApplyFilters([]);
    setShowFilterModal(false);
  };

  /***
   * function handleCloseFilterModal()
   * This function is used to close the toggle modal
   */
  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  /***
   * function handleToggleFilterModal()
   * This function is used to open the modal when clicking on the modal
   */
  const handleToggleFilterModal = (value, e) => {
    if (e) e.preventDefault();
    setShowFilterModal(value);
  };

  const handleNameSearch = (e) => {
    const filterParams = {
      language: selectedLanguage,
      speciality: selectedSpeciality,
      apptType: selectedAppointmentType,
      sortBy: selectedSortingOption,
    };

    handleApplyFilters(filterParams, searchText);
  };

  return (
    <div className={"search-nav"}>
      <div className={"search-left modal"}>
        <a
          href="#"
          className="filter-search"
          onClick={(e) => handleToggleFilterModal(true, e)}
        >
          Filter <MdFilterList className="filter-icon" />
        </a>
        {showFilterModal && (
          <FilterModalContainer
            languages={languages}
            specialities={specialities}
            selectedSpeciality={selectedSpeciality}
            selectedAppointmentType={selectedAppointmentType}
            selectedLanguage={selectedLanguage}
            fuzzySearch={fuzzySearch}
            handleSpecialityChange={handleSpecialityChange}
            handleApptChange={handleApptChange}
            handleLanguageChange={handleLanguageChange}
            handleResetFilters={handleResetFilters}
            showFilterModal={showFilterModal}
            setShowFilterModal={setShowFilterModal}
            handleCloseFilterModal={handleCloseFilterModal}
            isTelemed={isTelemed}
            locations={locations}
            handleCityChange={handleCityChange}
            handleNameSearch={handleNameSearch}
            setSearchText={setSearchText}
            searchText={searchText}
            selectedLocation={selectedLocation}
            fetchCities={fetchCities}
            getCityOptions={getCityOptions}
          />
        )}

        <FilterContainer
          filterModal={"filter-modal"}
          languages={languages}
          specialities={specialities}
          selectedSpeciality={selectedSpeciality}
          selectedAppointmentType={selectedAppointmentType}
          selectedLanguage={selectedLanguage}
          fuzzySearch={fuzzySearch}
          handleSpecialityChange={handleSpecialityChange}
          handleApptChange={handleApptChange}
          handleLanguageChange={handleLanguageChange}
          handleResetFilters={handleResetFilters}
          showFilterModal={showFilterModal}
          setShowFilterModal={setShowFilterModal}
          isTelemed={isTelemed}
          locations={locations}
          handleCityChange={handleCityChange}
          handleNameSearch={handleNameSearch}
          setSearchText={setSearchText}
          searchText={searchText}
          selectedLocation={selectedLocation}
          fetchCities={fetchCities}
          getCityOptions={getCityOptions}
        />
      </div>
      <div className={"search-right"}>
        <div className="sorting-block">
          <div className="sort-heading">Sort by: </div>
          <div className="sort-select">
            <SelectSearch
              className="select-search small"
              options={sortingOptions}
              value={selectedSortingOption}
              name="sort"
              placeholder="Select"
              onChange={handleSortChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Filters;
