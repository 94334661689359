import React, { Component } from "react";
import {
  convertUTCDateToLocalTime,
  logMixpanelEventBasic,
  encryptId
} from "../../../../helpers/utils";
import { fetchAppointmentDetail } from "../../../Appointments/bookAppointmentService";
import moment from "moment";
import Pusher from "react-pusher";
import VideoAppointmentDetails from "../VideoAppointmentDetails/index";
import { showSuccessNotification } from "../../../../helpers/showNotifications";
import { getMobileOperatingSystem } from "../../../Appointments/helpers";

class Teleconsultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentDetail: null,
      isCovidClinic: null,
      appointmentTime: null,
      patientId: null,
    };
  }

  componentDidMount() {
    this.handleFetchAppointmentDetail();
  }

  handleFetchAppointmentDetail = () => {
    const { appointmentId } = this.props;
    fetchAppointmentDetail(appointmentId).then((response) => {
      this.setState({ isProcessing: false });
      if (response.data.success) {
        const record = response.data.data;

        const time = record.appointment_time
          ? moment(convertUTCDateToLocalTime(record.appointment_time)).format(
              "hh:mm A"
            )
          : null;

        const isCovidClinic =
          (record && record.clinic_details.covid_clinic) ||
          record.clinic_details.private_clinic;

        const params = {
          appointmentDetail: record,
          appointmentTime: time,
          patientId: record.patient_details.id,
          isCovidClinic,
        };

        this.setState(
          {
            appointmentDetail: record,
            appointmentTime: time,
            patientId: record.patient_details.id,
            isCovidClinic,
          },
          () => {
            this.props.handleUpdateState(params);
            // this.setAppointmentStatus(record);
          }
        );
      }
    });
  };

  /**
   * @function handleRefreshAppointment
   * @description It will refresh appointments after call
   */
  handleRefreshAppointment = (doctorJoined) => {
    if (doctorJoined) showSuccessNotification("Doctor has joined the call!");
    this.setState({
      isProcessing: true,
    });
    this.handleFetchAppointmentDetail();
  };

  /**
   * @function handleAppDownload
   * @description When user click on download
   */
  handleAppDownload = () => {
    var os = getMobileOperatingSystem();
    logMixpanelEventBasic(
      "Patient App Downloaded",
      null,
      null,
      "Telemedicine Listing"
    );
    if (os === "Windows Phone" || os === "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.patient_healthcare_app&hl=en_IN",
        "_blank"
      );
    } else {
      window.open(
        "https://apps.apple.com/us/app/kulcare/id1253938993",
        "_blank"
      );
    }
  };

  /**
   * @function handlePatientSignup
   * @description this function handles patient sign up
   */
  handlePatientSignup = (e) => {
    if (e) e.preventDefault();
    const { appointmentDetail } = this.state;
    logMixpanelEventBasic(
      "Sign Up Intitiated",
      null,
      "Teleconsult Marketplace"
    );
    window.open(
      `${process.env.REACT_APP_AUTH_SITE_BASE_URL}/sign-up?acc=patient&dname=${appointmentDetail?.patient_details?.name}&ph=${appointmentDetail?.patient_details?.phone_number}`,
      "_blank"
    );
  };

  render() {
    const { handleJoinCall } = this.props;
    const { appointmentDetail } = this.state;

    return (
      <div>
        {appointmentDetail && (
          <React.Fragment>
            <Pusher
              channel={process.env.REACT_APP_PUSHER_CHANNEL}
              event={`pt_telecon_doc_started_${appointmentDetail.id}`}
              onUpdate={() => this.handleRefreshAppointment(true)}
            />
            <Pusher
              channel={process.env.REACT_APP_PUSHER_CHANNEL}
              event={`doctor_joined_${appointmentDetail.id}`}
              onUpdate={() => this.handleRefreshAppointment()}
            />
            <Pusher
              channel={process.env.REACT_APP_PUSHER_CHANNEL}
              event={`prescription_attached_${appointmentDetail.clinic_details.id}`}
              onUpdate={() => this.handleRefreshAppointment()}
            />
            <Pusher
              channel={process.env.REACT_APP_PUSHER_CHANNEL}
              event={`doc_telecon_start_in_10_${appointmentDetail.doctor_details.id}`}
              onUpdate={() => this.handleRefreshAppointment()}
            />

            <Pusher
              channel={process.env.REACT_APP_PUSHER_CHANNEL}
              event={`session_completed_${appointmentDetail.id}`}
              onUpdate={() => this.handleRefreshAppointment()}
            />
          </React.Fragment>
        )}

        {appointmentDetail && (
          <VideoAppointmentDetails
            appointmentDetail={appointmentDetail}
            getAppointmentDetail={this.handleFetchAppointmentDetail}
            handleJoinCall={handleJoinCall}
            handleAppDownload={this.handleAppDownload}
            handlePatientSignup={this.handlePatientSignup}
            handleGotoDoctorDetails={this.handleGotoDoctorDetails}
          />
        )}
      </div>
    );
  }
}

export default Teleconsultation;
