import React, { Component } from "react";
import OtpInput from "react-otp-input";
import { Button, Row, Col } from "antd";
import {
  logMixpanelEventBasic,
  encryptId,
  encodePhoneNumber,
} from "../../../../helpers/utils";
import {
  sendVerifyCode,
  phoneOTPVerification,
  resendVerifyCode,
} from "../../../Appointments/bookAppointmentService";
import { history } from "../../../../helpers/history";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../helpers/showNotifications";
import NumericInput from "../../../../helpers/NumericInput";

class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      showPhoneField: true,
      mobileNumber: null,
      showOtpField: false,
      verificationCode: null,
      verifyId: null,
      isProcessing: false,
    };
  }

  /**
   * @function handleChange
   * @param value
   * @description it will handle otp change event
   */
  handleChange = (value) => {
    this.setState({ otp: value });
  };

  handleChangeInput = (e, fieldName) => {
    this.setState({ [fieldName]: e?.target?.value });
  };

  handleVerifyMobileNumber = () => {
    const { mobileNumber } = this.state;
    const { clinicDoctorId } = this.props;

    if (!mobileNumber || (mobileNumber && mobileNumber.trim() === "")) {
      showErrorNotification("Please enter your mobile number");
      return false;
    }
    logMixpanelEventBasic(
      "Patient Phone Number Added",
      null,
      "Teleconsult Marketplace"
    );

    const params = {
      clinic_doctor_id: clinicDoctorId,
      country_code: 91,
      phone_number: mobileNumber,
      appointment_type: "TELEMEDICINE",
      payment_option: "BEFORE",
      preferred_time: null,
    };

    this.setState({ isProcessing: true });
    sendVerifyCode(params)
      .then((response) => {
        if (response?.data?.success) {
          this.setState({
            showPhoneField: false,
            showOtpField: true,
            verifyId: response.data.data.id,
          });

          showSuccessNotification("Code sent successfully");
        } else {
          showErrorNotification(response.data.errors[0].title);
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  handleOTPChange = (val) => {
    this.setState({ verificationCode: val });
  };

  handleOTPVerification = () => {
    const { verificationCode, verifyId, mobileNumber } = this.state;
    const { doctorId, clinicDoctorId, clinicId, countryCode, apptDate } =
      this.props;

    if (!verificationCode) {
      showErrorNotification("Please enter OTP sent on your mobile number");
      return false;
    }
    const params = {
      verification_code: verificationCode,
    };

    this.setState({ isProcessing: true });
    phoneOTPVerification(verifyId, params)
      .then((response) => {
        if (response?.data?.success) {
          logMixpanelEventBasic(
            "Patient Phone Number Verified",
            null,
            "Teleconsult Marketplace"
          );
          showSuccessNotification("Verified successfully");

          // history.push(
          //   `/patient-teleconsult?did=${encryptId(doctorId)}&cdid=${encryptId(
          //     clinicDoctorId
          //   )}&clid=${encryptId(clinicId)}&vid=${encryptId(
          //     verifyId
          //   )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
          //     countryCode
          //   )}&dt=${apptDate}`
          // );

          const updatedParams = {
            verifyId: verifyId,
            mobileNumber: mobileNumber,
            countryCode: countryCode,
          };

          this.props.handleUpdateState(updatedParams);
          // update step number
          this.props.handleUpdateStepNo(2);
        } else {
          showErrorNotification(response.data.errors[0].title);
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  handleResendCode = (e) => {
    if (e) e.preventDefault();
    const { verifyId } = this.state;
    this.setState({ isProcessing: true });
    resendVerifyCode(verifyId)
      .then((response) => {
        if (response?.data?.success) {
          showSuccessNotification("Code sent successfully");
        } else {
          showErrorNotification(response.data.errors[0].title);
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  render() {
    const {
      showPhoneField,
      mobileNumber,
      showOtpField,
      verificationCode,
      isProcessing,
    } = this.state;
    const { countryCode } = this.props;

    let hiddenPhoneNumber = null;
    if (countryCode && mobileNumber)
      hiddenPhoneNumber = encodePhoneNumber(countryCode, mobileNumber);

    return (
      <div>
        {/* <div className="verification-heading-title">Verify mobile number</div> */}
        <div className="verification-sub-text">
          {showPhoneField
            ? "Please verify your mobile number for confirmation"
            : `Please enter the OTP sent on your mobile number ${hiddenPhoneNumber}`}
        </div>

        {showPhoneField && (
          <div className="phone_number_wrapper">
            <Row type="flex">
              <Col lg={12} xs={18} sm={15}>
                <div className="form_fields_wrapper">
                  <div className="form_field">
                    <NumericInput
                      placeholder="Enter mobile number"
                      onChange={(e) =>
                        this.handleChangeInput(e, "mobileNumber")
                      }
                      value={mobileNumber}
                      maxLength={10}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col span={8}>
                <Button
                  type="primary"
                  className="full_width"
                  // className="primary-btn mt-40 full-btn"
                  onClick={this.handleVerifyMobileNumber}
                  disabled={!mobileNumber}
                  loading={isProcessing}
                >
                  Verify
                </Button>
              </Col>
            </Row>
          </div>
        )}

        {showOtpField && (
          <div className="phone_number_wrapper">
            <div className="form_field mb-5">
              <OtpInput
                name="verificationCode"
                value={verificationCode}
                onChange={this.handleOTPChange}
                numInputs={6}
                separator={<div className="field_sep"></div>}
                containerStyle="verify-otp-container"
                inputStyle="verify-otp-box"
              />
            </div>
            <div className="mb-5">
              <a
                className="verify-send-again-link"
                href="#"
                onClick={this.handleResendCode}
              >
                Send again
              </a>
            </div>
            <Row type="flex">
              <Col span={12}>
                <Button
                  type="primary"
                  onClick={this.handleOTPVerification}
                  disabled={!verificationCode}
                  loading={isProcessing}
                >
                  Verify OTP
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default VerifyUser;
