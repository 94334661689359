import axios from "axios";
import { getBasicAuthHeaders } from "../helpers/auth";

/**
 * @function getClinicDetail
 * @desc This gets Cities
 */
export const getClinicDetailES = (id) => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_CLINICS_INDEX}/_search`;
  const params = {
    "query": {
      "match": {
        "unique_clinic_id": id
      }
    }
  }
  return axios.post(url, params);
};

export const getClinicDetail = (id) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/clinics/listing/${id}`;
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};