import { getDocDefaultImage, cityName } from "../../helpers/utils";
import Button from "../Button";

const DoctorListItem = ({
  doctor,
  handleShowBookTeleconsultation,
  screenSize,
  handleGotoProfile,
}) => {
  let docLocation = [];
  let docLanguages = [];
  if (doctor?.languages && doctor?.languages?.length > 0) {
    docLanguages = doctor?.languages?.map((language) => {
      return language;
    });
  }
  if (doctor?.location?.city) docLocation.push(cityName(doctor.location.city));
  if (doctor?.location?.state) docLocation.push(doctor.location.state);

  return (
    <li className="tele_doc_item">
      <div className="col-80">
        <div className="d-flex flex-valign-centered with-resp-col-flex">
          <div className="col-40 right-bdr">
            <div className="doctor-name-wrap">
              <div className="doctor-image-wrap">
                <div
                  className="doctor-image"
                  style={{
                    backgroundImage: `url(${getDocDefaultImage(doctor)})`,
                  }}
                >
                  <div className="live-dot-box">
                    <div
                      className={`live-dot mr-5 ${
                        doctor.available ? "online" : "offline"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <div
                  className="text-bold max-1-line cursor-pointer"
                  title={`Dr. ${doctor.name}`}
                  onClick={(e) => handleGotoProfile(e, doctor)}
                >
                  Dr. {doctor.name}
                </div>
                <div
                  className="font-size-14 max-1-line for-sm-screen"
                  title={doctor?.speciality}
                >
                  {doctor?.speciality}
                </div>

                {/* <div className="hide-text-overflow">{doctor?.speciality}</div> */}

                <div
                  className="mt-3 font-size-12 max-1-line"
                  title={docLanguages?.join(", ")}
                >
                  {docLanguages?.length > 0 ? docLanguages?.join(", ") : ""}
                </div>
                {screenSize === "xs" && (
                  <div className="mb-5">
                    <div
                      className="font-size-14 max-2-line"
                      title={docLocation.join(", ")}
                    >
                      {docLocation.join(", ")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {screenSize === "big" && (
            <div className="col-25 right-bdr for-desktop-screen">
              <div
                className="font-size-14 max-1-line"
                title={doctor?.speciality}
              >
                {doctor?.speciality}
              </div>
            </div>
          )}
          {screenSize !== "xs" && (
            <div className="col-15 right-bdr">
              <div className="font-size-14">
                {doctor?.telemedicine_fees
                  ? `₹ ${doctor?.telemedicine_fees}`
                  : ""}
              </div>
            </div>
          )}
          {screenSize !== "xs" && (
            <div className="col-20 right-bdr">
              <div
                className="font-size-14 max-2-line"
                title={docLocation.join(", ")}
              >
                {docLocation.join(", ")}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-15 buttons-section">
        <div className="content-section text-center">
          {screenSize === "xs" && (
            <div className="mb-5">
              <div className="font-size-14 text-bold font-color-purple">
                {doctor?.telemedicine_fees
                  ? `₹ ${doctor?.telemedicine_fees}`
                  : ""}
              </div>
            </div>
          )}
          <Button
            type={"ghost"}
            size="small"
            block
            onClick={() => handleShowBookTeleconsultation(doctor)}
          >
            {screenSize === "xs" ? "Call" : "Teleconsult"}
          </Button>
        </div>
      </div>
    </li>
  );
};

export default DoctorListItem;
