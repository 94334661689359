import React from "react";
import EducationPhoto from "../../Appointments/images/education.svg";

const EducationShowComponent = ({ doctorDetails }) => {
  return (
    <>
      <ul className="list_with_btm_border">
        {doctorDetails &&
          doctorDetails.education_list.length > 0 &&
          doctorDetails.education_list.map((education, i) => (
            <li key={`${education.id}_${i}`}>
              <div>
                <div className="d-flex">
                  <div className="edu-icon-box">
                    <img src={EducationPhoto} alt="" />
                  </div>
                  <div className="ml-10">
                    {education.university && (
                      <div className="flexbox-with-icons">
                        <h1 className="font-size-14 font-weight-bold mb-0">
                          {education.university}
                        </h1>
                      </div>
                    )}
                    <p className="font-size-14 font-weight-normal mb-0 font-color-primary">
                      {education.name}
                    </p>
                    <p className="font-color-grey font-weight-normal mb-0 font-size-14">
                      {education.graduating_year}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default EducationShowComponent;
