import React from "react";

const WorkShowComponent = ({ doctorDetails }) => {
  return (
    <>
      <ul className="list_with_btm_border">
        {doctorDetails &&
          doctorDetails.experiences_list.length > 0 &&
          doctorDetails.experiences_list.map((experience, index) => (
            <li key={index}>
              <div>
                {experience.position && (
                  <div className="flexbox-with-icons">
                    <h1 className="font-size-14 font-weight-bold mb-0">
                      {experience.position}
                    </h1>
                  </div>
                )}
                {experience.hospital && (
                  <p className="font-size-14 font-weight-normal mb-0 font-color-primary">
                    {experience.hospital}
                  </p>
                )}
                <p className="font-color-grey font-weight-normal mb-0 font-size-14 font-color-primary">
                  {experience.start_year} -{" "}
                  {experience.currently_working
                    ? "Present"
                    : experience.end_year}
                </p>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default WorkShowComponent;
