import React from "react";
import Button from "./Button";
import OtpInput from "react-otp-input";
import Countdown from "./Countdown";

const VerifyOTPForm = ({
  showTimer,
  mobileNumber,
  handleResendCode,
  verificationCode,
  handleOTPChange,
  handleOTPVerification,
  isProcessing,
}) => {
  return (
    <div className="add_patient_info_wrapper">
      <div>
        <p className="info_col_heading">
          Please enter the OTP sent on your mobile number <br />
          <span className="font-weight-normal">
            91-{mobileNumber}
          </span>
        </p>
        <div className="phone_number_wrapper">
          <div className="form_fields_wrapper">
            <div className="form_field">
              <div className="label_with_flex">
                <label>Enter OTP</label>
                {showTimer && (
                  <Countdown seconds={60} handleResendCode={handleResendCode} />
                )}
              </div>

              <OtpInput
                name="verificationCode"
                value={verificationCode}
                onChange={handleOTPChange}
                numInputs={6}
                separator={<div className="field_sep"></div>}
                containerStyle={"otp_input_box"}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Button
          type="primary"
          className={`full_width ${isProcessing ? "disabled" : ""}`}
          onClick={handleOTPVerification}
        >
          Verify OTP
        </Button>
      </div>
    </div>
  );
};

export default VerifyOTPForm;
