import React from "react";

const AboutShowComponent = ({ doctorDetails }) => {
  return (
    <div className="mt-10">
      <p className="font-size-14 mb-0 font-weight-semibold">
        {doctorDetails && doctorDetails.bio}
      </p>
    </div>
  );
};

export default AboutShowComponent;
