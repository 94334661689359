import axios from "axios";
import _ from "lodash";
import { getBasicAuthHeaders } from "../helpers/auth";

/**
 * @function getEsSpecialities
 * @desc This gets Cities
 */
export const getEsDoctors = (page, perPage, city, searchText, filterParams) => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_DOCTORS_INDEX}/_search`;
  const params = {
    query: {
      bool: {
        must: [
          {
            geo_distance: {
              distance: "20km",
              location_coordinates: {
                lat: city.lat,
                lon: city.lng,
              },
            },
          },
          // { match_phrase_prefix: { city: city.name } },
        ],
      },
    },
    from: page - 1,
    size: perPage,
  };
  // for doctor id
  // if (doctorId) {
  //   params.query.bool.must.push({ term: { id: doctorId } });
  // }
  // for search text
  if (searchText) {
    params.query.bool.must.push({ match_phrase_prefix: { name: searchText } });
  }
  // speciality
  if (filterParams && filterParams.speciality) {
    params.query.bool.must.push({
      match_phrase_prefix: { speciality: filterParams.speciality },
    });
  }
  // appointment type in person
  if (filterParams && filterParams.apptType === "in_person") {
    params.query.bool.must.push({ term: { takes_in_clinic: true } });
    params.query.bool.must.push({ exists: { field: "fees" } });
  }
  // appointment type in virtual
  if (filterParams && filterParams.apptType === "virtual") {
    params.query.bool.must.push({ term: { takes_teleconsultation: true } });
    params.query.bool.must.push({ exists: { field: "telemedicine_fees" } });
  }

  // language
  if (filterParams && filterParams.language) {
    params.query.bool.must.push({
      term: { languages: filterParams.language.toLowerCase() },
    });
  }

  // price filter for both case

  if (
    filterParams &&
    filterParams.minFees &&
    filterParams.maxFees &&
    !filterParams.apptType
  ) {
    params.query.bool.must.push({
      range: {
        fees: {
          gte: parseInt(filterParams.minFees),
          lte: parseInt(filterParams.maxFees),
        },
      },
    });
    params.query.bool.must.push({
      range: {
        telemedicine_fees: {
          gte: parseInt(filterParams.minFees),
          lte: parseInt(filterParams.maxFees),
        },
      },
    });
  }

  if (
    filterParams &&
    filterParams.minFees &&
    !filterParams.maxFees &&
    !filterParams.apptType
  ) {
    params.query.bool.must.push({
      range: {
        fees: {
          gte: parseInt(filterParams.minFees),
        },
      },
    });
    params.query.bool.must.push({
      range: {
        telemedicine_fees: {
          gte: parseInt(filterParams.minFees),
        },
      },
    });
  }

  // price filter for in person
  if (
    filterParams &&
    filterParams.minFees &&
    filterParams.maxFees &&
    filterParams.apptType === "in_person"
  ) {
    params.query.bool.must.push({
      range: {
        fees: {
          gte: parseInt(filterParams.minFees),
          lte: parseInt(filterParams.maxFees),
        },
      },
    });
  }

  if (
    filterParams &&
    filterParams.minFees &&
    !filterParams.maxFees &&
    filterParams.apptType === "in_person"
  ) {
    params.query.bool.must.push({
      range: {
        fees: {
          gte: parseInt(filterParams.minFees),
        },
      },
    });
  }

  // price filters for virtual

  if (
    filterParams &&
    filterParams.minFees &&
    filterParams.maxFees &&
    filterParams.apptType === "virtual"
  ) {
    params.query.bool.must.push({
      range: {
        telemedicine_fees: {
          gte: parseInt(filterParams.minFees),
          lte: parseInt(filterParams.maxFees),
        },
      },
    });
  }

  if (
    filterParams &&
    filterParams.minFees &&
    !filterParams.maxFees &&
    filterParams.apptType === "virtual"
  ) {
    params.query.bool.must.push({
      range: {
        telemedicine_fees: {
          gte: parseInt(filterParams.minFees),
        },
      },
    });
  }
  params.sort = [];
  // sorting by distance
  if (
    filterParams &&
    filterParams.sortBy &&
    filterParams.sortBy === "nearest"
  ) {
    params.sort.push({
      _geo_distance: {
        location_coordinates: {
          lat: city.lat,
          lon: city.lng,
        },
        order: "asc",
        unit: "km",
      },
    });
  }
  // sorting by name

  if (filterParams && filterParams.sortBy && filterParams.sortBy === "name") {
    params.sort.push({ name: { order: "asc" } });
  }
  // sorting by price

  if (
    filterParams &&
    filterParams.sortBy &&
    filterParams.sortBy === "price_low"
  ) {
    const feesNode =
      filterParams && filterParams.apptType === "virtual"
        ? "telemedicine_fees"
        : "fees";
    params.sort.push({ [feesNode]: { order: "asc" } });
  }
  if (
    filterParams &&
    filterParams.sortBy &&
    filterParams.sortBy === "price_high"
  ) {
    const feesNode =
      filterParams && filterParams.apptType === "virtual"
        ? "telemedicine_fees"
        : "fees";
    params.sort.push({ [feesNode]: { order: "desc" } });
  }

  params.query.bool.must.push({ term: { is_listed: true } });

  if (
    !filterParams ||
    (filterParams &&
      Array.isArray(filterParams) &&
      filterParams.length === 0) ||
    (filterParams && _.isObject(filterParams) && _.isEmpty(filterParams))
  )
    params.sort.push({ active_percent: { order: "DESC" } });

  return axios.post(url, params);
};

/**
 * @function getEsSpecialities
 * @desc This gets Cities
 */
export const getEsSpecialities = () => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_SPECIALITIES_INDEX}/_search`;
  return axios.get(url);
};

/**
 * @function getBasicSpokenLanguages
 * @param searchText
 * @desc This service fetches all spoken languages
 */
export const getBasicSpokenLanguages = () => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_BASIC_SPOKEN_LANGUAGE_INDEX}/_search`;
  const params = {
    query: {
      bool: {
        must: [],
      },
    },
    from: 0,
    size: 100,
  };
  return axios.post(url, params);
};

/**
 * @function getEsSpecialities
 * @desc This gets Cities
 */
export const getEsHospitals = (
  page,
  perPage,
  city,
  searchText,
  filterParams
) => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_HOSPITALS_INDEX}/_search`;
  const params = {
    query: {
      bool: {
        must: [{ match_phrase_prefix: { "location.city": city.name } }],
      },
    },
    from: page - 1,
    size: perPage,
  };

  // for search text
  if (searchText) {
    params.query.bool.must.push({ match_phrase_prefix: { name: searchText } });
  }

  return axios.post(url, params);
};

/**
 * @function getEsCities
 * @desc This gets Cities
 */
export const getEsCities = (page, perPage, searchText) => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_CITIES_INDEX}/_search`;
  const params = {
    query: {
      bool: {
        must: [],
      },
    },
    from: page - 1,
    size: perPage,
  };

  // for search text
  if (searchText) {
    params.query.bool.must.push({ match_phrase_prefix: { name: searchText } });
  }
  params.sort = [];
  params.sort.push({ order_number: { order: "asc" } });
  return axios.post(url, params);
};

/**
 * @function getEsSpecialities
 * @desc This gets Cities
 */
export const getEsTeleDoctors = (
  page,
  perPage,
  city,
  searchText,
  filterParams,
  onlyCount
) => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_DOCTORS_INDEX}/_search`;
  const params = {
    query: {
      bool: {
        must: [
          // { match_phrase_prefix: { city: city.name } },
        ],
      },
    },
    from: page - 1,
    size: perPage,
  };
  params.sort = [];
  params.query.bool.must.push({ term: { available: true } });
  // params.sort.push({ available: { order: "desc" } });

  if (city) {
    params.query.bool.must.push({ match_phrase_prefix: { city: city.name } });
  }
  // for search text
  if (searchText) {
    params.query.bool.must.push({
      bool: {
        should: [
          { match_phrase_prefix: { name: searchText } },
          { match_phrase_prefix: { speciality: searchText } },
          { term: { search_keywords: searchText } },
        ],
      },
    });
  }
  // speciality
  if (filterParams && filterParams.speciality) {
    params.query.bool.must.push({
      match_phrase_prefix: { speciality: filterParams.speciality },
    });
  }
  // appointment type in person
  if (filterParams && filterParams.apptType === "in_person") {
    params.query.bool.must.push({ term: { takes_in_clinic: true } });
    params.query.bool.must.push({ exists: { field: "fees" } });
  }
  // appointment type in virtual
  if (filterParams && filterParams.apptType === "virtual") {
    params.query.bool.must.push({ term: { takes_teleconsultation: true } });
    params.query.bool.must.push({ exists: { field: "telemedicine_fees" } });
  }

  // language
  if (filterParams && filterParams.language) {
    params.query.bool.must.push({
      term: { languages: filterParams.language.toLowerCase() },
    });
  }
  // sorting by name

  if (filterParams && filterParams.sortBy && filterParams.sortBy === "name") {
    params.sort.push({ name: { order: "asc" } });
  }
  // sorting by price
  if (
    filterParams &&
    filterParams.sortBy &&
    filterParams.sortBy === "price_low"
  )
    params.sort.push({ telemedicine_fees: { order: "asc" } });
  if (
    filterParams &&
    filterParams.sortBy &&
    filterParams.sortBy === "price_high"
  ) {
    params.sort.push({ telemedicine_fees: { order: "desc" } });
  }

  // if(onlyCount) params.query.bool.must.push({ term: { available: true } });
  params.query.bool.must.push({ term: { is_tele_listed: true } });
  params.query.bool.must.push({ exists: { field: "telemedicine_fees" } });

  if (
    !filterParams ||
    (filterParams &&
      Array.isArray(filterParams) &&
      filterParams.length === 0) ||
    (filterParams && _.isObject(filterParams) && _.isEmpty(filterParams))
  )
    params.sort.push({ active_percent: { order: "DESC" } });

  return axios.post(url, params);
};

/**
 * @function fetchDoctorAvailability
 * @desc This gets doctor availability for current day
 */
export const fetchDoctorAvailability = (clinicDoctorId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/public_appointments/availability?clinic_doctor_id=${clinicDoctorId}`;
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};

/**
 * @function getEsSpecialities
 * @desc This gets Cities
 */
export const getEsTeleDoctorDetail = (clinicDoctorId) => {
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_DOCTORS_INDEX}/_search`;
  const params = {
    query: {
      bool: {
        must: [{ term: { clinic_doctor_id: clinicDoctorId } }],
      },
    },
  };

  return axios.post(url, params);
};
