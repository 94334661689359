import React from "react";

const CertificationsShowComponent = ({
  doctorDetails,
}) => {
  return (
    <>
      <ul className="list_with_btm_border">
        {doctorDetails &&
          doctorDetails.certifications_list.length > 0 &&
          doctorDetails.certifications_list.map((certificate, i) => (
            <li key={`${certificate.id}_${i}`}>
              <div>
                <div className="flexbox-with-icons">
                  <p className="font-size-14 font-weight-normal mb-0 font-color-primary">
                    {certificate.name}
                  </p>
                </div>

                <p className="font-color-grey font-weight-normal mb-0 font-size-14">
                  {certificate.year}
                </p>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};

export default CertificationsShowComponent;
