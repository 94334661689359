import React from "react";
import Button from "./Button";

const VerifyMobileNumberForm = ({
  setMobileNumber,
  mobileNumber,
  handleVerifyMobileNumber,
  isProcessing
}) => {
  return (
    <div className="add_patient_info_wrapper">
      <div>
        <p className="info_col_heading">
          Please verify your mobile number for confirmation
        </p>
        <div className="phone_number_wrapper">
          <div className="form_fields_wrapper">
            <div className="form_field">
              <label>Mobile number</label>
              <input
                placeholder="Enter your mobile number"
                onChange={(e) => setMobileNumber(e.target.value)}
                value={mobileNumber}
                maxLength={10}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <Button
          type="primary"
          className={`full_width ${isProcessing ? 'disabled' : ''}`}
          onClick={handleVerifyMobileNumber}
        >
          Verify mobile number
        </Button>
      </div>
    </div>
  );
};

export default VerifyMobileNumberForm;
