import React from "react";
import { getDocImage, getInitials } from "../../../helpers/utils";
import { FaVideo } from "react-icons/fa";
import { HiUser } from "react-icons/hi";
import { BsFillCalendarEventFill } from "react-icons/bs";

const ProfileTopSectionShowComponent = ({ doctorDetails }) => {
  return (
    <div>
      <div className="profile_top_wrapper">
        <div
          className="doc-profile-img"
          style={{
            backgroundImage: `url('${getDocImage(doctorDetails)}')`,
          }}
        >
          {doctorDetails &&
            (!doctorDetails.photo || doctorDetails.photo.trim() === "") && (
              <div className="name-initials">
                {getInitials(doctorDetails?.name)}
              </div>
            )}
        </div>

        <div className="doc-profile-detail">
          <div className="doc-name-flex-wrap">
            <div className="doc-fullname">
              {doctorDetails &&
                doctorDetails.name &&
                `Dr. ${doctorDetails.name}`}
            </div>
          </div>
          <div className="doc_profile">
            <div className="doc-info-text">
              <ul className="doctor_spec_brief_list">
                {doctorDetails && doctorDetails.speciality ? (
                  <li className="doc-spec-box font-color-primary">
                    {doctorDetails.speciality}
                  </li>
                ) : (
                  ""
                )}
                {doctorDetails && doctorDetails.exp ? (
                  <li className="doc-spec-box font-color-primary">
                    {`${doctorDetails.exp} years`}
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>

            {doctorDetails && doctorDetails?.other_specialities?.length > 0 ? (
              <div className="doctor_city">
                <span className="text-bold">Other specialities:</span>{" "}
                {doctorDetails?.other_specialities
                  ?.map((obj) => {
                    return obj.name;
                  })
                  .join(", ")}
              </div>
            ) : (
              ""
            )}

            {doctorDetails && doctorDetails.city ? (
              <div className="doctor_city">{doctorDetails.city}</div>
            ) : (
              ""
            )}

            <div className="doc_appt_types mt-10">
              {doctorDetails && doctorDetails.takes_in_clinic && (
                <div className="doc_appt_box inperson_type">
                  <div className="doc_appt_icon mr-5">
                    <HiUser color="white" size={"12px"} />
                  </div>
                  <div>In-person visits</div>
                </div>
              )}
              {doctorDetails && doctorDetails.takes_teleconsultation && (
                <div className="doc_appt_box video_visits">
                  <div className="doc_appt_icon mr-5">
                    <FaVideo color="white" size={"10px"} />
                  </div>
                  <div>Video visits</div>
                </div>
              )}

              {doctorDetails && doctorDetails.takes_appointment && (
                <div className="doc_appt_box appts">
                  <div className="doc_appt_icon mr-5">
                    <BsFillCalendarEventFill color="white" size="8px" />
                  </div>
                  <div>Appointments</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTopSectionShowComponent;
