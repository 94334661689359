import { useState, useEffect } from "react";
import "./styles/appointments.css";
import "./styles/button.css";
import {
  getTimeSlots,
  sendVerifyCode,
  phoneOTPVerification,
  fetchClinicPatients,
  createAppointment,
  createTeleconsultationOrder,
  resendVerifyCode,
} from "./bookAppointmentService";
import {
  formatedShortDate,
  convertTime12to24,
  loadScript,
  getMobileOperatingSystem,
  staticDaySlots,
  setAddressLine,
  handleSetLocationFee,
  timeOfDay,
} from "./helpers";
import moment from "moment";
import toast from "react-hot-toast";
import { logMixpanelEventBasic } from "../../helpers/utils";
import BookAppointmentView from "./BookAppointmentView";

const defaultAppointmentSlot = 15;

const BookAppointmentContainer = ({
  doctorId,
  doctorDetails,
  apptType,
  selectedApptDayObj,
  doctorAvailableDays,
  selectedClinicDoctorId,
  selectedDoctorLocation,
  daysGapforFetchSchedule,
  selectedScheduleIndex,
}) => {
  const noOfDays = 1;
  const walkingNoOfDays = 5;
  const [showPatientPhoneField, setShowPatientPhoneField] = useState(false);
  const [showVerifyOTPField, setShowVerifyOTPField] = useState(false);
  const [selectedApptType, setSelectedApptType] = useState(
    doctorDetails.takes_teleconsultation ? "TELEMEDICINE" : "ONCALL"
  );
  const [clinicLocations, setClinicLocations] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fees, setFees] = useState(
    doctorDetails.takes_teleconsultation
      ? null
      : doctorDetails.fees || doctorDetails.fixed_appt_fees
  );
  const [selectedApptDate, setSelectedApptDate] = useState(new Date());
  const [daysGapFromNow, setDaysGapFromNow] = useState(0);
  const [apptTime, setApptTime] = useState(null);
  const [clinicDoctorId, setClinicDoctorId] = useState(null);
  const [stepNo, setStepNo] = useState(1);
  const [dateTimeString, setDateTimeString] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [verifyId, setVerifyId] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [patientAge, setPatientAge] = useState(null);
  const [patientGender, setPatientGender] = useState({
    value: "Male",
    label: "Male",
  });
  const [clinicPatientList, setClinicPatientList] = useState([]);
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [clinicPatientId, setClinicPatientId] = useState(null);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [preferredTimeInISO, setPreferredTimeInISO] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [useKulcareApptsFlag, setUseKulcareApptsFlag] = useState(
    doctorDetails.use_kulcare_appts
  );
  const [selectedDay, setSelectedDay] = useState(false);
  const [selectedDayObj, setSelectedDayObj] = useState(null);
  const [doctorAvailabilityDays, setDoctorAvailabilityDays] = useState([]);
  const [preferredStartTime, setPreferredStartTime] = useState(null);
  const [preferredEndTime, setPreferredEndTime] = useState(null);
  const [doctorWalkinTimeSlots, setDoctorWalkinTimeSlots] = useState([]);
  const [selectedDaySlot, setSelectedDaySlot] = useState(null);
  const [selectedWalkinDate, setSelectedWalkinDate] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFetchingTimeSlots, setIsFetchingTimeSlots] = useState(false);
  const [timeSlotDayLimit, setTimeSlotDayLimit] = useState(
    defaultAppointmentSlot
  );

  useEffect(() => {
    if (apptType) {
      handleSelectApptType(null, apptType);
    }

    // APPOINTMENT TYPE: WALKIN
    if (
      doctorDetails &&
      selectedApptDayObj &&
      doctorAvailableDays.length > 0 &&
      selectedDoctorLocation
    ) {
      setSelectedApptType(apptType);
      setSelectedDayObj(selectedApptDayObj);
      setSelectedDay(selectedApptDayObj.day);
      setSelectedLocation(selectedDoctorLocation);
      doctorAvailableDays.map((obj) => {
        obj.static_day_slots = staticDaySlots;
      });
      setDoctorAvailabilityDays(doctorAvailableDays);
    }
  }, []);

  useEffect(() => {
    let finalLocArray = [];
    if (doctorDetails?.clinic_locations) {
      const locations = doctorDetails?.clinic_locations;

      locations.map((obj) => {
        if (
          obj.schedule &&
          obj.schedule.length > 0 &&
          ((obj.takes_in_clinic && obj.fees) ||
            (obj.takes_appointment && obj.fixed_appt_fees)) &&
          obj.use_kulcare_appts
        ) {
          const clinicLocation = {};
          clinicLocation.label = setAddressLine(obj);
          clinicLocation.value = obj?.location?.id;
          clinicLocation.fees = handleSetLocationFee(obj);
          clinicLocation.takes_teleconsultation = obj.takes_teleconsultation;
          clinicLocation.clinic_doctor_id = obj.clinic_doctor_id;
          clinicLocation.clinicId = obj?.clinic?.id;
          clinicLocation.use_kulcare_appts = obj.use_kulcare_appts;
          clinicLocation.takes_in_clinic = obj.takes_in_clinic;
          clinicLocation.takes_appointment = obj.takes_appointment;
          clinicLocation.fixed_appt_fees = obj.fixed_appt_fees;
          clinicLocation.schedules = obj.schedules;
          clinicLocation.time_slot_day_limit = obj?.time_slot_day_limit;

          finalLocArray.push(clinicLocation);
        }
      });
    }
    setClinicLocations(finalLocArray);

    if (
      (selectedApptType === "ONCALL" ||
        apptType === "ONCALL" ||
        apptType === "WALKIN") &&
      finalLocArray.length > 0
    ) {
      setUseKulcareApptsFlag(finalLocArray[0].use_kulcare_appts);
      if (selectedApptType === "ONCALL" || apptType === "ONCALL")
        setSelectedLocation(finalLocArray[0]);
      setClinicDoctorId(finalLocArray[0].clinic_doctor_id);
      setClinicId(finalLocArray[0].clinicId);
      setFees(handleSetLocationFee(finalLocArray[0]));
      setTimeSlotDayLimit(
        finalLocArray[0]?.time_slot_day_limit || defaultAppointmentSlot
      );
      if (selectedApptType === "ONCALL" || apptType === "ONCALL") {
        handleFetchTimeSlots(
          apptType ? apptType : selectedApptType,
          daysGapFromNow,
          finalLocArray[0].clinic_doctor_id
        );
      }

      if (apptType === "WALKIN") {
        setSelectedApptType(apptType);
        handleFetchWalkinTimeSlots();
      }
    }

    if (
      doctorId &&
      selectedApptType === "TELEMEDICINE" &&
      apptType !== "WALKIN" &&
      apptType !== "ONCALL"
    ) {
      loadScript("https://checkout.razorpay.com/v1/checkout.js", "razorpay");
      handleFetchTimeSlots(selectedApptType, daysGapFromNow, doctorId);
    }
  }, [doctorId, doctorDetails]);

  const handleFetchWalkinTimeSlots = (daysGapLocal) => {
    // get three days time slots
    setIsFetchingTimeSlots(true);
    let dayGap = daysGapLocal ? daysGapLocal : daysGapforFetchSchedule;
    getTimeSlots(
      selectedClinicDoctorId,
      walkingNoOfDays,
      dayGap,
      false,
      true,
      true
    ).then((response) => {
      setIsFetchingTimeSlots(false);
      if (response?.data?.success) {
        const schedules = response.data.data;
        let tempSch = [];
        for (let i = 0; i < schedules.length; i++) {
          let timeslotsTemp = [];
          let schTimeslots = schedules[i].timeslots;
          for (let j = 0; j < schTimeslots.length; j++) {
            let obj = {
              start_time: schedules[i].timeslots[j]?.start_time,
              end_time: schedules[i].timeslots[j]?.end_time,
              schedule_type: schedules[i].timeslots[j]?.schedule_type,
              percentage: schedules[i].timeslots[j]?.percentage,
              timeStamp: new Date(
                `${schedules[i].date} ${schedules[i].timeslots[j]?.start_time}`
              ),
              slot_name: timeOfDay(
                `${schedules[i].date} ${schedules[i].timeslots[j]?.start_time}`
              ),
            };
            timeslotsTemp.push(obj);
          }
          let tempObj = {
            date: schedules[i].date,
            timeslots: timeslotsTemp,
          };
          tempSch.push(tempObj);
        }

        setDoctorWalkinTimeSlots(tempSch);
      }
    });
  };

  // handle fetch time slots
  const handleFetchTimeSlots = (selectedApptType, daysGapFromNow, id) => {
    setIsFetchingTimeSlots(true);
    getTimeSlots(
      id,
      noOfDays,
      daysGapFromNow,
      selectedApptType === "TELEMEDICINE"
    ).then((response) => {
      setIsFetchingTimeSlots(false);
      if (response?.data?.success) {
        setTimeSlots(response.data.data[0].timeslots);
      }
    });
  };

  const handleMoveWalkingCarousal = (e, direction) => {
    e.preventDefault();
    let selectedDate = null;
    let daysGap = 0;
    let todayDate = moment().format("YYYY-MM-DD");
    // calculate difference in days, this needs to be sent to api,
    // so that next 3 days schedules can be fetched, starting from this days gap difference

    if (direction === "forward") {
      selectedDate = moment(doctorWalkinTimeSlots[walkingNoOfDays - 1].date);
      daysGap = selectedDate.diff(todayDate, "days");
      
      handleFetchWalkinTimeSlots(daysGap + 1);
    }else{
      const prevDate = moment(doctorWalkinTimeSlots[0].date);
      selectedDate  =  moment(doctorWalkinTimeSlots[walkingNoOfDays - 1].date);
      // daysGap = selectedDate.diff(prevDate, "days");
      // todayDate = moment()
      daysGap = prevDate.diff(todayDate, "days");
      handleFetchWalkinTimeSlots(daysGap <= 0 ? daysGap : daysGap - 3);
    }
  };

  // handle select appt ype
  const handleSelectApptType = (e, apptType) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic(
      "Patient Visit Type Selected",
      null,
      null,
      "Doctor Listing"
    );

    setSelectedApptType(apptType);
    // setDaysGapFromNow(0);
    // setSelectedApptDate(new Date());

    setSelectedLocation(null);

    if (apptType === "TELEMEDICINE") {
      // setFees(doctorDetails.max_telemedicine_fees);
      setUseKulcareApptsFlag(doctorDetails.use_kulcare_appts);
      // fetch time slots
      handleFetchTimeSlots(apptType, daysGapFromNow, doctorId);
    } else if (apptType === "ONCALL") {
      if (clinicLocations.length > 0) {
        setSelectedLocation(clinicLocations[0]);
        setClinicDoctorId(clinicLocations[0].clinic_doctor_id);
        setClinicId(clinicLocations[0].clinicId);
        setFees(handleSetLocationFee(clinicLocations[0]));
        setUseKulcareApptsFlag(clinicLocations[0].use_kulcare_appts);
        handleFetchTimeSlots(
          "ONCALL",
          daysGapFromNow,
          clinicLocations[0].clinic_doctor_id
        );
      } else {
        setSelectedLocation();
        setFees(null);
        setTimeSlots([]);
        setUseKulcareApptsFlag(true);
      }
    }
  };

  // handle select doctor location
  const handleLocationChange = (locObj) => {
    // setSelectedApptDate(new Date());
    logMixpanelEventBasic(
      "Clinic Location Selected",
      null,
      null,
      "Doctor Listing"
    );
    setSelectedLocation(locObj);
    setClinicDoctorId(locObj.clinic_doctor_id);
    setClinicId(locObj.clinicId);
    // setDaysGapFromNow(0);
    setUseKulcareApptsFlag(locObj.use_kulcare_appts);
    setTimeSlotDayLimit(
      locObj?.time_slot_day_limit || defaultAppointmentSlot
    );

    if (selectedApptType === "ONCALL") {
      setFees(handleSetLocationFee(locObj));
      handleFetchTimeSlots(
        selectedApptType,
        daysGapFromNow,
        locObj.clinic_doctor_id
      );
    } else if (selectedApptType === "WALKIN") {
      setFees(handleSetLocationFee(locObj));
    }
  };

  const handleSetApptDate = (val) => {
    logMixpanelEventBasic(
      "Next Availability Viewed",
      null,
      null,
      "Doctor Listing"
    );

    let apptDate = moment(val); // or whatever start date you have

    const today = moment().startOf("day");
    let daysGap = Math.floor(moment.duration(apptDate - today).asDays());
    setSelectedApptDate(new Date(val));
    setDaysGapFromNow(daysGap);
    handleFetchTimeSlots(
      selectedApptType,
      daysGap,
      selectedApptType === "TELEMEDICINE" ? doctorId : clinicDoctorId
    );
  };

  const handleSelectApptTime = (e, timeObj) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic(
      "Patient Visit Time Selected",
      null,
      null,
      "Doctor Listing"
    );
    setApptTime(timeObj?.start_time);
    setClinicDoctorId(timeObj.clinic_doctor_id);
    if (selectedApptType === "TELEMEDICINE") {
      setClinicId(timeObj.clinic_id);
      setFees(timeObj.telemedicine_fees);
    }
  };

  const handleShowVerifyPhoneNumber = () => {
    if (
      selectedApptType === "WALKIN" &&
      preferredStartTime &&
      preferredEndTime
    ) {
      setStepNo(2);
      setShowPatientPhoneField(true);
    } else if (selectedApptType !== "WALKIN" && apptTime && clinicId) {
      setDateTimeString(
        `${formatedShortDate(selectedApptDate)} at ${apptTime}`
      );
      setStepNo(2);
      setShowPatientPhoneField(true);
    } else {
      toast.error("Please select the clinic location and timings");
    }
  };

  const handleVerifyMobileNumber = () => {
    if (!mobileNumber || (mobileNumber && mobileNumber.trim() === "")) {
      toast.error("Please enter your mobile number");
      return false;
    }
    logMixpanelEventBasic(
      "Patient Phone Number Added",
      null,
      selectedApptType === "WALKIN" ? "Care Search" : null,
      "Doctor Listing"
    );

    const params = {
      clinic_doctor_id:
        selectedApptType === "WALKIN" ? selectedClinicDoctorId : clinicDoctorId,
      country_code: 91,
      phone_number: mobileNumber,
      appointment_type:
        selectedApptType === "WALKIN" ? "ONCALL" : selectedApptType,
      payment_option: "BEFORE",
    };
    if (selectedApptType === "WALKIN") {
      params.preferred_start_time = preferredStartTime;
      params.preferred_end_time = preferredEndTime;
    } else {
      const preferredTime =
        moment(selectedApptDate).format("MMMM D, YYYY") +
        " " +
        convertTime12to24(apptTime);

      const preferredTimeTemp = new Date(preferredTime);
      const preferredTimeInISO = preferredTimeTemp.toISOString();
      setPreferredTimeInISO(preferredTimeInISO);
      params.preferred_time = preferredTimeInISO;
    }
    setIsProcessing(true);
    sendVerifyCode(params)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          setVerifyId(response.data.data.id);
          setShowPatientPhoneField(false);
          setShowVerifyOTPField(true);
          setShowTimer(true);
          // handleStartTimer();
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleOTPVerification = () => {
    if (!verificationCode) {
      toast.error("Please enter OTP sent on your mobile number");
      return false;
    }
    const params = {
      verification_code: verificationCode,
    };
    setShowTimer(false);
    setShowPatientPhoneField(false);
    setIsProcessing(true);
    phoneOTPVerification(verifyId, params)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          logMixpanelEventBasic(
            "Patient Phone Number Verified",
            null,
            selectedApptType === "WALKIN" ? "Care Search" : null,
            "Doctor Listing"
          );
          setShowPatientForm(true);
          setShowVerifyOTPField(false);
          handleFetchingClinicPatients();
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleFetchingClinicPatients = () => {
    fetchClinicPatients(clinicId, mobileNumber).then((response) => {
      if (response?.data?.data?.length > 0) {
        if (response.data.data.length > 1)
          setClinicPatientList(response.data.data);
        else {
          const clinicPatient = response.data.data[0].attributes;
          setPatientName(clinicPatient.name);
          if (clinicPatient.gender) setPatientGender(clinicPatient.gender);
          if (clinicPatient.age) setPatientAge(clinicPatient.age);
        }
      }
    });
  };

  const handleOTPChange = (val) => {
    setVerificationCode(val);
  };

  const handlePatientNameChange = (e) => {
    setPatientName(e.target.value);
  };

  const handleSetPatientAge = (e) => {
    setPatientAge(e.target.value);
  };

  const handleGenderChange = (obj) => {
    setPatientGender(obj);
  };

  const handleConfirmAppointment = () => {
    if (
      !patientName ||
      (patientName && patientName.trim() === "") ||
      !patientGender
    ) {
      toast.error("Please enter patient details");
      return false;
    }

    const params = {
      clinic_doctor_id: clinicDoctorId,
      name: patientName,
      country_code: 91,
      phone_number: mobileNumber,
      gender: patientGender.value,
      age: patientAge,
      clinic_patient_id: clinicPatientId,
    };
    setIsProcessing(true);
    createAppointment(verifyId, params)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          logMixpanelEventBasic(
            "Patient Visit Added",
            null,
            selectedApptType === "WALKIN" ? "Care Search" : null,
            "Doctor Listing"
          );
          if (
            selectedApptType === "ONCALL" ||
            selectedApptType === "WALKIN" ||
            (selectedApptType === "TELEMEDICINE" && !fees)
          ) {
            setShowSuccessScreen(true);
            setShowPatientForm(false);
          } else {
            handleCreatingTelemedConsultation(response.data.data);
          }
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleUpdatePayment = (paymentObj, tkn) => {
    setShowSuccessScreen(true);
    setShowPatientForm(false);
  };

  const handleCreatingTelemedConsultation = (apptObj) => {
    var orderParams = {
      clinic_doctor_id: clinicDoctorId,
      patient_id: apptObj.pid,
      appt_verification_id: verifyId,
      payment_option: "BEFORE",
    };

    if (selectedApptType !== "WALKIN")
      orderParams.slot_start_time = preferredTimeInISO;
    setIsProcessing(true);
    createTeleconsultationOrder(orderParams, apptObj.t)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          var options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: "kulcare",
            description: "Telemedicine Payment",
            order_id: response.data.data.razorpay_order_id,
            image:
              "https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/kulcare_logo_black.png",
            handler: (response) => {
              const allData = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              };
              handleUpdatePayment(allData, apptObj.t);
            },
            prefill: {
              name: patientName,
              email: "",
              contact: `91${mobileNumber}`,
            },
            notes: {
              address: "",
            },
            theme: {
              color: "#078DFF",
            },
          };

          let rzp = new window.Razorpay(options);
          rzp.open();
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleSelectClinicPatient = (patientObj) => {
    setClinicPatientId(patientObj.id);
    setPatientName(patientObj.attributes.name);
    setPatientGender({
      label: patientObj.attributes.gender,
      value: patientObj.attributes.gender,
    });
    setPatientAge(patientObj.attributes.age);
  };

  const handleEditTimings = (e) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic(
      "Patient Visit Edited",
      null,
      selectedApptType === "WALKIN" ? "Care Search" : null,
      "Doctor Listing"
    );
    setStepNo(1);
    setShowTimer(false);
    setShowVerifyOTPField(false);
  };

  const handleResendCode = () => {
    setShowTimer(false);
    resendVerifyCode(verifyId).then((response) => {
      if (response?.data?.success) {
        setShowPatientPhoneField(false);
        setShowVerifyOTPField(true);
        setShowTimer(true);
      } else {
        toast.error(response.data.errors[0].title);
      }
    });
  };

  /**
   * @function handleAppDownload
   * @description When user click on download
   */
  const handleAppDownload = () => {
    var os = getMobileOperatingSystem();
    logMixpanelEventBasic(
      "Patient App Downloaded",
      null,
      selectedApptType === "WALKIN" ? "Care Search" : null,
      "Doctor Listing"
    );
    if (os === "Windows Phone" || os === "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.patient_healthcare_app&hl=en_IN",
        "_blank"
      );
    } else {
      window.open(
        "https://apps.apple.com/us/app/kulcare/id1253938993",
        "_blank"
      );
    }
  };

  const handleSelectingPrefferedTime = (selectedTimeSlotObj, slotName) => {
    if(selectedTimeSlotObj){
      setSelectedDaySlot(slotName);
      setSelectedWalkinDate(selectedTimeSlotObj.date);
      const timeSlots = selectedTimeSlotObj[slotName];

      const startTime = `${selectedTimeSlotObj.date} ${timeSlots[0]?.start_time}`;
      const endTime = `${selectedTimeSlotObj.date} ${timeSlots[timeSlots.length - 1].end_time}`;
      setPreferredStartTime(moment(startTime).toISOString());
      setPreferredEndTime(moment(endTime).toISOString())
    }
  };

  return (
    <BookAppointmentView
      doctorDetails={doctorDetails}
      showSuccessScreen={showSuccessScreen}
      selectedApptType={selectedApptType}
      apptTime={apptTime}
      stepNo={stepNo}
      handleSelectApptType={handleSelectApptType}
      selectedLocation={selectedLocation}
      handleLocationChange={handleLocationChange}
      clinicLocations={clinicLocations}
      fees={fees}
      useKulcareApptsFlag={useKulcareApptsFlag}
      doctorAvailabilityDays={doctorAvailabilityDays}
      handleSelectingPrefferedTime={handleSelectingPrefferedTime}
      handleShowVerifyPhoneNumber={handleShowVerifyPhoneNumber}
      selectedApptDate={selectedApptDate}
      handleSetApptDate={handleSetApptDate}
      timeSlots={timeSlots}
      handleSelectApptTime={handleSelectApptTime}
      dateTimeString={dateTimeString}
      showPatientPhoneField={showPatientPhoneField}
      setMobileNumber={setMobileNumber}
      mobileNumber={mobileNumber}
      handleVerifyMobileNumber={handleVerifyMobileNumber}
      showVerifyOTPField={showVerifyOTPField}
      showTimer={showTimer}
      handleResendCode={handleResendCode}
      verificationCode={verificationCode}
      handleOTPChange={handleOTPChange}
      handleOTPVerification={handleOTPVerification}
      showPatientForm={showPatientForm}
      clinicPatientList={clinicPatientList}
      handleSelectClinicPatient={handleSelectClinicPatient}
      handlePatientNameChange={handlePatientNameChange}
      patientName={patientName}
      patientGender={patientGender}
      handleGenderChange={handleGenderChange}
      handleSetPatientAge={handleSetPatientAge}
      patientAge={patientAge}
      handleConfirmAppointment={handleConfirmAppointment}
      handleAppDownload={handleAppDownload}
      handleEditTimings={handleEditTimings}
      doctorWalkinTimeSlots={doctorWalkinTimeSlots}
      daysGapforFetchSchedule={daysGapforFetchSchedule}
      selectedDaySlot={selectedDaySlot}
      selectedWalkinDate={selectedWalkinDate}
      selectedScheduleIndex={selectedScheduleIndex}
      isProcessing={isProcessing}
      isFetchingTimeSlots={isFetchingTimeSlots}
      walkingNoOfDays={walkingNoOfDays}
      handleMoveWalkingCarousal={handleMoveWalkingCarousal}
      timeSlotDayLimit={timeSlotDayLimit}
    />
  );
};

export default BookAppointmentContainer;
