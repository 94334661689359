import React from "react";
import { Popover, Button } from "antd";
import Timer from "react-compound-timer";
import { isMobile } from "react-device-detect";
import { OTPublisher, OTSubscriber } from "opentok-react";
import { convertUTCDateToLocalTime } from "../../../helpers/utils";
import moment from "moment";

const VideoCallView = ({
  handlePopoverVisibilityChange,
  showEndCallPopover,
  showTimer,
  showTextAfterTimer,
  handleOnTimerStop,
  appointmentDetail,
  timerIntialTime,
  sessionHelper,
  streams,
  micOn,
  cameraOn,
  handleToggleMic,
  handleToggleCamera,
  publisherProperties,
  publisherEventHandlers,
  subsciberProperties,
  handleLeaveCall,
  handleFlipCamera,
  otPublisher
}) => {
  const PopoverContent = () => {
    return (
      <div className="end-call-content">
        <div className="heading">Leave call?</div>
        <div className="text">
          You can join the call again untill the doctor ends the call
        </div>
        <div className="action-btns text-center mt-20 mb-10">
          <Button
            className="secondary-btn"
            onClick={() => handlePopoverVisibilityChange(false)}
          >
            No
          </Button>
          <Button type="primary" className="ml-10" onClick={handleLeaveCall}>
            Yes
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="video-call-container">
      <div className="abs-video-call-wrap">
        {sessionHelper &&
          streams.length > 0 &&
          streams.map(stream => {
            if (stream.name === "doctor") {
              return (
                <OTSubscriber
                  key={stream.id}
                  session={sessionHelper.session}
                  stream={stream}
                  properties={{ ...subsciberProperties }}
                  className="doctor-stream"

                  // onSubscribe={handlePatientJoined}
                  // eventHandlers={subscriberEventHandlers}
                />
              );
            }
          })}
        <div className="video-call-top">
          {isMobile && sessionHelper && (
            <a
              href="#"
              className="camera-switch-link "
              onClick={handleFlipCamera}
            ></a>
          )}

          {sessionHelper && (
            <OTPublisher
              ref={otPublisher}
              session={sessionHelper.session}
              eventHandlers={publisherEventHandlers}
              properties={{
                publishAudio: micOn,
                publishVideo: cameraOn,
                ...publisherProperties
              }}
              className="patient-stream"
            />
          )}
        </div>
        <div className="video-call-middle">
          {/* timer start  */}
          {showTimer && streams.length === 0 && (
            <div className="timer-wrapper">
              <div className="heading">Doctor will join in:</div>
              <div className="countdown-timer">
                <Timer
                  initialTime={timerIntialTime}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => handleOnTimerStop()
                    }
                  ]}
                >
                  {() => (
                    <React.Fragment>
                      <Timer.Minutes />:
                      <Timer.Seconds />
                    </React.Fragment>
                  )}
                </Timer>
              </div>
              <div className="text">
                at{" "}
                {appointmentDetail && appointmentDetail.appointment_time
                  ? moment(
                      convertUTCDateToLocalTime(
                        appointmentDetail.appointment_time
                      )
                    ).format("hh:mm a")
                  : ""}
              </div>
            </div>
          )}

          {/* timer end  */}
          {/* after timer text start  */}
          {showTextAfterTimer && streams.length === 0 && (
            <div className="timer-wrapper">
              <div className="heading">Doctor will join the call soon</div>
              <div className="text">
                In case the doctor is not able to take the call, you will get a
                refund within 5-6 working days automatically
              </div>
            </div>
          )}

          {/* after timer text end  */}
        </div>
        <div className="video-call-bottom">
          <a
            href="#"
            className={`video-mic-link ${!micOn ? "off" : ""}`}
            onClick={handleToggleMic}
          ></a>
          <Popover
            content={PopoverContent()}
            title={null}
            trigger="click"
            overlayClassName="call-end-popover"
            visible={showEndCallPopover}
            onVisibleChange={handlePopoverVisibilityChange}
          >
            <a href="#" className="end-call-link"></a>
          </Popover>
          <a
            href="#"
            className={`video-camera-link ${!cameraOn ? "off" : ""}`}
            onClick={handleToggleCamera}
          ></a>
        </div>
      </div>
    </div>
  );
};

export default VideoCallView;
