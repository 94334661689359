import axios from "axios";
// import {getBasicAuthHeaders} from "../helpers/auth";

/**
 * @function getSearchResults
 * @param params
 * @desc This gets search results
 */
export const getSearchResults = (
  page,
  perPage,
  city,
  doctorId,
  searchText,
  filterParams
) => {
  let url = `${process.env.REACT_APP_SERVER_URL}/doctors/listing?page=${page}&perPage=${perPage}`;
  if (city) url += url.includes("?") ? `&city=${city}` : `?city=${city}`;
  if (doctorId)
    url += url.includes("?") ? `&doctor_id=${doctorId}` : `?city=${doctorId}`;
  if (searchText)
    url += url.includes("?")
      ? `&search_text=${searchText}`
      : `?search_text=${searchText}`;
  if (filterParams && filterParams.speciality)
    url += url.includes("?")
      ? `&speciality=${filterParams.speciality}`
      : `?speciality=${filterParams.speciality}`;
  if (filterParams && filterParams.apptType)
    url += url.includes("?")
      ? `&appt_type=${filterParams.apptType}`
      : `?appt_type=${filterParams.apptType}`;
  if (filterParams && filterParams.language)
    url += url.includes("?")
      ? `&language=${filterParams.language}`
      : `?language=${filterParams.language}`;
  if (filterParams && filterParams.minFees)
    url += url.includes("?")
      ? `&min_fees=${filterParams.minFees}`
      : `?min_fees=${filterParams.minFees}`;
  if (filterParams && filterParams.maxFees)
    url += url.includes("?")
      ? `&max_fees=${filterParams.maxFees}`
      : `?max_fees=${filterParams.maxFees}`;

  return axios.get(url);
};

/**
 * @function getCities
 * @desc This gets Cities
 */
export const getCities = () => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors/listing/cities`;
  return axios.get(url);
};

/**
 * @function getSpecialities
 * @desc This gets Cities
 */
export const getSpecialities = () => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors/listing/specialities`;
  return axios.get(url);
};
