import React, { Component } from "react";
import { Menu, Dropdown, Button } from "antd";
import helpersClass from "../../../../helpers/add-to-cal-helpers";
const helpers = new helpersClass();

class AddToCalenderButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCrappyIE: false
    };
  }
  componentDidMount() {
    let isCrappyIE = false;
    if (
      typeof window !== "undefined" &&
      window.navigator.msSaveOrOpenBlob &&
      window.Blob
    ) {
      isCrappyIE = true;
    }

    this.setState({ isCrappyIE: isCrappyIE });
  }

  /**
   * @function handleDropdownLinkClick
   * @description This function will handle link click
   */
  handleDropdownLinkClick = e => {
    e.preventDefault();
    let url = e.currentTarget.getAttribute("href");

    if (
      !helpers.isMobile() &&
      (url.startsWith("data") || url.startsWith("BEGIN"))
    ) {
      let filename = "download.ics";
      let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

      if (this.state.isCrappyIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        /****************************************************************
        // many browsers do not properly support downloading data URIs
        // (even with "download" attribute in use) so this solution
        // ensures the event will download cross-browser
        ****************************************************************/
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, "_blank");
    }
  };
  render() {
    const { isCrappyIE } = this.state;
    const { listItems, event } = this.props;
    const menuItems = listItems.map(item => {
      let currentItem = Object.keys(item)[0];
      let currentLabel = item[currentItem];
      let icon = null;
      let currentIcon =
        currentItem === "outlook" || currentItem === "outlookcom"
          ? "windows"
          : currentItem;
      icon = <i className={"fa fa-" + currentIcon} />;
      return (
        <Menu.Item key={helpers.getRandomKey()}>
          <a
            className={currentItem + "-link"}
            onClick={this.handleDropdownLinkClick}
            href={helpers.buildUrl(event, currentItem, isCrappyIE)}
            target="_blank"
          >
            {icon}
            {currentLabel}
          </a>
        </Menu.Item>
      );
    });
    const menu = <Menu className="react-add-to-calendar">{menuItems}</Menu>;
    return (
      <Dropdown
        overlay={menu}
        className="add-to-calendar-button mt-15"
        placement="bottomCenter"
        trigger="click"
      >
        <Button>
          <i className="button-calender-icon"></i> Add to Calender
        </Button>
      </Dropdown>
    );
  }
}

export default AddToCalenderButton;
