import React from "react";
import { Button } from "antd";
import AppointmentModal from "./AppointmentModal";
import { generateDevicePermissionImage } from "../../../helpers/utils";

const AllowPermissionInfoModal = ({
  showAllowPerModal,
  toggleAllowPerModal
}) => {
  return (
    <AppointmentModal
      showModal={showAllowPerModal}
      toggleModal={toggleAllowPerModal}
    >
      <div className="allow-permission-content">
        <div className="heading">Allow Permissions</div>
        <div className="text">
          Make sure to{" "}
          <span className="text-bold">
            allow the Camera and Microphone access
          </span>{" "}
          that you might be asked for on the next screen
        </div>
        <div className="per-image-wrap text-center mt-50">
          <img src={generateDevicePermissionImage()} />
        </div>

        <div className="mt-50 text-center">
          <Button type="primary" onClick={toggleAllowPerModal}>
            Ok, Got it
          </Button>
        </div>
      </div>
    </AppointmentModal>
  );
};

export default AllowPermissionInfoModal;
