import React, { Component } from "react";
import { Spin } from "antd";
import FileSaver from "file-saver";
import { getAuthFile } from "../../../Appointments/bookAppointmentService";
import {
  dataURItoBlob,
  logMixpanelEventBasic,
} from "../../../../helpers/utils";
import moment from "moment";
import { showSuccessNotification } from "../../../../helpers/showNotifications";

class PrescriptionThumbnail extends Component {
  state = {
    isProcessing: true,
    fileSrc: null,
    fileType: null,
    fullUrl: null,
    url: null,
  };

  componentDidMount() {
    const { url, fileType, fullUrl } = this.props;
    this.setState({ fileType, fullUrl, url }, () => {
      this.getFile(url);
    });
  }

  /**
   * @function getFile
   * @param url
   * @desc This function gets the authorized file
   */
  getFile = (url) => {
    getAuthFile(url).then((res) => {
      const reader = new window.FileReader();
      reader.readAsDataURL(res.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        this.setState({
          fileSrc: base64data,
          isProcessing: false,
        });
      };
    });
  };

  /**
   * @function getFile
   * @param url
   * @desc This function gets the authorized file
   */
  handleDownload = (e) => {
    e.preventDefault();
    const { patientId } = this.props;
    const { url, fileType, fullUrl } = this.state;
    const fileUrl = fileType.includes("pdf") ? url : fullUrl;
    const type = fileType.includes("pdf") ? "pdf" : "image";
    const fileName = moment().format("DD-MM-YYYY_h_mm_ss");
    logMixpanelEventBasic(
      "Prescription Viewed",
      patientId,
      "Teleconsult Marketplace"
    );
    getAuthFile(fileUrl).then((res) => {
      const reader = new window.FileReader();
      reader.readAsDataURL(res.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        const blob = dataURItoBlob(base64data);
        const extension = base64data.substring(
          base64data.indexOf("/") + 1,
          base64data.indexOf(";base64")
        );
        FileSaver.saveAs(
          blob,
          `${
            type === "pdf"
              ? `teleconsult_prescription${fileName}.pdf`
              : `teleconsult_prescription${fileName}.${extension}`
          }`
        );
        // showSuccessNotification("Prescription Downloaded!");
        showSuccessNotification("Prescription Downloaded!");
      };
    });
  };

  render() {
    const { isProcessing, fileSrc, fileType } = this.state;

    return (
      <a href="#" className="pdf-link-container" onClick={this.handleDownload}>
        <Spin spinning={isProcessing}>
          {fileType && fileType.includes("pdf") && (
            <span className="pdf-image"></span>
          )}
          {fileType && fileType.includes("image") && fileSrc && (
            <img src={fileSrc} />
          )}
        </Spin>
      </a>
    );
  }
}

export default PrescriptionThumbnail;
