import React from "react";
import {IoIosVideocam} from "react-icons/io";
import {FaFilePrescription} from "react-icons/fa";

const StepCard = ({ step, status, type, heading, text, rightContent }) => {
  return (
    <div className={`teleconsultation-step-card mt-15 ${status ? status : ""}`}>
      <div className="teleconsultation-left-content">
        <div className="teleconsultation-step-inner-heading">{heading}</div>
        <div className="teleconsultation-step-inner-sub-heading">
          {text}
        </div>
      </div>
      {rightContent}
      <div className="teleconsultation-step-number">
        {step === "video" && <IoIosVideocam size={16}/>}
        {step === "prescription" && <FaFilePrescription size={16}/>}
      </div>
    </div>
  );
};

export default StepCard;
