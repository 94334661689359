import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./components/Search/index";
import Appointments from "./components/Appointments";
import DoctorProfile from "./components/DoctorProfile";
import Header from "./components/Header";
import SearchContextProvider from "./components/SearchContextProvider";
import HospitalProfile from "./components/HospitalProfile";
import ClinicProfile from "./components/ClinicProfile";
import TeleconsultationSearch from "./components/Search/TeleconsultationSearch";
import { setInitialLanguage } from "./helpers/locales";
import { keyLocallyPresent } from "./helpers/utils";
import VideoCall from "./components/Search/VideoCall/index";
import VideoCallContainer from "./components/Search/VideoCall/VideoCallContainer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreview: false,
    };
    if (keyLocallyPresent("kc_lang"))
      setInitialLanguage(sessionStorage.getItem("kc_lang"));
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get("preview");
    this.setState({ isPreview });
  }

  // componentWillUnmount(){
  //   localStorage.clear();
  // }

  render() {
    const { isPreview } = this.state;
    return (
      <div className={`doc-search-wrapper ${isPreview ? "readonly" : ""}`}>
        <SearchContextProvider>
          {!isPreview && <Header />}
          <BrowserRouter>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/search" exact component={Home} />
              <Route path="/book_appointment" exact component={Appointments} />
              <Route path="/doctors/:did" exact component={DoctorProfile} />
              <Route path="/hospitals" exact component={HospitalProfile} />
              <Route path="/clinics/:id" exact component={ClinicProfile} />
              <Route
                path="/doctors/:did/book_appointment"
                exact
                component={DoctorProfile}
              />
              <Route
                path="/teleconsult"
                exact
                component={TeleconsultationSearch}
              />
              <Route
                exact
                path="/tele"
                render={() => <Redirect to="/teleconsult" />}
              />
              <Route
                exact
                path="/telemed"
                render={() => <Redirect to="/teleconsult" />}
              />
              <Route exact path="/patient-teleconsult" component={VideoCall} />
              <Route
                path="/video-call"
                render={(props) => (
                  <VideoCallContainer
                    {...props}
                    opentokClientUrl={process.env.REACT_APP_OPENTOK_SDK_LINK}
                  />
                )}
              />
            </Switch>
          </BrowserRouter>
        </SearchContextProvider>
      </div>
    );
  }
}

export default App;
