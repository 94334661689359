import React, { Component } from "react";
import { Spin } from "antd";
import qs from "qs";
import moment from "moment";
import Pusher from "react-pusher";
import { createSession, preloadScript } from "opentok-react";
import DeniedPermissionInfoModal from "./DeniedPermissionModal";
import VideoCallView from "./VideoCallView";
import {
  fetchAppointmentDetail,
  joinTeleconsultPatient,
  generateTeleConsultToken,
} from "../../Appointments/bookAppointmentService";
import {
  decryptId,
  encryptId,
  convertUTCDateToLocalTime,
  logMixpanelEventBasic,
} from "../../../helpers/utils";
import { showErrorNotification } from "../../../helpers/showNotifications";

class VideoCallContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentId: "",
      countryCode: "",
      phoneNumber: "",
      appointmentDetail: null,
      isProcessing: true,
      showDeniedPerModal: false,
      showEndCallPopover: false,
      showTimer: false,
      showTextAfterTimer: false,
      timerIntialTime: 0,

      sessionId: null,
      token: null,
      callStarted: false,
      showEndCall: false,
      patientJoined: false,
      streams: [],
      connectionId: null,
      micOn: true,
      cameraOn: true,
      patientsVideoIsOn: true,
      memberType: "patient",
      isCovidClinic: false,
      cameraMode: "user",
      apptDate: null,
    };
    this.otPublisher = React.createRef();
    this.subsciberProperties = {
      showControls: false,
    };
    this.subscriberEventHandlers = {
      videoDisabled: (event) => {
        this.handleOnPatientVideoToggle(false);
      },
      videoEnabled: (event) => {
        this.handleOnPatientVideoToggle(true);
      },
    };
    this.publisherEventHandlers = {
      accessDenied: (event) => {
        logMixpanelEventBasic("Permission Denied");
        this.toggleDeniedPerModal();
      },
      accessAllowed: (event) => {
        logMixpanelEventBasic("Permission Allowed");
      },
    };
    this.publisherProperties = {
      showControls: false,
      name: this.state.memberType,
      facingMode: this.state.cameraMode,
    };
  }

  componentDidMount() {
    // get params from url
    const parsedParams = qs.parse(this.props.location.search);
    const appointmentId = parsedParams["apid"] || parsedParams["apid"];
    const countryCode = parsedParams["cc"];
    const phoneNumber = parsedParams["ph"];

    this.handleUpdateParams();

    // decrypt params
    if (
      appointmentId !== null &&
      countryCode !== null &&
      phoneNumber !== null
    ) {
      this.setState(
        {
          appointmentId: appointmentId ? decryptId(appointmentId) : null,
          countryCode: countryCode ? decryptId(countryCode) : null,
          phoneNumber: phoneNumber ? decryptId(phoneNumber) : null,
        },
        () => {
          this.getAppointmentDetail(decryptId(appointmentId));
        }
      );
    } else {
      this.redirectToDetailsPage();
    }
  }

  /**
   * @function getAppointmentDetail
   * @param appointmentId
   * @description This function will get appointment details
   */
  getAppointmentDetail = (appointmentId) => {
    fetchAppointmentDetail(appointmentId).then((response) => {
      this.setState({ isProcessing: false });
      if (response.data.success) {
        const record = response.data.data;

        const time = record.appointment_time
          ? moment(convertUTCDateToLocalTime(record.appointment_time)).format(
              "hh:mm A"
            )
          : null;
        const date = record.appointment_time
          ? moment(convertUTCDateToLocalTime(record.appointment_time)).format(
              "DD MMM, YYYY"
            )
          : null;
        this.setState(
          {
            appointmentDetail: record,
            isCovidClinic:
              record.clinic_details.covid_clinic ||
              record.clinic_details.private_clinic,
            sessionId: record.session_details.session_id,
            appointmentId: record.id,
            cameraOn: record.clinic_details.covid_clinic ? false : true,
          },
          () => {
            this.handlePatientJoinedTeleconsult();
          }
        );

        // if appointment expired or done
        if (this.isAppointmentExpiredOrDone(record)) {
          this.redirectToDetailsPage();
        } else {
          this.handleShowTextOrTimer(record);
        }
      }
    });
  };

  handleUpdateParams = () => {
    const parsedParams = qs.parse(this.props.location.search);

    const doctorId = parsedParams["?did"];
    const clinicDoctorId = parsedParams["cdid"];
    const clinicId = parsedParams["clid"];
    const verifyId = parsedParams["vid"];
    const mobileNumber = parsedParams["ph"];
    const confirmed = parsedParams["cn"];
    // let token = parsedParams["t"];
    const appointmentId = parsedParams["apid"];
    const apptDate = parsedParams["dt"];

    this.setState({
      doctorId: doctorId ? decryptId(doctorId) : null,
      clinicDoctorId: clinicDoctorId ? decryptId(clinicDoctorId) : null,
      clinicId: clinicId ? decryptId(clinicId) : null,
      verifyId: verifyId ? decryptId(verifyId) : null,
      mobileNumber: mobileNumber ? decryptId(mobileNumber) : null,
      confirmed: confirmed || false,
      // token: token || null,
      appointmentId: appointmentId ? decryptId(appointmentId) : null,
      apptDate: apptDate || null,
    });
  };

  /**
   * @function checkAndRedirectToDetailsPage
   * @description This function will rediect to details page
   */
  redirectToDetailsPage = () => {
    // const {
    //   doctorId,
    //   clinicDoctorId,
    //   clinicId,
    //   verifyId,
    //   mobileNumber,
    //   appointmentId,
    //   countryCode,
    //   apptDate,
    // } = this.state;

    // window.open(
    //   `/patient-teleconsult?did=${encryptId(doctorId)}&cdid=${encryptId(
    //     clinicDoctorId
    //   )}&clid=${encryptId(clinicId)}&dt=${apptDate}&vid=${encryptId(
    //     verifyId
    //   )}&ph=${encryptId(mobileNumber)}&cc=${encryptId(
    //     countryCode
    //   )}&cn=true&apid=${encryptId(appointmentId)}`,
    //   "_self"
    // );
    window.top.close();
  };

  /**
   * @function isAppointmentExpiredOrDone
   * @description This function will check if appointment expired or done
   */
  isAppointmentExpiredOrDone = (appointment) => {
    let appointmentDone = false;

    if (
      appointment.session_details.session_status === "COMPLETE" ||
      appointment.appointment_status === "CHECKED_OUT" ||
      appointment.appointment_status === "REMOVED"
    ) {
      appointmentDone = true;
    }

    return appointmentDone;
  };

  /**
   * @function handleShowTextOrTimer
   * @description This function will check which text to show on video call screen
   */
  handleShowTextOrTimer = (appointment) => {
    let showTimer = false;
    let showTextAfterTimer = false;
    let timerIntialTime = 0;

    const currentDateTime = moment().format("LT");
    const currentDateTime24 = moment(currentDateTime, "LT").format("HH:mm");

    const appointmentDateTime = moment(appointment.appointment_time).format(
      "LT"
    );
    const appointmentDateTime24 = moment(appointmentDateTime, "LT").format(
      "HH:mm"
    );

    const timeDiff = moment(appointment.appointment_time).diff(
      moment(new Date()),
      "seconds"
    );

    if (
      currentDateTime24 <= appointmentDateTime24 &&
      timeDiff <= 600 &&
      timeDiff >= 1
    ) {
      showTimer = true;
      timerIntialTime = timeDiff * 1000;
    } else {
      showTextAfterTimer = true;
    }

    this.setState({
      showTimer,
      showTextAfterTimer,
      timerIntialTime,
    });
  };

  handleOnTimerStop = () => {
    this.setState({ showTimer: false, showTextAfterTimer: true });
  };

  handlePopoverVisibilityChange = (visible) => {
    this.setState({ showEndCallPopover: visible });
  };

  toggleDeniedPerModal = () => {
    this.setState({ showDeniedPerModal: !this.state.showDeniedPerModal });
  };

  /**
   * @function handleOnPatientVideoToggle
   * @description When patient toggles video on and off
   */
  handleOnPatientVideoToggle = (value) => {
    this.setState({
      patientsVideoIsOn: value,
    });
  };

  /**
   * @function handlePatientJoinedTeleconsult
   * @description Api call when patient joined
   */
  handlePatientJoinedTeleconsult = () => {
    const { appointmentId, memberType } = this.state;
    joinTeleconsultPatient(appointmentId, memberType).then((response) => {
      this.handleStartCall();
    });
  };

  /**
   * @function handleStartCall
   * @description this functions will generate token
   * and start the call
   */
  handleStartCall = () => {
    const { appointmentId, memberType } = this.state;
    const owner = memberType === "family_member" ? "family" : "patient";
    logMixpanelEventBasic(
      "Teleconsultation Call Started",
      null,
      "Teleconsult Marketplace"
    );
    generateTeleConsultToken(appointmentId, owner).then((response) => {
      if (response.data.success) {
        this.setState(
          {
            token: response.data.data.token,
            isProcessing: false,
          },
          () => {
            this.sessionHelper = createSession({
              apiKey: process.env.REACT_APP_VONAGE_API_KEY,
              sessionId: this.state.sessionId,
              token: this.state.token,
              onStreamsUpdated: (streams) => {
                this.setState({
                  streams,
                  showEndCall: true,
                  connectionId: this.sessionHelper.session.connection.id,
                });
              },
            });
            this.setState({
              callStarted: true,
            });
          }
        );
      }
    });
  };

  /**
   * @function handleLeaveCall
   * @description this functions will leave call
   */
  handleLeaveCall = () => {
    this.redirectToDetailsPage();
  };

  /**
   * @function handleFlipCaemra
   * @description this functions will toggle camera
   */
  handleFlipCamera = (e) => {
    // const { cameraMode } = this.state;
    if (e) e.preventDefault();

    if (this.otPublisher && this.otPublisher.current) {
      const publisher = this.otPublisher.current.getPublisher();
      publisher.cycleVideo().catch((err) => {
        if (err) showErrorNotification(err);
      });
    }
  };

  /**
   * @function handleToggleMic
   * @description This will toggle doctor audio
   */
  handleToggleMic = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return { micOn: !prevState.micOn };
    });
  };

  /**
   * @function handleToggleCamera
   * @description This will toggle doctor Video
   */
  handleToggleCamera = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return { cameraOn: !prevState.cameraOn };
    });
  };

  /**
   * @function handleOnPatientVideoToggle
   * @description When patient toggles video on and off
   */
  handleOnPatientVideoToggle = (value) => {
    this.setState({
      patientsVideoIsOn: value,
    });
  };

  /**
   * @function handleRefreshPage
   * @description this functions refresh the page
   */
  handleRefreshPage = () => {
    window.location.reload();
  };

  componentWillUnmount() {
    if (this.sessionHelper) {
      this.sessionHelper.disconnect();
    }
  }

  render() {
    const {
      showDeniedPerModal,
      showEndCallPopover,
      isProcessing,
      showTimer,
      showTextAfterTimer,
      appointmentDetail,
      timerIntialTime,
      callStarted,
      sessionId,
      streams,
      showEndCall,
      micOn,
      cameraOn,
      patientsVideoIsOn,
    } = this.state;

    return (
      <Spin spinning={isProcessing} wrapperClassName="fixed-spinner">
        {appointmentDetail && (
          <Pusher
            channel={process.env.REACT_APP_PUSHER_CHANNEL}
            event={`session_completed_${appointmentDetail.id}`}
            onUpdate={this.handleLeaveCall}
          />
        )}
        <VideoCallView
          handlePopoverVisibilityChange={this.handlePopoverVisibilityChange}
          showEndCallPopover={showEndCallPopover}
          showTimer={showTimer}
          showTextAfterTimer={showTextAfterTimer}
          handleOnTimerStop={this.handleOnTimerStop}
          appointmentDetail={appointmentDetail}
          timerIntialTime={timerIntialTime}
          streams={streams}
          callStarted={callStarted}
          handleStartCall={this.handleStartCall}
          sessionId={sessionId}
          subscriberEventHandlers={this.subscriberEventHandlers}
          sessionHelper={this.sessionHelper}
          handleEndCall={this.handleEndCall}
          handlePatientJoined={this.handlePatientJoined}
          showEndCall={showEndCall}
          micOn={micOn}
          cameraOn={cameraOn}
          publisherProperties={this.publisherProperties}
          handleToggleCamera={this.handleToggleCamera}
          handleToggleMic={this.handleToggleMic}
          patientsVideoIsOn={patientsVideoIsOn}
          publisherEventHandlers={this.publisherEventHandlers}
          subsciberProperties={this.subsciberProperties}
          handleLeaveCall={this.handleLeaveCall}
          handleFlipCamera={this.handleFlipCamera}
          otPublisher={this.otPublisher}
        />

        <DeniedPermissionInfoModal
          showDeniedPerModal={showDeniedPerModal}
          toggleDeniedPerModal={this.toggleDeniedPerModal}
          handleRefreshPage={this.handleRefreshPage}
        />
      </Spin>
    );
  }
}
export default preloadScript(VideoCallContainer);
