import React from "react";
import BookingWidgetComponent from "../../Appointments/index";

export default class TimingsShowComponent extends React.Component {
  render() {
    const { doctorDetails, componentViewMode, isProcessing } = this.props;
    return (
      <BookingWidgetComponent
        doctorId={doctorDetails?.doctor_id}
        componentViewMode={componentViewMode}
        isProcessing={isProcessing}
        doctorDetails={doctorDetails}
      />
    );
  }
}
