import React, { Component } from "react";

class AwardsContainer extends Component {
  render() {
    return (
      <div className="hospital-awards-contributions mb-10">
        <div className="hospital-heading">
          <h2>Awards and Contributions</h2>
        </div>

        <ul className="awards_wrapper">
          <li>
            <div>
              <h3>Award 1</h3>
              <p>MBBS (Bachelor of Medicine, Bachelor of Surgery)</p>
              <p className="font-color-grey">2002</p>
            </div>
          </li>
          
          <li>
            <div>
              <h3>Award 2</h3>
              <p>BYNS (Bachelor of Yoga and Naturopathy Sciences)</p>
              <p className="font-color-grey">2002</p>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default AwardsContainer;
