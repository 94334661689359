import React, { Component } from "react";
import { getDocImage, getInitials } from "../../../helpers/utils";

class DoctorList extends Component {
  render() {
    const { doctors, handleGotoDoctorProfile } = this.props;
    return (
      <div className="speciality_wrapper mb-40">
        <div className="hospital-heading">
          <h2>Doctors</h2>
        </div>
        <div className="specialities-accordion mt-10">
          <div className="spec_card_wrapper doctor_list_wrapper">
            {doctors?.length > 0 &&
              doctors.map((obj, index) => {
                return (
                  <div
                    className={`spec_card ${
                      obj.listed ? "cursor_pointer" : ""
                    }`}
                    key={obj?.id}
                    onClick={() => handleGotoDoctorProfile(obj)}
                  >
                    <div className="img-card">
                      <div
                        className="img-card-inner"
                        style={{
                          backgroundImage: `url('${getDocImage(obj)}')`,
                        }}
                      >
                        {obj && (!obj.photo || obj.photo.trim() === "") && (
                          <div className="name-initials">
                            {getInitials(obj.name)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="content">
                      <h5>{obj?.name}</h5>
                      <p>{obj?.speciality}</p>
                      {obj?.exp && (
                        <p className="font-color-light-grey font-size-13">{`${obj?.exp} yrs exp`}</p>
                      )}
                    </div>
                    {/* <div className="right-btn">
                      <button>Book</button>
                    </div> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default DoctorList;
