import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { BiLinkAlt } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdLocationOn, MdPhone, MdVideocam } from "react-icons/md";
import { BsCalendar3EventFill } from "react-icons/bs";
import { RiUser3Fill } from "react-icons/ri";
import moment from "moment";
import { commaSepNum } from "../../Appointments/helpers";
// const { Panel } = Collapse;

const showAppointmentTypes = (location) => {
  const appointmentTypes = [];
  if (location?.takes_in_clinic) appointmentTypes.push("Walk-ins");
  if (location?.takes_teleconsultation) appointmentTypes.push("Virtual");
  if (location?.takes_appointment) appointmentTypes.push("Appointments");
  return appointmentTypes.length > 0 ? appointmentTypes.join(", ") : "";
};

const setAddressLine = (location) => {
  const loc = [];
  if (location?.clinic?.name) loc.push(location?.clinic?.name);
  if (location?.location?.address_line_1)
    loc.push(location?.location?.address_line_1);
  if (location?.location?.city) loc.push(location?.location?.city);
  if (location?.location?.state) loc.push(location?.location?.state);
  if (location?.location?.zipcode) loc.push(location?.location?.zipcode);
  return loc.join(", ");
};

const handleShowPhoneNumbers = (profileObj) => {
  let doctorPhoneNumbers = [];
  if (
    profileObj?.location?.phone_numbers &&
    profileObj.location.phone_numbers.length > 0
  ) {
    const phoneNumbers = profileObj.location.phone_numbers;
    phoneNumbers.map((phoneObj) => {
      if (phoneObj.phone_number) doctorPhoneNumbers.push(phoneObj.phone_number);
    });
  } else {
    doctorPhoneNumbers.push(profileObj?.location?.phone_number);
  }
  return doctorPhoneNumbers.join(", ");
};

const LocationShowComponent = ({ locations }) => {
  return (
    <div className="mnt-5">
      <Accordion
        allowZeroExpanded={true}
        preExpanded={[`${locations[0]?.clinic?.id}_${0}`]}
      >
        {locations.length > 0 &&
          locations.map((location, i) => (
            <AccordionItem
              key={`${location?.clinic?.id}_${i}`}
              uuid={`${location?.clinic?.id}_${i}`}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  {location?.clinic?.name}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="location_wraper">
                  <div className="loc_left_section">
                    {setAddressLine(location) ? (
                      <div className="location-item tp-2 lft-2">
                        <MdLocationOn color="#353949" size="18px" />
                        {setAddressLine(location)}
                      </div>
                    ) : (
                      ""
                    )}

                    {location.schedule &&
                      location.schedule.length > 0 &&
                      location.schedule.map((schedule) => (
                        <div className="location-item tp-3">
                          <AiOutlineClockCircle color="#353949" size={"16px"} />

                          <div>
                            {schedule.days}:{" "}
                            {schedule.time.map((timeSlot) => {
                              return (
                                <div className="font-weight-bold">
                                  {timeSlot?.start_time} - {timeSlot?.end_time}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ))}

                    {/* {location?.location?.phone_number && (
                      <div className="location-item tp-3">
                        <MdPhone color="#353949" size={"16px"} />
                        {handleShowPhoneNumbers(location)}
                      </div>
                    )} */}
                    {location &&
                      location.use_kulcare_appts &&
                      location.book_appt_link && (
                        <div className="location-item tp-3">
                          <BiLinkAlt color="#353949" size={"16px"} />
                          <a
                            href={location.book_appt_link}
                            target="_blank"
                            className="blue-link"
                          >
                            {location.book_appt_link}
                          </a>
                        </div>
                      )}
                    {location &&
                      !location.use_kulcare_appts &&
                      location?.booking_partners?.length > 0 && (
                        <div className="location-item tp-3">
                          <BiLinkAlt color="#353949" size={"16px"} />
                          <a
                            href={location.booking_partners[0].booking_link}
                            target="_blank"
                            className="blue-link"
                          >
                            {location.booking_partners[0].booking_link}
                          </a>
                        </div>
                      )}
                  </div>

                  {location && (
                    <div className="loc_right_section">
                      {showAppointmentTypes(location) && (
                        <div className="location-item tp-3">
                          <BsCalendar3EventFill color="#353949" size={"12px"} className="trx-2"/>
                          {showAppointmentTypes(location)}
                        </div>
                      )}

                      {location.fees && location.takes_in_clinic && (
                        <div className="location-item tp-3">
                          <RiUser3Fill color="#353949" size={"16px"} />
                          {`₹${commaSepNum(location.fees)}`}
                        </div>
                      )}
                      {location.telemedicine_fees &&
                        location.takes_teleconsultation && (
                          <div className="location-item tp-3">
                            <MdVideocam
                              color="#353949"
                              size={"18px"}
                              className="md_video_icon"
                            />
                            {`₹${commaSepNum(location.telemedicine_fees)}`}
                          </div>
                        )}
                      {location.fixed_appt_fees && location.takes_appointment && (
                        <div className="location-item tp-3">
                          <BsCalendar3EventFill color="#353949" size={"12px"} className="trx-2"/>
                          {`₹${commaSepNum(location.fixed_appt_fees)}`}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {/* <div className="location_wraper">
                  <div className="location-item tp-2">
                    {setAddressLine(location) ? (
                      <>
                        <IoLocationOutline color="#999EBC" size="18px" />
                        {setAddressLine(location)}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {location?.schedule &&
                    location?.schedule.length > 0 &&
                    location?.schedule.map((schedule) => (
                      <div className="location-item tp-3">
                        <BsClock color="#999EBC" />
                        {schedule.days.join(", ")}:{" "}
                        {moment(schedule.start_time).format("hh:mm a")} -{" "}
                        {moment(schedule.end_time).format("hh:mm a")}
                      </div>
                    ))}
                  {location?.location?.email && (
                    <div className="location-item tp-5">
                      <AiOutlineMail color="#999EBC" />
                      {location?.location?.email}
                    </div>
                  )}
                  {location?.location?.phone_number && (
                    <div className="location-item tp-3">
                      <HiPhone color="#999EBC" />
                      {location?.location?.phone_number}
                    </div>
                  )}
                </div> */}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
};

export default LocationShowComponent;
