import React, { useState, createContext } from "react";
export const PageContext = createContext();

const SearchContextProvider = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchOnEnter, setSearchOnEnter] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState("doctors");
  const [selectedLocation, setSelectedLocation] = useState({
    name: "Chandigarh",
    lat: 30.7333,
    lng: 76.7794,
  });

  return (
    <PageContext.Provider
      value={{
        searchText: searchText,
        selectedLocation: selectedLocation,
        setSelectedLocation: setSelectedLocation,
        setSearchText: setSearchText,
        searchOnEnter: searchOnEnter,
        setSearchOnEnter: setSearchOnEnter,
        selectedSearchType:selectedSearchType,
        setSelectedSearchType:setSelectedSearchType
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export default SearchContextProvider;
