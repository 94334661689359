import axios from "axios";
import { getBasicAuthHeaders, getAuthHeaders } from "../../helpers/auth";

/**
 * @function getCities
 * @desc This gets Cities
 */
export const fetchDoctorProfileDetail = (doctorId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors/public_page/${doctorId}`;
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};

export const getTimeSlots = (
  doctorId,
  noOfDays,
  daysGap,
  isVideoCall,
  skipUnavailable,
  splSlots
) => {
  let url = `${
    process.env.REACT_APP_SERVER_URL
  }/public_appointments/time_slots?${
    isVideoCall ? "doctor_id" : "clinic_doctor_id"
  }=${doctorId}&days_num=${noOfDays}&days_gap=${daysGap}&teleconsultation=${isVideoCall}`;
  if (skipUnavailable) url = `${url}&skip_unavailable=true`;
  if (splSlots) {
    url = `${url}&include_spl=true`;
  }
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};

export const sendVerifyCode = (params) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/public_appointments/send_code`;
  return axios.post(url, params, {
    headers: getBasicAuthHeaders(),
  });
};

export const phoneOTPVerification = (verifyId, params) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/public_appointments/${verifyId}/verify`;
  return axios.post(url, params, {
    headers: getBasicAuthHeaders(),
  });
};

export const fetchClinicPatients = (clinicId, phoneNumber) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/clinics/${clinicId}/patients/search?phone=${phoneNumber}&perpage=25&page=1`;
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};

export const createAppointment = (verifyId, params) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/public_appointments/${verifyId}`;
  return axios.put(url, params, {
    headers: getBasicAuthHeaders(),
  });
};

export const createTeleconsultationOrder = (params, token = null) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/telemedicine/orders`;
  let headers = null;
  if(token) headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
  else headers = getAuthHeaders();

  return axios.post(
    url,
    params,
    {
      headers,
    }
  );
};

export const resendVerifyCode = (verifyId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/public_appointments/${verifyId}/resend_code`;
  return axios.post(
    url,
    {},
    {
      headers: getBasicAuthHeaders(),
    }
  );
};

/**
 * @function getCities
 * @desc This gets Cities
 */
export const fetchDoctorProfileDetailES = (doctorId) => {
  const params = {
    query: {
      term: { doctor_id: doctorId },
    },
  };
  const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_ONLY_DOCTORS_INDEX}/_search`;
  return axios.post(url, params);
};

/**
 * @function fetchDoctorDetail
 * @desc This gets doctor detail from elastic search
 */
export const fetchDoctorDetail = (doctorId, groupId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors/public_page/${doctorId}?only_locations=true&clinic_group_id=${groupId}`;
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};

/**
 * @function fetchListedDoctorDetail
 * @desc This gets doctor detail from api
 */
 export const fetchListedDoctorDetail = (doctorId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/doctors/listing/${doctorId}`;
  return axios.get(url, {
    headers: getBasicAuthHeaders(),
  });
};

export const fetchAppointmentDetail = (appId) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/appointments/${appId}`;
  
  return axios.get(url, {
    headers: getAuthHeaders()
  });
}

/**
 * @function getAuthFile
 * @param url
 * @desc This function gets the authorized file
 */
 export const getAuthFile = url =>
 axios.get(url, {
   responseType: "blob",
   headers: getAuthHeaders()
 });

 /**
 * @function joinTeleconsultjoinTeleconsultPatient
 * @param apptId
 * @description This function depicts that doctor joined the session
 */
export const joinTeleconsultPatient = (apptId, memberType) => {
  let url = `${process.env.REACT_APP_SERVER_URL}/appointments/${apptId}/sessions/`;
  if (memberType === "patient") {
    url += "join_patient";
  } else {
    url += "join_family";
  }
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders()
    }
  );
};

/**
 * @function generateTeleConsultToken
 * @param apptId
 * @description This function will generate token for teleconsult
 */
 export const generateTeleConsultToken = (apptId, owner) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/appointments/${apptId}/sessions/generate_token`;
  return axios.post(
    url,
    {
      owner_type: owner
    },
    {
      headers: getAuthHeaders()
    }
  );
};
