import React from "react";
import { Modal } from "antd";

const AppointmentModal = props => {
  return (
    <Modal
      wrapClassName={`dark-modal ${
        props.verticallyCentered ? "absolute-v-centered" : ""
      }`}
      title={null}
      footer={null}
      visible={props.showModal}
      onCancel={props.toggleModal}
      maskClosable={false}
      closable={false}
    >
      {props.children}
    </Modal>
  );
};

export default AppointmentModal;
