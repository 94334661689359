import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker";
import { useEffect, useState } from "react";

const mapStyles = [
  {
    featureType: "landscape.man_made",
    elementType: "geometry",
    stylers: [
      {
        color: "#f7f1df",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#d0e3b4",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "geometry",
    stylers: [
      {
        color: "#fbd3da",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#bde6ab",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffe15f",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#efd151",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "black",
      },
    ],
  },
  {
    featureType: "transit.station.airport",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#cfb2db",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#a2daf2",
      },
    ],
  },
];

const SearchMap = ({
  hoverIndex,
  searchResults,
  selectedLocation,
  previewListOnly,
}) => {
  const [mapCenter, setMapCenter] = useState({
    lat:
      selectedLocation && selectedLocation.lat ? selectedLocation.lat : 30.7333,
    lng:
      selectedLocation && selectedLocation.lng ? selectedLocation.lng : 76.7794,
  });

  const zoom = 10;
  const mapKey = `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
  useEffect(() => {
    if (selectedLocation.lat) {
      setMapCenter({ lat: selectedLocation.lat, lng: selectedLocation.lng });
    }
  }, [selectedLocation]);

  return (
    <GoogleMapReact
      // defaultCenter={mapCenter}
      center={mapCenter}
      defaultZoom={zoom}
      bootstrapURLKeys={{ key: mapKey }}
      options={{
        styles: mapStyles,
      }}
    >
      {!previewListOnly &&
        searchResults.length > 0 &&
        searchResults.map((item, index) => (
          <MapMarker
            lat={
              item?._source?.location?.latitude
                ? item?._source?.location?.latitude
                : mapCenter.lat
            }
            lng={
              item?._source?.location?.longitude
                ? item?._source?.location?.longitude
                : mapCenter.lng
            }
            text={item?._source?.name}
            key={index}
            hoverIndex={hoverIndex}
            id={index}
          />
        ))}

      {previewListOnly &&
        searchResults.length > 0 &&
        searchResults.map((item, index) => (
          <MapMarker
            lat={
              item?.location?.latitude
                ? item?.location?.latitude
                : mapCenter?.lat
            }
            lng={
              item?.location?.longitude
                ? item?.location?.longitude
                : mapCenter?.lng
            }
            text={item?.name}
            key={index}
            hoverIndex={hoverIndex}
            id={index}
          />
        ))}
    </GoogleMapReact>
  );
};

export default SearchMap;
