import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchHospitalsListItem from "./SearchHospitalsListItem";
import DoctorSearchItem from "./DoctorSearchItem";
import SearchMap from "./SearchMap";
import Filters from "./filters";
import { getSearchResults, getEsDoctors, getEsHospitals } from "../../services";
import { fetchDoctorProfileDetailES } from "../Appointments/bookAppointmentService";
import Loader from "../Loader";
import {
  encryptId,
  decryptId,
  logMixpanelEventBasic,
  setAddressLineFromDoctor,
  handleSetLocationFeeFromDoc,
} from "../../helpers/utils";
import "rc-dialog/assets/index.css";

import qs from "qs";
import InfiniteScroll from "react-infinite-scroll-component";
import { PageContext } from "../SearchContextProvider";
import Dialog from "rc-dialog";
import Appointments from "../Appointments";
import moment from "moment";
const Search = ({ queryParams }) => {
  // const router = useRouter();
  const history = useHistory();
  const location = useLocation();
  const perPage = 10;

  const [searchResults, setSearchResults] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);

  const { selectedLocation, searchOnEnter, searchText, selectedSearchType } =
    useContext(PageContext);

  const [hoverIndex, setHoverIndex] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState(null);
  const [previewListOnly, setPreviewListOnly] = useState(false);

  // for appointment booking
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [selectedDocAppt, setSelectedDocAppt] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedApptDayObj, setSelectedApptDayObj] = useState(null);
  const [doctorAvailableDays, setDoctorAvailableDays] = useState([]);
  const [selectedClinicDoctorId, setSelectedClinicDoctorId] = useState(null);
  const [selectedDoctorLocation, setSelectedDoctorLocation] = useState(null);
  const [daysGapforFetchSchedule, setDaysGapforFetchSchedule] = useState(1);
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(1);

  // on component load
  useEffect(() => {
    const queryValue = qs.parse(location.search.slice(1));
    // fetch for particular doctor
    if (queryValue && queryValue.did) {
      const doctorId = decryptId(queryValue.did);
      setPreviewListOnly(true);
      fetchSearchResultsFromApi(pageNo, null, doctorId); // fetch from API if doctor id is present else fetch doctor list from elastic search
    } else {
      fetchSearchResults(
        pageNo,
        selectedLocation,
        null,
        null,
        selectedSearchType
      );
    }
  }, [location]);

  // on location change
  useEffect(() => {
    logMixpanelEventBasic(
      "Clinic Location Selected",
      null,
      null,
      "Doctor Listing"
    );

    fetchSearchResults(
      1,
      selectedLocation,
      searchText,
      filters,
      selectedSearchType
    );
  }, [selectedLocation.name, selectedSearchType]);

  // on search text change
  useEffect(() => {
    if (searchOnEnter) {
      logMixpanelEventBasic(
        "Results Filtered",
        null,
        null,
        "Doctor Listing",
        "text"
      );
      fetchSearchResults(
        1,
        selectedLocation,
        searchText,
        filters,
        selectedSearchType
      );
    }
  }, [searchOnEnter]);

  // handle hover on table
  const handleItemHover = (index) => {
    setHoverIndex(index);
  };

  // on applying filters
  const handleApplyFilters = (filterParams) => {
    setFilters(filterParams);

    logMixpanelEventBasic(
      "Results Filtered",
      null,
      null,
      "Doctor Listing",
      filterParams.length > 0 ? filterParams.join(", ") : ""
    );
    // send location in case of nearest
    setHasMore(true);
    setPageNo(1);
    fetchSearchResults(
      1,
      selectedLocation,
      searchText,
      filterParams,
      selectedSearchType
    );
  };

  // fetch search results
  const fetchSearchResults = (
    page,
    city,
    searchText,
    filterParams,
    selectedSearchType
  ) => {
    setIsProcessing(true);

    if (previewListOnly) {
      setPreviewListOnly(false);
    }
    let searchResponse = null;
    if (selectedSearchType === "doctors") {
      searchResponse = getEsDoctors(
        page,
        perPage,
        city,
        searchText,
        filterParams
      );
    } else if (selectedSearchType === "hospitals") {
      searchResponse = getEsHospitals(page, perPage, city, searchText);
    }

    searchResponse
      .then((response) => {
        if (response && response.data.hits.total.value > 0) {
          if (page == 1) {
            setHasMore(true);

            setSearchResults(response.data.hits.hits);
          } else {
            setSearchResults([...searchResults, ...response.data.hits.hits]);
          }

          setTotalRecords(response.data.hits.total.value);
        } else {
          setSearchResults([]);
          setTotalRecords(0);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  // fetch search results
  const fetchSearchResultsFromApi = (
    page,
    city,
    doctorId,
    searchText,
    filterParams
  ) => {
    setIsProcessing(true);

    getSearchResults(page, perPage, city, doctorId, searchText, filterParams)
      // getEsDoctors(page, perPage, city, doctorId, searchText, filterParams)
      .then((response) => {
        if (response && response.data.success) {
          if (page == 1) {
            setHasMore(true);
            setSearchResults(response.data.data.doctors);
          } else {
            setSearchResults([...searchResults, ...response.data.data.doctors]);
          }

          setTotalRecords(response.data.data.total_records);
        } else {
          setSearchResults([]);
          setTotalRecords(0);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const fetchMoreResults = () => {
    const nextPage = pageNo + 10;
    if (nextPage > totalRecords) {
      setHasMore(false);
      return false;
    } else {
      setPageNo(nextPage);
      fetchSearchResults(
        nextPage,
        selectedLocation,
        searchText,
        filters,
        selectedSearchType
      );
    }
  };

  const handleGotoDoctorDetails = (doctor) => {
    logMixpanelEventBasic("Profile Previewed", null, null, "Doctor Listing");
    // history.push(`/doctors/${encryptId(doctor.doctor_id)}`);
    window.open(`/doctors/${encryptId(doctor.doctor_id)}`, "_blank");
  };

  const handleShowBookAppointment = (
    type,
    doctor,
    selectedScheduleIndex = 1,
    selectedDayObj = null,
    doctorAvailableDays = []
  ) => {
    setIsProcessing(true);
    setSelectedType(type);

    setSelectedDoctorId(doctor.doctor_id);

    if (doctor.clinic_doctor_id)
      setSelectedClinicDoctorId(doctor.clinic_doctor_id);
    if (
      (selectedScheduleIndex && selectedDayObj) ||
      selectedScheduleIndex === 0
    )
      setSelectedScheduleIndex(selectedScheduleIndex);
    if (selectedDayObj) {
      logMixpanelEventBasic(
        "Appointment Time Selected",
        null,
        "Care search",
        "Doctor Listing"
      );
      logMixpanelEventBasic(
        "Patient Visit Type Selected",
        null,
        "Care search",
        "Doctor Listing"
      );
      logMixpanelEventBasic(
        "Clinic Location Selected",
        null,
        "Care search",
        "Doctor Listing"
      );
      // select day chosen for appt
      setSelectedApptDayObj(selectedDayObj);

      let selectedApptDate = moment(selectedDayObj?.date);
      const todayDate = moment().format("YYYY-MM-DD");
      // calculate difference in days, this needs to be sent to api,
      // so that next 3 days schedules can be fetched, starting from this days gap difference
      setDaysGapforFetchSchedule(selectedApptDate.diff(todayDate, "days"));
    }
    if (doctor.location) {
      const doctorLocation = {};
      doctorLocation.label = setAddressLineFromDoctor(doctor);
      doctorLocation.value = doctor?.location?.id;
      doctorLocation.fees = handleSetLocationFeeFromDoc(doctor);
      doctorLocation.takes_teleconsultation = doctor.takes_teleconsultation;
      doctorLocation.clinic_doctor_id = doctor.clinic_doctor_id;
      doctorLocation.clinicId = doctor?.clinic_id;
      doctorLocation.use_kulcare_appts = doctor.use_kulcare_appts;
      doctorLocation.takes_in_clinic = doctor.takes_in_clinic;
      doctorLocation.takes_appointment = doctor.takes_appointment;
      doctorLocation.fixed_appt_fees = doctor.fixed_appt_fees;
      doctorLocation.schedules = doctor.schedules;
      doctorLocation.spl_schedules = doctor.spl_schedules;
      setSelectedDoctorLocation(doctorLocation);
    }

    if (doctorAvailableDays) setDoctorAvailableDays(doctorAvailableDays);

    // fetch profile
    fetchDoctorProfileDetailES(doctor.doctor_id)
      .then((response) => {
        if (response && response.data.hits.total.value > 0) {
          setSelectedDocAppt(response.data.hits.hits[0]._source);
          setShowModal(true);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <div className="section-container">
      <section className={`${"doc-search-main-section"}`}>
        {isProcessing && <Loader />}
        <div className={"doc-search-left-content"}>
          {/* search section start */}

          <Filters handleApplyFilters={handleApplyFilters} />
          {/* search section end */}
          {!isProcessing && totalRecords > 0 && !previewListOnly && (
            <div className={"result-found"}>
              We found {totalRecords} {selectedSearchType}
            </div>
          )}
          {!isProcessing && totalRecords === 0 && (
            <div className={"result-found"}>No results found!</div>
          )}
          {isProcessing && (
            <div className={"result-found"}>Finding doctors for you...</div>
          )}

          {/* <Collapse isOpened={showFilters}>
            <div className={"search-filter-content-wrap"}>
              
            </div>
          </Collapse> */}
          <div className={"divider"}></div>
          {/* search listing wrapper start  */}
          <div className={"doc-listing-wrap"} id="scrollableDiv">
            {selectedSearchType === "doctors" && (
              <InfiniteScroll
                dataLength={searchResults.length}
                next={fetchMoreResults}
                hasMore={previewListOnly ? false : hasMore}
                scrollableTarget="scrollableDiv"
              >
                {searchResults.length > 0 &&
                  searchResults.map((item, index) => {
                    return (
                      <DoctorSearchItem
                        doctor={item._source ? item._source : item}
                        handleItemHover={handleItemHover}
                        key={index}
                        itemIndex={index}
                        handleShowBookAppointment={handleShowBookAppointment}
                        selectedLocation={selectedLocation}
                        handleGotoDoctorDetails={handleGotoDoctorDetails}
                      />
                    );
                  })}
              </InfiniteScroll>
            )}
            {selectedSearchType === "hospitals" && (
              <InfiniteScroll
                dataLength={searchResults.length}
                next={fetchMoreResults}
                hasMore={previewListOnly ? false : hasMore}
                scrollableTarget="scrollableDiv"
              >
                {searchResults.length > 0 &&
                  searchResults.map((item, index) => {
                    return (
                      <SearchHospitalsListItem
                        hospital={item._source ? item._source : item}
                        handleItemHover={handleItemHover}
                        key={index}
                        itemIndex={index}
                        selectedLocation={selectedLocation}
                      />
                    );
                  })}
              </InfiniteScroll>
            )}
          </div>
          {/* search listing wrapper end  */}
        </div>
        <div className={"doc-search-right-content"}>
          <SearchMap
            hoverIndex={hoverIndex}
            searchResults={searchResults}
            selectedLocation={selectedLocation}
            previewListOnly={previewListOnly}
          />
        </div>
      </section>
      <Dialog
        title={null}
        onClose={() => setShowModal(false)}
        animation="zoom"
        maskAnimation="fade"
        visible={showModal}
        wrapClassName="flex-center"
        className="booking-modal"
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Appointments
          doctorDetails={selectedDocAppt}
          apptType={selectedType}
          isProcessing={isProcessing}
          selectedApptDayObj={selectedApptDayObj}
          doctorAvailableDays={doctorAvailableDays}
          selectedClinicDoctorId={selectedClinicDoctorId}
          selectedDoctorLocation={selectedDoctorLocation}
          daysGapforFetchSchedule={daysGapforFetchSchedule}
          selectedScheduleIndex={selectedScheduleIndex}
        />
      </Dialog>
    </div>
  );
};
export default Search;
