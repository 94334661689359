import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import StepCard from "./StepCard";
import {
  generateStepHeading,
  generateStepText,
  cardStatus,
  cardRightContent,
} from "../../../../helpers/appointments";

export default class TeleConsultationSteps extends Component {
  render() {
    const {
      appointmentDetail,
      handleJoinCall,
      handleAppDownload,
      handlePatientSignup,
    } = this.props;

    return (
      <div>
        {/* // teleconsultation steps start */}
        <div className="teleconsultation-steps-wrapper">
          {/* payment card start  */}
          {/* <StepCard
            step={1}
            heading={
              appointmentDetail && generateStepHeading(appointmentDetail, 0)
            }
            text={appointmentDetail && generateStepText(appointmentDetail, 0)}
            status={appointmentDetail && cardStatus(appointmentDetail, 0)}
            rightContent={
              appointmentDetail && cardRightContent(appointmentDetail, 0)
            }
          /> */}

          {/* <StepCard
            step={2}
            heading={
              appointmentDetail && generateStepHeading(appointmentDetail, 1)
            }
            text={appointmentDetail && generateStepText(appointmentDetail, 1)}
            status={appointmentDetail && cardStatus(appointmentDetail, 1)}
            rightContent={
              appointmentDetail && cardRightContent(appointmentDetail, 1)
            }
          /> */}
          {/* payment card end  */}
          {/* waiting room card start  */}
          <StepCard
            step={"video"}
            heading={
              appointmentDetail && generateStepHeading(appointmentDetail, 2)
            }
            text={appointmentDetail && generateStepText(appointmentDetail, 2)}
            status={appointmentDetail && cardStatus(appointmentDetail, 2)}
            rightContent={
              appointmentDetail &&
              cardRightContent(appointmentDetail, 2, handleJoinCall)
            }
          />
          {/* waiting room card end  */}
          {/* consultation card start  */}
          <StepCard
            step={"prescription"}
            heading={
              appointmentDetail && generateStepHeading(appointmentDetail, 3)
            }
            text={appointmentDetail && generateStepText(appointmentDetail, 3)}
            status={appointmentDetail && cardStatus(appointmentDetail, 3)}
            rightContent={
              appointmentDetail && cardRightContent(appointmentDetail, 3)
            }
          />

          <div className="whats_next_wrapper">
            {/* <h2>What's next</h2> */}
            <div className="whats_next_steps font-weight-bold">
              <div>To modify or manage your bookings</div>
            </div>
            <Row type="flex" justify="center" className="mt-20" align="middle">
              <Col className="text-center">
                <Button type="primary" onClick={handleAppDownload}>
                  Download app
                </Button>
                <div>or</div>
                <a
                  href=""
                  className="font-color-blue"
                  onClick={handlePatientSignup}
                >
                  Sign up
                </a>
              </Col>
            </Row>
          </div>
          {/* consultation card end  */}
        </div>
        {/* // teleconsultation steps ends */}
      </div>
    );
  }
}
