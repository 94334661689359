import React, { Component } from "react";

class InsauranceShowContainer extends Component {
  render() {
    return (
      <div className="hospital-insaurance-container">
        <div className="hospital-heading">
          <h2 className="mb-15">Insaurance Accepted</h2>
        </div>
        <div className="insauranceWrapper">
          <div className="insurance-card">
            <div className="insurance">
              <div className="img-left">
                <div className="ins-img"></div>
              </div>
              <div className="ins-content">
                <h5>Insurance company</h5>
                <p>Additions</p>
              </div>
              <div>
                <a className="see-more">See more</a>
              </div>
            </div>
          </div>
          <div className="insurance-card">
            <div className="insurance">
              <div className="img-left">
                <div className="ins-img"></div>
              </div>
              <div className="ins-content">
                <h5>Insurance company</h5>
                <p>Additions</p>
              </div>
              <div>
                <a className="see-more">See more</a>
              </div>
            </div>
          </div>
          <div className="insurance-card">
            <div className="insurance">
              <div className="img-left">
                <div className="ins-img"></div>
              </div>
              <div className="ins-content">
                <h5>Insurance company</h5>
                <p>Additions</p>
              </div>
              <div>
                <a className="see-more">See more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InsauranceShowContainer;
