import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { AiOutlineClockCircle } from "react-icons/ai";
import { MdLocationOn, MdPhone } from "react-icons/md";

const setAddressLine = (location) => {
  const loc = [];
  if (location?.name) loc.push(location?.name);
  if (location?.location?.address_line_1)
    loc.push(location?.location?.address_line_1);
  if (location?.location?.city) loc.push(location?.location?.city);
  if (location?.location?.state) loc.push(location?.location?.state);
  if (location?.location?.zipcode) loc.push(location?.location?.zipcode);
  return loc.join(", ");
};

const appendCountryCodeToPhoneNumber = (location) => {
  let phoneNumbers = location?.location?.loc_phone_numbers;

  phoneNumbers = phoneNumbers.split(",");
  let phoneNumberWithCode = [];
  if (phoneNumbers?.length > 0) {
    phoneNumberWithCode = phoneNumbers.map((phoneNo) => {
      return `+91-${phoneNo}`;
    });
  }
  return phoneNumberWithCode.join(", ");
};

const LocationShowComponent = ({ locations }) => {
  return (
    <div className="mt-10 hospital-about-container">
      <div className={`widget-title font-size-16 text-bold font-color-primary`}>
        <span>Clinic locations</span>
      </div>

      <Accordion
        allowZeroExpanded={true}
        preExpanded={[`${locations[0]?.location?.id}_${0}`]}
      >
        {locations.length > 0 &&
          locations.map((location, i) => (
            <AccordionItem
              key={`${location?.location?.id}_${i}`}
              uuid={`${location?.location?.id}_${i}`}
            >
              <AccordionItemHeading>
                <AccordionItemButton>{location?.name}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="location_wraper">
                  <div className="loc_left_section">
                    {setAddressLine(location) ? (
                      <div className="location-item tp-2 lft-2">
                        <MdLocationOn color="#353949" size="18px" />
                        {setAddressLine(location)}
                      </div>
                    ) : (
                      ""
                    )}

                    {location.schedule &&
                      location.schedule.length > 0 &&
                      location.schedule.map((schedule) => (
                        <div className="location-item tp-3">
                          <AiOutlineClockCircle color="#353949" size={"16px"} />

                          <div>
                            {schedule.days.join(", ")}:{" "}
                            {schedule.time.map((timeSlot) => {
                              return (
                                <div className="font-weight-bold">
                                  {timeSlot?.start_time} - {timeSlot?.end_time}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                  </div>

                  {location?.location?.display_phone_no && (
                    <div className="loc_right_section">
                      {location?.location?.loc_phone_numbers && (
                        <div className="location-item tp-3">
                          <MdPhone color="#353949" size={"16px"} />
                          {appendCountryCodeToPhoneNumber(location)}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </div>
  );
};

export default LocationShowComponent;
