import React from "react";

const SkeletonView = () => {
  return (
    <div className="appointment_main_wrapper">
      <div className="appt_wrapper">
        <h1 className="font-size-20 font-weight-bold">
          {/* Book appointment */}
          </h1>
        <div className="skl-card-body">
          <h2 className="skl-card-title skl-skeleton"></h2>
          <div className="skl-card-img skl-skeleton"></div>

          <div className="skl-card-img skl-skeleton"></div>

          <p className="skl-card-intro skl-skeleton"></p>
        </div>
      </div>
    </div>
  );
};

export default SkeletonView;
