import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
class SpecialitiesView extends Component {
  render() {
    return (
      <div className="speciality_wrapper mb-40">
        <div className="hospital-heading">
          <h2 className="mb-15">Specialities</h2>
        </div>
        <div className="specialities-accordion mt-10">
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Orthopedic surgeon</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="spec_head">Orthopedic surgeon</p>
                <div className="spec_card_wrapper">
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Surgeon</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="spec_head">Surgeon</p>
                <div className="spec_card_wrapper">
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Cardiologist</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="spec_head">Cardiologist</p>
                <div className="spec_card_wrapper">
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                  <div className="spec_card">
                    <div className="img-card">
                      <div className="img-card-inner"></div>
                    </div>
                    <div className="content">
                      <h5>Tom DuHamel</h5>
                      <p>Oncology</p>
                    </div>
                    <div className="right-btn">
                      <button>Book</button>
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default SpecialitiesView;
