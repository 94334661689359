import moment from "moment";
import _ from "lodash";

export const getBasicAuthHeaders = () => {
  let headers = {
    Authorization: `Basic ${btoa(
      process.env.REACT_APP_BASIC_AUTH_USERNAME +
        ":" +
        process.env.REACT_APP_BASIC_AUTH_PASSWORD
    )}`,
  };
  return headers;
};

/**
 * @function formatedShortDate
 * @param date
 * @desc this function will return the formated short date like- 08 Aug 2020
 */
export const formatedShortDate = (date) => moment(date).format("Do MMM YYYY");

// defining helper functions: start
// converts 12 hour format time to 24 hour format
export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM" || modifier === "pm") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}:00`;
};

export const loadScript = (src, id) => {
  const alreadyPresent = document.getElementById(id);
  if (alreadyPresent) {
    return false;
  }
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'null'.
 */
export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) return "Windows Phone";
  if (/android/i.test(userAgent)) return "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
  return null;
};

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    zIndex: "inherit",
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#d1d6f3",
    minHeight: "35px",
    height: "35px",
    boxShadow: state.isFocused ? null : null,
    borderRadius: "2px",
    fontSize: "14px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 6px",
    transform: 'translateY(-4px)',
    height: '100%'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "35px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? "#e6f7ff" : "white",
    color: "#000",
    fontSize: "14px",
    zIndex: 99,
  }),
};

/**
 * @function commaSepNum
 * @desc this function returns currency string in indian comma seperated system, like 10000 to 10,000
 */
export const commaSepNum = (num) => {
  if (!num) return;
  return num.toLocaleString();
};

export const perPage = 2;

export const timeSlotMinutes = 5;

/** *
 * @function convertUTCDateToLocalTime
 * @param utcDate
 * @desc This method converts utcDate to user time in its timezone
 */
export const convertUTCDateToLocalTime = (utcDate) => {
  const clinicTZ = "Asia/Calcutta";
  // const savedUserTZ = user ? user.timezone : null;
  // date: "2021-06-18T10:40:51.834Z"
  const utcDateString = utcDate || `${moment()}`;
  // const tzString = clinicTZ || savedUserTZ; // do the coversion according to current timezone of user,
  // if current time could not be detected, then convert according to saved user timezone
  const localTime = new Date(
    (typeof utcDateString === "string"
      ? new Date(utcDateString)
      : utcDateString
    ).toLocaleString("en-US", { timeZone: clinicTZ })
  ); // Fri Jun 18 2021 16:10:51 GMT+0530 (India Standard Time)

  return localTime;
};

const findLastIndex = (timeSlots, searchKey, searchValue) => {
  var index = timeSlots
    .slice()
    .reverse()
    .findIndex((x) => x[searchKey] === searchValue);
  var count = timeSlots.length - 1;
  var finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
};

export const timeLiesBetween = (timeSlots) => {
  const morningIndex = timeSlots.map((i) => i.slot).indexOf("morning");
  const lastMorningIndex = findLastIndex(timeSlots, "slot", "morning");
  const noonIndex = timeSlots.map((i) => i.slot).indexOf("afternoon");
  const lastNoonIndex = findLastIndex(timeSlots, "slot", "afternoon");
  const eveningIndex = timeSlots.map((i) => i.slot).indexOf("evening");
  const lastEveningIndex = findLastIndex(timeSlots, "slot", "evening");

  // eslint-disable-next-line array-callback-return
  timeSlots.map((timeObj, index) => {
    const day = moment().format("YYYY-MM-DD");

    if (timeObj?.slot === "morning") {
      const morningtempSt = `${day} ${timeSlots[morningIndex].time1}`;
      const morningtempEnd = `${day} ${timeSlots[lastMorningIndex].time1}`;

      timeObj.preffered_start_time = morningtempSt;
      timeObj.preffered_end_time = morningtempEnd;
    }
    if (timeObj?.slot === "afternoon") {
      const noonTempSt = `${day} ${timeSlots[noonIndex].time1}`;
      const noonTempEnd = `${day} ${timeSlots[lastNoonIndex].time1}`;

      timeObj.preffered_start_time = noonTempSt;
      timeObj.preffered_end_time = noonTempEnd;
    }
    if (timeObj?.slot === "evening") {
      const eveTempSt = `${day} ${timeSlots[eveningIndex].time1}`;
      const eveTempEnd = `${day} ${timeSlots[lastEveningIndex].time1}`;

      timeObj.preffered_start_time = eveTempSt;
      timeObj.preffered_end_time = eveTempEnd;
    }
  });
};

export const staticDaySlots = [
  {
    slot_name: "morning",
    start_time: "00:00:00",
    end_time: "12:00:00",
  },
  {
    slot_name: "afternoon",
    start_time: "12:00:00",
    end_time: "16:00:00",
  },
  {
    slot_name: "evening",
    start_time: "16:00:00",
    end_time: "24:00:00",
  },
];

export const setAddressLine = (location) => {
  const loc = [];
  if (location?.clinic?.name) loc.push(location?.clinic?.name);
  if (location?.location?.address_line_1)
    loc.push(location?.location?.address_line_1);
  if (location?.location?.city) loc.push(location?.location?.city);
  if (location?.location?.state) loc.push(location?.location?.state);
  if (location?.location?.zipcode) loc.push(location?.location?.zipcode);
  return loc.join(", ");
};

export const handleSetLocationFee = (locObj) => {
  return locObj.takes_in_clinic && locObj.fees
    ? locObj.fees
    : locObj.fixed_appt_fees;
};

export const timeOfDay = (timeSlot) => {
  let dateObj = new Date(timeSlot);
  
  let hrs = dateObj.getHours();
  var mins = dateObj.getMinutes();

  if (hrs >= 0 && ((hrs === 0 && mins >= 30) || (hrs > 0 && hrs < 12) || (hrs === 12 && mins === 0)))
  return "morning";
  else if (hrs >= 12 && hrs < 16)
    return "afternoon";
  else if ((hrs >= 16 && hrs < 24) || hrs > 0)
  return "evening";
  else
  return null;
};