import React, { Component } from "react";
import moment from "moment";
import AddToCalenderButton from "./AddToCalenderButton";
import {
  convertUTCDateToLocalTime,
  handleShowAddToCal,
} from "../../../../helpers/utils";

export default class AppointmentCard extends Component {
  render() {
    const { appointmentDetail, apptDate, doctorName, doctorDetail } =
      this.props;

    return (
      <div>
        {appointmentDetail ? (
          <div className="patient-appointment-card">
            <div className="appointment-card-left-content">
              <div>
                <span className="appt-datetime-bigger-fnt">
                  {appointmentDetail && appointmentDetail.appointment_time
                    ? moment(
                        convertUTCDateToLocalTime(
                          appointmentDetail.appointment_time
                        )
                      ).format("DD")
                    : appointmentDetail && appointmentDetail.created_at
                    ? moment(
                        convertUTCDateToLocalTime(appointmentDetail.created_at)
                      ).format("DD")
                    : ""}{" "}
                </span>{" "}
                <span className="appt-mnth-small-fn">
                  {appointmentDetail && appointmentDetail.appointment_time
                    ? moment(
                        convertUTCDateToLocalTime(
                          appointmentDetail.appointment_time
                        )
                      ).format("MMM")
                    : appointmentDetail && appointmentDetail.created_at
                    ? moment(
                        convertUTCDateToLocalTime(appointmentDetail.created_at)
                      ).format("MMM")
                    : ""}
                </span>
              </div>
              <div>
                <span className="appt-datetime-bigger-fnt">
                  {appointmentDetail && appointmentDetail.appointment_time
                    ? moment(
                        convertUTCDateToLocalTime(
                          appointmentDetail.appointment_time
                        )
                      ).format("hh:mm")
                    : ""}
                </span>{" "}
                <span className="appt-mnth-small-fn">
                  {appointmentDetail && appointmentDetail.appointment_time
                    ? moment(
                        convertUTCDateToLocalTime(
                          appointmentDetail.appointment_time
                        )
                      ).format("A")
                    : ""}
                </span>
              </div>
            </div>
            <div className="appointment-card-right-content">
              <div>
                <div className="title text-left">TELECONSULTATION WITH</div>
                <div className="doc-name mt-5">
                  Dr.{" "}
                  {appointmentDetail && appointmentDetail.doctor_details.name}
                </div>
                {/* fees */}
                <div className="font-size-12 mt-5">
                  <span className="font-weight-bold">
                    Consultation fee:&nbsp;
                  </span>
                  ₹ {appointmentDetail && appointmentDetail.doctor_details.telemedicine_fees}
                </div>
                {/* appointment missed  */}
                {appointmentDetail &&
                  (appointmentDetail.appointment_status === "REMOVED" ||
                    appointmentDetail.appointment_status === "CHECKED_OUT") &&
                  !appointmentDetail.session_details.patient_joined && (
                    <div className="mt-5 violated-red-text">
                      <div className="appointment-missed">
                        Appointment missed!
                      </div>
                    </div>
                  )}
                {/* appointment completed  */}
                {appointmentDetail &&
                  appointmentDetail.session_details.session_status ===
                    "COMPLETE" &&
                  appointmentDetail.session_details.patient_joined &&
                  appointmentDetail.session_details.doctor_joined && (
                    <div className="appointment-complete">
                      Appointment complete!
                    </div>
                  )}
                {/* show add to calender before waiting room is activated */}
                {/* {appointmentDetail &&
              appointmentDetail.appointment_status !== "REMOVED" &&
              appointmentDetail.appointment_status !== "CHECKED_OUT" &&
              appointmentDetail.session_details.session_status !== "COMPLETE" &&
              handleShowAddToCal(appointmentDetail) && (
                <AddToCalenderButton event={calenderEvent} listItems={items} />
              )} */}
              </div>
            </div>
          </div>
        ) : (
          <div className="patient-appointment-card">
            <div className="appointment-card-left-content">
              <div>
                <span className="appt-datetime-bigger-fnt">
                  {moment(apptDate).format("DD")}
                </span>{" "}
                <span className="appt-mnth-small-fn">
                  {moment(apptDate).format("MMM")}
                </span>
              </div>
            </div>
            <div className="appointment-card-right-content">
              <div>
                <div className="title text-left">TELECONSULTATION WITH</div>
                <div className="doc-name mt-5">Dr. {doctorDetail?.name}</div>
                <div className="font-size-12 mt-5">
                  <span className="font-weight-bold">
                    Consultation fee:&nbsp;
                  </span>
                  ₹ {doctorDetail?.telemedicine_fees}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
