import "./styles/appointments.css";
import "./styles/button.css";

const AppointmentTypeTabs = ({
  doctorDetails,
  selectedApptType,
  apptTime,
  stepNo,
  handleSelectApptType,
}) => {
  return (
    <div className="appointment-type-tabs mb-15">
      {doctorDetails &&
        (doctorDetails.takes_in_clinic || doctorDetails.takes_appointment) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            data-value="ONCALL"
            className={`${selectedApptType === "ONCALL" ? "active" : ""} ${
              apptTime && selectedApptType === "TELEMEDICINE" && stepNo !== 1
                ? "hide"
                : ""
            } ${apptTime && stepNo !== 1 ? "disabled" : ""}`}
            onClick={(e) => handleSelectApptType(e, "ONCALL")}
          >
            In-person
          </a>
        )}
      {doctorDetails && doctorDetails.takes_teleconsultation && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          data-value="TELEMEDICINE"
          className={`${selectedApptType === "TELEMEDICINE" ? "active" : ""} ${
            apptTime && selectedApptType === "ONCALL" && stepNo !== 1
              ? "hide"
              : ""
          } ${apptTime && stepNo !== 1 ? "disabled" : ""}`}
          onClick={(e) => handleSelectApptType(e, "TELEMEDICINE")}
        >
          Teleconsultation
        </a>
      )}
    </div>
  );
};

export default AppointmentTypeTabs;

