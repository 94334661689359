import React, { Component } from "react";

import AppointmentCard from "./AppointmentCard";
import TeleConsultationSteps from "./TeleConsultationSteps";

export default class VideoAppointmentDetails extends Component {
  render() {
    const {
      appointmentDetail,
      handleJoinCall,
      handleAppDownload,
      handlePatientSignup
    } = this.props;
    const calenderEvent = {
      title: appointmentDetail
        ? `kulcare appoinment with Dr. ${appointmentDetail.doctor_details.name}`
        : "",
      location: "",
      startTime: appointmentDetail ? appointmentDetail.appointment_time : "",
      endTime: appointmentDetail ? appointmentDetail.appointment_time : ""
    };

    return (
      <>
        {/* <AppointmentCard
          calenderEvent={calenderEvent}
          appointmentDetail={appointmentDetail}
        /> */}
        <div className="video-appt-outer-container">
        <TeleConsultationSteps
          appointmentDetail={appointmentDetail}
          handleJoinCall={handleJoinCall}
          handleAppDownload={handleAppDownload}
          handlePatientSignup={handlePatientSignup}
        />
      </div>
      </>
    );
  }
}
