import React from "react";

const AboutShowComponent = ({ profileDetail, parentContainer }) => {
  return (
    <div className="mt-10 hospital-about-container mb-10">
      <div className="hospital-heading">
        <h2>About</h2>
        <p className="mb-0 font-weight-semibold">
          {parentContainer === "clinic"
            ? profileDetail?.bio
            : "Something about the hospital"}
        </p>
      </div>
    </div>
  );
};

export default AboutShowComponent;
