import React from "react";
import { Button } from "antd";
import AppointmentModal from "./AppointmentModal";
import { generateDevicePermissionImage } from "../../../helpers/utils";

const DeniedPermissionInfoModal = ({
  showDeniedPerModal,
  toggleDeniedPerModal,
  handleRefreshPage
}) => {
  return (
    <AppointmentModal
      showModal={showDeniedPerModal}
      toggleModal={toggleDeniedPerModal}
      verticallyCentered={true}
    >
      <div className="allow-permission-content">
        <div className="heading font-16">You did not allow permissions</div>
        <div className="text font-14">
          Doctor will not be able to hear or see you.{" "}
          <a href={generateDevicePermissionImage()} target="_blank" className="underlined_text">
            Click here to see how to allow access.
          </a>
        </div>

        <div className="mt-50 text-center">
          <Button type="primary" onClick={handleRefreshPage}>
            Refresh after allowing access
          </Button>
        </div>
      </div>
    </AppointmentModal>
  );
};

export default DeniedPermissionInfoModal;
