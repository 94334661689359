import React, { Component } from "react";
import Select from "react-select";
import { Button, Row, Col, Icon } from "antd";
import {
  fetchClinicPatients,
  createAppointment,
  createTeleconsultationOrder,
} from "../../../Appointments/bookAppointmentService";
import { customStyles, loadScript } from "../../../Appointments/helpers";
import { logMixpanelEventBasic, encryptId } from "../../../../helpers/utils";
import { showErrorNotification } from "../../../../helpers/showNotifications";
import { MdKeyboardArrowRight } from "react-icons/md";

class PatientDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientName: null,
      patientGender: {
        value: "Male",
        label: "Male",
      },
      patientAge: null,
      clinicPatientList: [],
    };
  }

  componentDidMount() {
    loadScript("https://checkout.razorpay.com/v1/checkout.js", "razorpay");
    this.handleFetchingClinicPatients();
  }

  handleFetchingClinicPatients = () => {
    const { clinicId, mobileNumber } = this.props;

    this.setState({ isProcessing: true });
    fetchClinicPatients(clinicId, mobileNumber)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          this.setState({ clinicPatientList: response.data.data });
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  handleSelectClinicPatient = (e, patientObj) => {
    if (e) e.preventDefault();
    this.setState({
      clinicPatientId: patientObj.id,
      patientName: patientObj.attributes.name,
      patientGender: {
        label: patientObj.attributes.gender,
        value: patientObj.attributes.gender,
      },
      patientAge: patientObj.attributes.age,
    });
  };

  handleInputChange = (e, fieldName) => {
    this.setState({ [fieldName]: e?.target?.value });
  };

  handleGenderChange = (obj) => {
    this.setState({ patientGender: obj });
  };

  handleConfirmAppointment = () => {
    const { patientName, patientGender, patientAge, clinicPatientId } =
      this.state;
    const { clinicDoctorId, mobileNumber, verifyId } = this.props;

    if (
      !patientName ||
      (patientName && patientName.trim() === "") ||
      !patientGender
    ) {
      showErrorNotification("Please enter patient information");
      return false;
    }

    const params = {
      clinic_doctor_id: clinicDoctorId,
      name: patientName,
      country_code: 91,
      phone_number: mobileNumber,
      gender: patientGender.value,
      age: patientAge,
      clinic_patient_id: clinicPatientId || null,
    };

    this.setState({ isProcessing: true });
    createAppointment(verifyId, params)
      .then((response) => {
        // setIsProcessing(false);
        if (response?.data?.success) {
          logMixpanelEventBasic(
            "Patient Visit Added",
            null,
            "Teleconsult Marketplace"
          );
          localStorage.setItem("authToken", response?.data?.data?.t);
          this.handleCreatingTelemedConsultation(response.data.data);

          // this.props.handleUpdateState(updatedParams);
          this.props.handleUpdateStepNo(3);
        } else {
          showErrorNotification(response.data.errors[0].title);
        }
      })
      .finally(() => {
        // setIsProcessing(false);
        this.setState({ isProcessing: false });
      });
  };

  handleCreatingTelemedConsultation = (apptObj) => {
    const { clinicDoctorId, verifyId, mobileNumber } = this.props;
    const { patientName } = this.state;

    var orderParams = {
      clinic_doctor_id: clinicDoctorId,
      patient_id: apptObj.pid,
      appt_verification_id: verifyId,
      payment_option: "BEFORE",
      slot_start_time: null,
    };

    createTeleconsultationOrder(orderParams).then((response) => {
      if (response?.data?.success) {
        // setting this in main state if user closes payment the modal
        // user can reopen the modal
        this.props.handleUpdateState({
          pendingOrderDetail: {
            order_id: response.data.data.razorpay_order_id,
            patientName: patientName,
            mobileNumber: mobileNumber,
            clinic_doctor_id: clinicDoctorId,
            patient_id: apptObj.pid,
            appt_verification_id: verifyId,
            payment_option: "BEFORE",
            slot_start_time: null,
          },
        });

        var options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          name: "kulcare",
          description: "Telemedicine Payment",
          order_id: response.data.data.razorpay_order_id,
          image:
            "https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/kulcare_logo_black.png",
          handler: (response) => {
            const updatedParams = {
              confirmed: true,
              paymentComplete: true,
              pendingOrderDetail: null,
            };

            this.props.handleUpdateState(updatedParams);
            this.props.handleUpdateStepNo(4);

            document
              .querySelectorAll(".razorpay-container")
              .forEach((el) => el.remove());
          },
          prefill: {
            name: patientName,
            email: "",
            contact: `91${mobileNumber}`,
          },
          notes: {
            address: "",
          },
          theme: {
            color: "#078DFF",
          },
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        showErrorNotification(response.data.errors[0].title);
      }
    });
  };

  render() {
    const {
      clinicPatientList,
      patientName,
      patientGender,
      patientAge,
      isProcessing,
    } = this.state;
    const { mobileNumber, countryCode } = this.props;
    return (
      <div>
        <div className="patient-detail-wrapper">
          {clinicPatientList.length > 0 && (
            <>
              <p className="info_col_heading">
                We found some results with same mobile number, please choose if
                you are one of following:
              </p>

              <ul className="patient_list_ul mt-10">
                {clinicPatientList.map((obj) => {
                  return (
                    <li onClick={(e) => this.handleSelectClinicPatient(e, obj)}>
                      <div className="patient-list-item with-select-link">
                        <div className="patient-name">
                          {obj.attributes.name}
                          <span className="patient-other-info">{`${
                            obj.attributes.gender
                              ? `, ${obj.attributes.gender}`
                              : ""
                          }${
                            obj.attributes.age ? `, ${obj.attributes.age}` : ""
                          }`}</span>
                        </div>
                        <a
                          onClick={(e) =>
                            this.handleSelectClinicPatient(e, obj)
                          }
                          className="font-size-12 font-weight-semibold"
                        >
                          select
                          <MdKeyboardArrowRight size={14} className="try-3" />
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>

              <p className="div_or_text">OR</p>
            </>
          )}

          <div className="form_fields_wrapper">
            {/* mobile number */}
            <div className="form_field">
              <label className="mb-0">
                Your mobile number: {countryCode}-{mobileNumber}
              </label>
            </div>
            {/* patient name */}
            <div className="form_field">
              <label>Your name</label>
              <input
                type="text"
                onChange={(e) => this.handleInputChange(e, "patientName")}
                value={patientName}
              />
            </div>

            <div className="row_with_cols space_between">
              {/* gender */}
              <div className="form_field half_width">
                <label>Gender</label>
                <Select
                  value={patientGender}
                  onChange={this.handleGenderChange}
                  styles={customStyles}
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Other", value: "Other" },
                  ]}
                />
              </div>
              {/* age */}
              <div className="form_field half_width">
                <label>Age</label>
                <input
                  type="text"
                  onChange={(e) => this.handleInputChange(e, "patientAge")}
                  value={patientAge}
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  maxLength={3}
                />
              </div>
            </div>
          </div>

          <Row type="flex">
            <Col span={10}>
              <Button
                type="primary"
                // className={`full_width ${isProcessing ? 'disabled' : ''}`}
                onClick={this.handleConfirmAppointment}
                loading={isProcessing}
              >
                Confirm Appointment
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PatientDetailForm;
