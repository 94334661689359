const ListSkeletonView = () => {
  return (
    <div className="skl_wrapper">
      <div className="skl-card-body">
        <div className="d-flex list-type-skeleton-wrap">
          <div className="skl-card-title skl-skeleton list-title"></div>
          <div className="skl-card-title skl-skeleton list-title"></div>
          <div className="skl-card-title skl-skeleton list-title"></div>
          <div className="skl-card-title skl-skeleton list-title"></div>
          <div className="skl-card-title skl-skeleton list-title"></div>
        </div>
        <div className="d-flex list-type-skeleton-wrap">
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
        </div>
        <div className="d-flex list-type-skeleton-wrap">
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
        </div>
        <div className="d-flex list-type-skeleton-wrap">
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
          <div className="skl-card-title skl-skeleton list-item"></div>
        </div>
      </div>
    </div>
  );
};

export default ListSkeletonView;
