import React, { useState } from "react";
import Dialog from "rc-dialog";
import FilterContainer from "./FilterContainer";

const FilterModalContainer = ({
  selectedSpeciality,
  specialities,
  selectedLanguage,
  languages,
  selectedSortingOption,
  handleResetFilters,
  handleSpecialityChange,
  fuzzySearch,
  selectedAppointmentType,
  handleApptChange,
  handleLanguageChange,
  showFilterModal,
  setShowFilterModal,
  handleCloseFilterModal,
  isTelemed,
  handleCityChange,
  locations,
  selectedLocation,
  handleNameSearch,
  setSearchText,
  searchText,
  fetchCities,
}) => {
  return (
    <Dialog
      title="Filter"
      className="filter-bar-modal"
      visible={showFilterModal}
      onClose={handleCloseFilterModal}
    >
      <FilterContainer
        filterModal={"filter-modal"}
        languages={languages}
        specialities={specialities}
        selectedSpeciality={selectedSpeciality}
        selectedAppointmentType={selectedAppointmentType}
        selectedLanguage={selectedLanguage}
        fuzzySearch={fuzzySearch}
        handleSpecialityChange={handleSpecialityChange}
        handleApptChange={handleApptChange}
        handleLanguageChange={handleLanguageChange}
        handleResetFilters={handleResetFilters}
        showFilterModal={showFilterModal}
        setShowFilterModal={setShowFilterModal}
        isTelemed={isTelemed}
        locations={locations}
        handleCityChange={handleCityChange}
        handleNameSearch={handleNameSearch}
        setSearchText={setSearchText}
        searchText={searchText}
        fetchCities={fetchCities}
      />
      <div className="btn-wrapper">
        <button className="btn-apply" onClick={() => setShowFilterModal(false)}>
          Close
        </button>
        {(selectedAppointmentType ||
          selectedSpeciality ||
          selectedLanguage ||
          selectedSortingOption ||
          searchText ||
          selectedLocation) && (
          <div className="mt-5">
            <a
              href=""
              onClick={(e) => {
                if (e) e.preventDefault();
                handleResetFilters();
              }}
              className="font-color-blue ml-10"
            >
              Clear
            </a>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default FilterModalContainer;
