import React from "react";

const InplaceEditWidget = ({ ShowComponent, visible, widgetTitle }) => {
  return (
    <div className={`inplace-edit-widget-wrap`}>
      {widgetTitle && <div className={`widget-title font-size-16 text-bold font-color-primary`}>
        <span>{widgetTitle}</span>
      </div>}

      {visible && visible === "show" && ShowComponent}
    </div>
  );
};
export default InplaceEditWidget;
