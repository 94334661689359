const TeleconsultationSearchSkeleton = ({ index, screenSize }) => {
  return (
    <>
      {screenSize === "big" && (
        <li className="tele-card tele_doc_item" key={index}>
          <div className="col-80">
            <div className="d-flex flex-valign-centered">
              <div className="col-40">
                <div className="header">
                  <div className="d-flex flex-valign-centered">
                    <div className="img"></div>
                    <div className="details ml-9">
                      <span className="name"></span>
                      <span className="about"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-25 for-desktop-screen">
                <div className="d-flex flex-valign-centered">
                  <div className="details ml-9">
                    <span className="name"></span>
                  </div>
                </div>
              </div>
              <div className="col-15">
                <div className="d-flex flex-valign-centered">
                  <div className="details ml-9">
                    <span className="name"></span>
                  </div>
                </div>
              </div>
              <div className="col-20">
                <div className="d-flex flex-valign-centered">
                  <div className="details ml-9">
                    <span className="name"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-15">
            <div className="tele-skl-btn"></div>
          </div>
        </li>
      )}

      {screenSize === "xs" && (
        <li className="tele-card tele_doc_item" key={index}>
          <div className="col-80">
            <div className="d-flex flex-valign-centered">
              <div className="col-40">
                <div className="header">
                  <div className="d-flex flex-valign-centered">
                    <div className="img"></div>
                    <div className="details ml-9">
                      <span className="name"></span>
                      <span className="about"></span>
                      <span className="about mt-5"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-15 buttons-section">
            <div className="tele-skl-btn"></div>
          </div>
        </li>
      )}
    </>
  );
};

export default TeleconsultationSearchSkeleton;
