import React from "react";
import ReactDOM from "react-dom";
import { setPusherClient } from "react-pusher";
import Pusher from "pusher-js";
import { Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import "antd/dist/antd.css";
import "./styles/variables.css";
import "./styles/button.scss";
import "./styles/radio.scss";
import "./styles/default.scss";
import "./styles/globals.css";
import "./styles/search.scss";
import "./styles/Home.css";
import "./styles/doctors.css";
import "./styles/tags.css";
import "./styles/teleconsultation.css";
import { MixpanelProvider } from "react-mixpanel";
import mixpanel from "mixpanel-browser";
import { history } from "./helpers/history";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

// setup pusher
const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});
setPusherClient(pusherClient);

ReactDOM.render(
  <MixpanelProvider mixpanel={mixpanel}>
    <Router history={history}>
      <App />
    </Router>
  </MixpanelProvider>,
  document.getElementById("root")
);
