import React, { useState } from "react";

const VideoCallPendingPaymentModal = ({
  pendingOrderDetail,
  paymentComplete,
  handleUpdateState,
  handleUpdateStepNo,
}) => {
  const handlePayment = (e) => {
    if (e) e.preventDefault();
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      name: "kulcare",
      description: "Telemedicine Payment",
      order_id: pendingOrderDetail.order_id,
      image:
        "https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/kulcare_logo_black.png",
      handler: (response) => {
        const updatedParams = {
          confirmed: true,
          paymentComplete: true,
          pendingOrderDetail: null,
        };

        handleUpdateState(updatedParams);
        handleUpdateStepNo(4);

        document
          .querySelectorAll(".razorpay-container")
          .forEach((el) => el.remove());
      },
      prefill: {
        name: pendingOrderDetail.patientName,
        email: "",
        contact: `91${pendingOrderDetail.mobileNumber}`,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#078DFF",
      },
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <>
      {paymentComplete ? (
        "Payment done"
      ) : pendingOrderDetail ? (
        <a href="#" onClick={handlePayment}>
          Click here to complete payment
        </a>
      ) : (
        <>
          Redirecting to payment gateway
          <div className="stage">
            <div className="dot-flashing"></div>
          </div>
        </>
      )}
    </>
  );
};
export default VideoCallPendingPaymentModal;
