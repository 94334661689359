import Button from "../Button";

import { MdCall, MdOutlinePersonOutline } from "react-icons/md";
// import { IoMdPerson } from "react-icons/io";
// import { FaVideo, FaHospital } from "react-icons/fa";
// import { HiUser } from "react-icons/hi";
// import { BsFillCalendarEventFill } from "react-icons/bs";
// import kulcareIcon from "../../images/kulcare-icon.svg";
// import Beds from "../../images/beds-icon.svg";
// import Hospital from "../../images/hospital-icon.svg";
import haversine from "haversine-distance";

const SearchHospitalsListItem = ({
  hospital,
  handleItemHover,
  itemIndex,
  handleGotoBookAppointment,
  selectedLocation,
}) => {
  let hosLocation = [];
  if (hospital.location.address_line_1) {
    hosLocation.push(hospital.location.address_line_1);
  }
  if (hospital.location.address_line_2) {
    hosLocation.push(hospital.location.address_line_2);
  }
  return (
    <div
      onMouseOver={() => handleItemHover(itemIndex)}
      className={`${"doc-search-list-wrapper"} ${"flex"}`}
    >
      <div
        className="hospital-image"
        style={{
          backgroundImage: `${
            hospital.photo && hospital.photo.trim() !== ""
              ? `url(${hospital.photo})`
              : ""
          }`,
        }}
      ></div>
      <div className="search-info">
        <div className="name">{hospital.group_name}</div>
        {hosLocation.length > 0 && (
          <div className="location">
            <span className="location-text">
              <span className="location-icon"></span>
              {hosLocation.join(", ")}{" "}
            </span>

            {hospital.location &&
              hospital.location.latitude &&
              selectedLocation &&
              selectedLocation.latitude && (
                <span className="search-distance">
                  {`${(
                    haversine(selectedLocation, hospital.location) / 1000
                  ).toFixed(1)} km`}
                </span>
              )}
          </div>
        )}

        <div className="timings">
          <span className="timing-text">
            <span className="clock-icon"></span>{" "}
            <strong className="black-color">Mon-Fri:</strong>
          </span>
          &nbsp;
          <span>9am - 5pm</span> &nbsp; &nbsp;
          <span className="timing-text">
            <strong className="black-color">Sat:</strong>
          </span>
          &nbsp;
          <span>9am - 2pm</span>
        </div>
        <div className="hospital-booking-link">
          <span className="booking-link-text">
            <span className="link-icon"></span>www.practo.com/drtomd
          </span>
        </div>
        <div className="hospital-features">
          {hospital.specialities && hospital.specialities.length > 1 && (
            <span className="feature-icon speciality">Multispeciality</span>
          )}

          {hospital.beds_count > 0 && (
            <span className="feature-icon beds-count">
              {hospital.beds_count}
            </span>
          )}
          {hospital.open_all_day === true && (
            <span className="feature-icon availability">24/7</span>
          )}
          {hospital.accreditations &&
              hospital.accreditations.length > 0 && (
          <span className="feature-icon accreditions">
            {hospital.accreditations &&
              hospital.accreditations.length > 0 &&
              hospital.accreditations
                .map((elem) => {
                  return (elem.name)
              })
              .join("/")
            }
          </span>
          )}
          {hospital.specialities && hospital.specialities.length > 0 && (
            <span className="feature-icon clinics-count">
              {hospital.specialities.length}
            </span>
          )}
          {/* <span className="feature-icon accreditions">NABH/NABL/JCI</span> */}
          {/* <span className="feature-icon clinics-count">11</span> */}
          {hospital.facilities && hospital.facilities.length > 0 && (
            <span className="feature-icon patients-count">
              {hospital.facilities.length}
            </span>
          )}
        </div>
      </div>
      <div className="search-actions">
        <div className="reviews">
          <span className="review-icon"></span>
          No reviews
        </div>
        <div className="search-buttons">
          <Button type={"primary"} size="small" className="btn-call" block>
            <MdCall /> Call
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SearchHospitalsListItem;
