import { MdLocationPin } from "react-icons/md";
import ReactTooltip from "react-tooltip";

const MapMarker = ({ hoverIndex, id, text }) => {
  return (
    <>
      <div
        data-tip={`Dr. ${text}`}
        data-effect={"solid"}
        data-offset="{'top': -10, 'left': -20}"
        className={`${"map-marker"} ${
          hoverIndex === id ? "highlighted-marker" : ""
        }`}
      >
        <MdLocationPin />
      </div>
      <ReactTooltip />
    </>
  );
};

export default MapMarker;
