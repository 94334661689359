const Button = ({ children, type, size, onClick, className }) => {
  return (
    <button
      className={`${"btn"} ${type} ${size} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default Button;
