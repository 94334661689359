import React, { Component } from "react";

class ServicesShowContainer extends Component {
  render() {
    return (
      <div className="hospital-service-container">
          <div className="hospital-heading">
              <h2 className="mb-15">Services</h2>
          </div>
        <div className="services-wrapper">
          <div className="services">Of ambulances</div>
          <div className="services">X-ray / radiology</div>
          <div className="services">Of beds</div>
          <div className="services">Short-term hospitalization</div>
          <div className="services">Blood services</div>
          <div className="services">Emergency room services</div>
          <div className="services">Laboratory services</div>
          <div className="services">
            General and specialty surgical services
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesShowContainer;
