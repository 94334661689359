import React from "react";
import moment from "moment";
import { groupByKey } from "../../helpers/utils";

const DaySlotsContainer = ({
  doctorAvailabilityObj,
  handleSelectingPrefferedTime,
  doctorWalkinTimeSlots,
  selectedDaySlot,
  selectedWalkinDate,
}) => {
  const filteredSchedule = doctorWalkinTimeSlots.filter((obj) => {
    if (obj.date === doctorAvailabilityObj.date) {
      return obj;
    }
  });
  
  let final = groupByKey(filteredSchedule[0]?.timeslots, "slot_name");
  final.date = filteredSchedule[0].date;
  let morningDiscountPercent = null;
  let noonDiscountPercent = null;
  let eveningDiscountPercent = null;
  if (final) {
    if (final?.morning)
      morningDiscountPercent = final?.morning.find(
        (o) => o.schedule_type === "discount"
      );
    if (final?.evening)
      eveningDiscountPercent = final?.evening.find(
        (o) => o.schedule_type === "discount"
      );
    if (final?.afternoon)
      noonDiscountPercent = final?.afternoon.find(
        (o) => o.schedule_type === "discount"
      );
  }
  return (
    <div>
      <div className="app-slot-heading mb-15">
        <div className="day mb-5">{moment(final.date).format("ddd")}</div>
        <div className="text">{moment(final.date).format("MMM DD")}</div>
      </div>
      {/* morning */}
      <div
        className={`appt-slot ${final && !final.morning ? "greyed-out" : ""} ${
          selectedDaySlot === "morning" && selectedWalkinDate === final.date
            ? "selected"
            : ""
        }`}
        onClick={() =>
          handleSelectingPrefferedTime(
            (final && !final.morning) || !final ? null : final,
            "morning"
          )
        }
      >
        {/* {final?.morning ? "Morning" : "Unavailable"} */}
        Morning
        
        {morningDiscountPercent && (
          <div className="discounted-slot">
            {/* <span className="time">
              {moment(
                        discountedSlots[key][0].start_time,
                        "hh:mm a"
                      ).format("hh:mm")}{" "}
                      {" - "}{" "}
                      {moment(
                        discountedSlots[key][discountedSlots[key].length - 1]
                          .end_time,
                        "hh:mm a"
                      ).format("hh:mm")}
            </span> */}
            <span className="off">
              {morningDiscountPercent.percentage}% off
            </span>
          </div>
        )}
      </div>
      {/* afternoon */}
      <div
        className={`appt-slot ${
          final && !final.afternoon ? "greyed-out" : ""
        } ${
          selectedDaySlot === "afternoon" && selectedWalkinDate === final.date
            ? "selected"
            : ""
        }`}
        onClick={() =>
          handleSelectingPrefferedTime(
            (final && !final.afternoon) || !final ? null : final,
            "afternoon"
          )
        }
      >
        {/* {final?.afternoon ? "Afternoon" : "Unavailable"}
         */}
         Afternoon
        {noonDiscountPercent && (
          <div className="discounted-slot">
            <span className="off">{noonDiscountPercent.percentage}% off</span>
          </div>
        )}
      </div>
      {/* evening */}
      <div
        className={`appt-slot ${final && !final.evening ? "greyed-out" : ""} ${
          selectedDaySlot === "evening" && selectedWalkinDate === final.date
            ? "selected"
            : ""
        }`}
        onClick={() =>
          handleSelectingPrefferedTime(
            (final && !final.evening) || !final ? null : final,
            "evening"
          )
        }
      >
        {/* {final?.evening ? "Evening" : "Unavailable"} */}
        Evening
        {eveningDiscountPercent && (
          <div className="discounted-slot">
            <span className="off">
              {eveningDiscountPercent.percentage}% off
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaySlotsContainer;
