import { useState, useEffect } from "react";
import Filters from "./filters";
import {
  getEsCities,
  getEsTeleDoctors,
  fetchDoctorAvailability,
} from "../../services";
import DoctorListItem from "./DoctorListItem";
import {
  logMixpanelEventBasic,
  generateRange,
  encryptId,
} from "../../helpers/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import TeleconsultationSearchSkeleton from "./TeleconsultationSearchSkeleton";

import { strings } from "../../helpers/locales";
import BookTeleconsultationModal from "./BookTeleconsultModal";
import { Modal, Spin } from "antd";
import VideoCallAppointment from "./VideoCall/VideoCallAppointment";
import axios from "axios";

const TeleconsultationSearch = () => {
  const [hasMore, setHasMore] = useState(true);
  const [filters, setFilters] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [clinicDoctorId, setClinicDoctorId] = useState(null);
  const [doctorDetail, setDoctorDetail] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showDocUnavialableInfo, setShowDocUnavialableInfo] = useState(false);
  const [fetchAvailabilityProcessing, setFetchAvailabilityProcessing] =
    useState(false);
  const perPage = 50;
  const [totalRecordsToPaginate, setTotalRecordsToPaginate] = useState(0);
  const [screenSize, setScreenSize] = useState("big");
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [availabilityDetail, setAvailabilityDetail] = useState(null);
  const [showAvailabilityStatusModal, setShowAvailabilityStatusModal] =
    useState(null);
  const [showTelemedicineSteps, setShowTelemedicineSteps] = useState(false);

  // on component load
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    // fetch for particular doctor
    fetchSearchResults(pageNo, selectedLocation, searchText, null);
    // fetchSearchResultsForCount(pageNo, selectedLocation, searchText, null);
    fetchCities();

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenSize, pageNo]);

  /**
   * @function handleResize
   * @desc This funtion sets the screen type
   * in local state
   */
  const handleResize = () => {
    const windowSize = window.innerWidth;
    let screenSize = "big";
    if (windowSize > 800) screenSize = "big";
    else if (windowSize <= 800 && windowSize > 768) screenSize = "small";
    else if (windowSize <= 768) screenSize = "xs";
    setScreenSize(screenSize);
  };

  const fetchCities = async (searchText) => {
    const response = await getEsCities(1, 500, searchText);
    if (response && response.data.hits.total.value > 0) {
      const cities = response.data.hits.hits.map(({ _source }) => {
        const cityObj = {
          name: _source.name,
          value: _source.name,
          lat: _source.latitude,
          lng: _source.longitude,
        };
        return cityObj;
      });
      setLocations(cities);
    } else {
      setLocations([]);
    }
  };

  // fetch search results
  // const fetchSearchResultsForCount = (page, city, searchText, filterParams) => {
  //   // setIsProcessing(true);
  //   let searchResponse = getEsTeleDoctors(
  //     page,
  //     perPage,
  //     city,
  //     searchText,
  //     filterParams,
  //     true
  //   );

  //   searchResponse
  //     .then((response) => {
  //       if (response && response.data.hits.total.value > 0) {
  //         setTotalRecords(response.data.hits.total.value);
  //       } else {
  //         setTotalRecords(0);
  //       }
  //     })
  //     .finally(() => {
  //       // setIsProcessing(false);
  //     });
  // };

  // fetch search results
  const fetchSearchResults = (page, city, searchText, filterParams) => {
    setIsProcessing(true);
    let searchResponse = getEsTeleDoctors(
      page,
      perPage,
      city,
      searchText,
      filterParams
    );

    searchResponse
      .then((response) => {
        setFetchAvailabilityProcessing(false);
        if (response && response.data.hits.total.value > 0) {
          if (page == 1) {
            setHasMore(true);
            setSearchResults(response.data.hits.hits);
            setTotalRecords(response.data.hits.total.value);
          } else {
            setSearchResults([...searchResults, ...response.data.hits.hits]);
            setTotalRecords(0);
          }

          setTotalRecordsToPaginate(response.data.hits.total.value);
        } else {
          setSearchResults([]);
          setTotalRecordsToPaginate(0);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  // on applying filters
  const handleApplyFilters = (filterParams, searchText) => {
    setPageNo(1);
    setHasMore(true);
    setFilters(filterParams);
    setSearchText(searchText);
    if (filterParams.length === 0) setSelectedLocation(null);

    fetchSearchResults(
      1,
      filterParams.length === 0 ? null : selectedLocation,
      searchText,
      filterParams
    );
    // fetchSearchResultsForCount(
    //   1,
    //   filterParams.length === 0 ? null : selectedLocation,
    //   searchText,
    //   filterParams
    // );
  };

  const handleCityChange = (value, options) => {
    logMixpanelEventBasic("List Filtered", "Cities", "Teleconsult Marketplace");
    if (options && value) {
      setSelectedLocation(options.props.city);
      fetchSearchResults(1, options.props.city, searchText, filters);
      // fetchSearchResultsForCount(1, city, searchText, filters);
    }
  };

  const handleShowBookTeleconsultation = (doctor) => {
    setClinicDoctorId(doctor?.clinic_doctor_id);
    setDoctorDetail(doctor);
    logMixpanelEventBasic(
      "Patient Visit Initiated",
      null,
      "Teleconsult Marketplace"
    );
    setFetchAvailabilityProcessing(true);
    // fetch profile
    fetchDoctorAvailability(doctor?.clinic_doctor_id)
      .then((response) => {
        if (response?.data?.data?.available) {
          setSelectedDoctor(doctor);
          setShowAvailabilityStatusModal(true);
          setShowTelemedicineSteps(true);
          setShowDocUnavialableInfo(false);
          setAvailabilityDetail(response?.data?.data);
        } else {
          setSelectedDoctor(doctor);
          setShowDocUnavialableInfo(true);
          setShowAvailabilityStatusModal(true);
          setAvailabilityDetail(null);
        }
      })
      .finally(() => {
        setFetchAvailabilityProcessing(false);
      });
  };

  const fetchMoreResults = () => {
    const nextPage = pageNo + 50;
    if (nextPage > totalRecordsToPaginate) {
      setHasMore(false);
      return false;
    } else {
      setFetchAvailabilityProcessing(true);
      setPageNo(nextPage);
      fetchSearchResults(nextPage, selectedLocation, searchText, filters);
    }
  };

  const handleCloseStatusModal = () => {
    setShowAvailabilityStatusModal(false);
    setSelectedDoctor(null);
    setShowDocUnavialableInfo(false);
    setAvailabilityDetail(null);
  };

  const handleGotoProfile = (e, doctor) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic(
      "Doctor Profile Viewed",
      null,
      "Teleconsult Marketplace"
    );
    window.open(
      `/doctors/${encryptId(
        doctor ? doctor.doctor_id : selectedDoctor.doctor_id
      )}`,
      "_blank"
    );
  };
  const getCityOptions = (searchText = null) => {
    const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_CITIES_INDEX}/_search`;
    const params = {
      query: {
        bool: {
          must: [],
        },
      },
      from: 0,
      size: 100,
    };

    // for search text
    if (searchText) {
      params.query.bool.must.push({
        match_phrase_prefix: { name: searchText },
      });
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${url}`, params)
        .then((response) => {
          resolve(
            response.data.hits.hits.map(({ _source }) => ({
              name: _source.name,
              value: _source.name,
              lat: _source.latitude,
              lng: _source.longitude,
            }))
          );
        })
        .catch(reject);
    });
  };

  return (
    <div className="section-container">
      <div className="doctor-listing-wrapper">
        <div className="doctor-listing-inner-wrap">
          <Filters
            handleApplyFilters={handleApplyFilters}
            isTelemed
            handleCityChange={handleCityChange}
            locations={locations}
            selectedLocation={selectedLocation}
            fetchCities={fetchCities}
            getCityOptions={getCityOptions}
          />

          {!isProcessing && searchResults.length > 0 && (
            <div className={"result-found flex-valign-centered d-flex"}>
              {/* live-user-info */}
              <div className="font-color-primary text-bold font-size-16">
                {totalRecords} {strings.doctors}
              </div>
              <div className="font-color-light-grey">
                &nbsp;{strings.live_right_now}
              </div>
            </div>
          )}
          <div>
            {!isProcessing && searchResults.length === 0 && (
              <div className={"result-found"}>{strings.no_results_found}!</div>
            )}
            {isProcessing && (
              <div className={"result-found"}>{strings.finding_doctors}...</div>
            )}
            <div className="hor-dovider"></div>

            <Spin spinning={fetchAvailabilityProcessing}>
              <ul className="tele_doc_list">
                <li className="tele_doc_item header">
                  <div className="col-80">
                    <div className="d-flex flex-valign-centered">
                      <div className="col-40">
                        <div className="doctor-name-wrap">
                          <div>
                            <div className="text-bold font-size-14">
                              {strings.doctorName}
                            </div>

                            {/* <div className="hide-text-overflow">{doctor?.speciality}</div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-25 for-desktop-screen">
                        <div className="font-size-14 text-bold">
                          {strings.Speciality}
                        </div>
                      </div>
                      <div className="col-15">
                        <div className="font-size-14 text-bold">
                          {strings.Fees}
                        </div>
                      </div>
                      <div className="col-20">
                        <div className="font-size-14 text-bold">
                          {strings.City}, {strings.State}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-15"></div>
                </li>
                {isProcessing &&
                  !fetchAvailabilityProcessing &&
                  generateRange(1, 30).map((index) => {
                    return (
                      <TeleconsultationSearchSkeleton
                        index={index}
                        screenSize={screenSize}
                      />
                    );
                  })}
              </ul>
            </Spin>
            <div className="doc-listing-wrap" id="scrollableDiv">
              <ul className="tele_doc_list">
                <InfiniteScroll
                  dataLength={searchResults?.length}
                  next={fetchMoreResults}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                >
                  {searchResults?.length > 0 &&
                    searchResults.map((item, index) => {
                      return (
                        <DoctorListItem
                          key={index}
                          doctor={item._source ? item._source : item}
                          handleShowBookTeleconsultation={
                            handleShowBookTeleconsultation
                          }
                          screenSize={screenSize}
                          handleGotoProfile={handleGotoProfile}
                        />
                      );
                    })}
                </InfiniteScroll>
              </ul>
            </div>
          </div>
          {showAvailabilityStatusModal && (
            <BookTeleconsultationModal
              showAvailabilityStatusModal={showAvailabilityStatusModal}
              handleCloseStatusModal={handleCloseStatusModal}
              showDocUnavialableInfo={showDocUnavialableInfo}
              selectedDoctor={selectedDoctor}
              availabilityDetail={availabilityDetail}
              showTelemedicineSteps={showTelemedicineSteps}
              setShowTelemedicineSteps={setShowTelemedicineSteps}
              handleGotoProfile={handleGotoProfile}
            />
          )}

          {/* <Dialog
            title={null}
            onClose={() => setShowModal(false)}
            animation="zoom"
            maskAnimation="fade"
            visible={showModal}
            wrapClassName="flex-center"
            className="booking-modal"
            footer={null}
            destroyOnClose={true}
            maskClosable={false}
          >
            <BookTeleconsultation
              cdid={clinicDoctorId}
              doctorDetails={doctorDetail}
            />
          </Dialog> */}
        </div>
      </div>
    </div>
  );
};

export default TeleconsultationSearch;
