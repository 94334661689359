import React from "react";
import Select from "react-select";
import SelectSearch from "react-select-search";
import Button from "./Button";

const PatientDetailForm = ({
  clinicPatientList,
  handleSelectClinicPatient,
  mobileNumber,
  handlePatientNameChange,
  patientName,
  patientGender,
  handleGenderChange,
  customStyles,
  handleSetPatientAge,
  patientAge,
  handleConfirmAppointment,
  isProcessing
}) => {
  return (
    <div className="add_patient_info_wrapper">
      <div>
        {clinicPatientList.length > 1 && (
          <>
            <p className="info_col_heading">
              We found some results with same mobile number, please choose if
              you are one of following:
            </p>

            <ul className="patient_list_ul">
              {clinicPatientList.map((obj) => {
                return (
                  <li onClick={() => handleSelectClinicPatient(obj)}>
                    <div className="patient-list-item">
                      <div className="patient-name">
                        {obj.attributes.name}
                        <span className="patient-other-info">{`${
                          obj.attributes.gender
                            ? `, ${obj.attributes.gender}`
                            : ""
                        }${
                          obj.attributes.age ? `, ${obj.attributes.age}` : ""
                        }`}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            <p className="div_or_text">OR</p>
          </>
        )}

        <div className="phone_number_wrapper">
          <div className="form_fields_wrapper">
            {/* mobile number */}
            <div className="form_field">
              <label className="mb-0">Your mobile number:</label>
              <div className="font-size-14">91-{mobileNumber}</div>
            </div>
            {/* patient name */}
            <div className="form_field">
              <label>Your name</label>
              <input
                type="text"
                onChange={handlePatientNameChange}
                value={patientName}
              />
              {/* <p className="error_msg hide" id="name_error">Please enter your name</p> */}
            </div>

            <div className="row_with_cols space_between">
              {/* gender */}
              <div className="form_field half_width">
                <label>Gender</label>
                <Select
                  value={patientGender}
                  onChange={handleGenderChange}
                  styles={customStyles}
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Other", value: "Other" },
                  ]}
                />

                {/* <p className="error_msg hide" id="gender_error">Please select your gender</p> */}
              </div>
              {/* age */}
              <div className="form_field half_width">
                <label>Age</label>
                <input
                  type="text"
                  onChange={handleSetPatientAge}
                  value={patientAge}
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
                <p className="error_msg hide" id="age_error"></p>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div>
        <Button
          type="primary"
          className={`full_width ${isProcessing ? 'disabled' : ''}`}
          onClick={handleConfirmAppointment}
        >
          Confirm Appointment
        </Button>
      </div>
    </div>
  );
};

export default PatientDetailForm;
