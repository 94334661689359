import React, { useState, useEffect, useContext } from "react";
import SelectSearch from "react-select-search";
import Logo from "../../images/logo.svg";
import { getEsCities } from "../../services";
import { logMixpanelEventBasic } from "../../helpers/utils";
import { PageContext } from "../SearchContextProvider";
import SearchComponent from "./SearchComponent";
import { MdSearch } from "react-icons/md";
import SearchModalComponent from "./SearchModalComponent";
import { strings, setInitialLanguage } from "../../helpers/locales";
import axios from "axios";

const Header = () => {
  const perPage = 10;
  const [locations, setLocations] = useState([]);
  const [searchModal, setSearchModal] = useState(false);
  const [showSearch, setShowSeach] = useState(true);
  const [localLanguages, setLocalLanguage] = useState([
    { name: "English", value: "en" },
    { name: "हिंदी", value: "hi" },
    { name: "ਪੰਜਾਬੀ", value: "pb" },
  ]);
  const currentLanguage = strings.getLanguage();

  const {
    selectedLocation,
    setSelectedLocation,
    searchText,
    setSearchText,
    setSearchOnEnter,
    selectedSearchType,
    setSelectedSearchType,
  } = useContext(PageContext);
  useEffect(() => {
    if (window.location.pathname !== "/") setShowSeach(false);
    fetchCities();
  }, []);

  const getCityOptions = (searchText = null) => {
    const url = `${process.env.REACT_APP_ES_SERVER_URL}/${process.env.REACT_APP_ES_CITIES_INDEX}/_search`;
    const params = {
      query: {
        bool: {
          must: [],
        },
      },
      from: 0,
      size: 100,
    };

    // for search text
    if (searchText) {
      params.query.bool.must.push({
        match_phrase_prefix: { name: searchText },
      });
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${url}`, params)
        .then((response) => {
          resolve(
            response.data.hits.hits.map(({ _source }) => ({
              name: _source.name,
              value: _source.name,
              lat: _source.latitude,
              lng: _source.longitude,
            }))
          );
        })
        .catch(reject);
    });
  };

  const fetchCities = (searchText = null) => {
    let cities = [];
    getEsCities(1, 100, searchText).then((response) => {
      if (response && response.data.hits.total.value > 0) {
        cities = response.data.hits.hits.map(({ _source }) => {
          const cityObj = {
            name: _source.name,
            value: _source.name,
            lat: _source.latitude,
            lng: _source.longitude,
          };
          return cityObj;
        });
        setLocations(cities);
      }
    });
  };

  const handleSearchTypeChange = (value, city) => {
    setSelectedSearchType(value);
  };

  const handleCityChange = (value, city) => {
    logMixpanelEventBasic(
      "Clinic Location Selected",
      null,
      null,
      "Doctor Listing"
    );

    if (city && value) {
      setSelectedLocation(city);
      // fetchCities()
      // fetchSearchResults(1, value, searchText, filters, selectedSearchType);
    }
  };

  const handleLocalLangChange = (value, lang) => {
    setInitialLanguage(value);
    window.location.reload();
  };

  return (
    <section className="header-section">
      <div className="section-container">
        <div className="flex align-center justify-between">
          <div className="top-header-left-content">
            <a href={process.env.REACT_APP_CARE_URL} className="logo-img">
              <img src={Logo} alt="Logo" />
            </a>

            {showSearch && (
              <>
                <a className="search-icon" onClick={() => setSearchModal(true)}>
                  <MdSearch className="icon" />
                </a>
                {searchModal && (
                  <SearchModalComponent
                    setSearchModal={setSearchModal}
                    searchModal={searchModal}
                    handleCityChange={handleCityChange}
                    handleSearchTypeChange={handleSearchTypeChange}
                    selectedLocation={selectedLocation}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setSearchOnEnter={setSearchOnEnter}
                    selectedSearchType={selectedSearchType}
                    locations={locations}
                    getCityOptions={getCityOptions}
                  />
                )}
                <SearchComponent
                  parentContainer={"header"}
                  handleCityChange={handleCityChange}
                  handleSearchTypeChange={handleSearchTypeChange}
                  selectedLocation={selectedLocation}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  setSearchOnEnter={setSearchOnEnter}
                  selectedSearchType={selectedSearchType}
                  locations={locations}
                  setSearchModal={setSearchModal}
                  searchModal={searchModal}
                  getCityOptions={getCityOptions}
                  fetchCities={fetchCities}
                />
              </>
            )}
          </div>
          {/* <div className={`top-header-right-content`}>
            <div className="filter-block language">
              <div className={"filter-section"}>
                <SelectSearch
                  options={localLanguages}
                  value={currentLanguage}
                  name="location"
                  placeholder="Select langauge"
                  onChange={handleLocalLangChange}
                  className="select-search small"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default Header;
