const SkeletonView = () => {
  return (
    <div className="appointment_main_wrapper">
      <div className="appt_wrapper">
        <h1 className="font-size-16 font-weight-bold">Book appointment</h1>
        <div className="skl-card-body mt-20">
          <h2 className="skl-card-title skl-skeleton"></h2>
          <div className="skl-card-img skl-skeleton"></div>

          <div className="skl-card-img skl-skeleton"></div>

          <p className="skl-card-intro skl-skeleton"></p>
        </div>
      </div>
    </div>
  );
};

export default SkeletonView;
