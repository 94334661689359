import React from "react";
import { BiLinkAlt } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdLocationOn, MdPhone } from "react-icons/md";
import { HiUser } from "react-icons/hi";
import { FaVideo } from "react-icons/fa";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { getInitials } from "../../../helpers/utils";

const ProfileTopSectionShowComponent = ({
  parentContainer,
  providerDetail,
}) => {
  return (
    <div>
      <div className="hospital_top_wrapper">
        <div className="hos-profile-img">
          {providerDetail?.logo_url ? (
            <div
              className="img-fluid"
              style={{ backgroundImage: `url(${providerDetail?.logo_url})` }}
            ></div>
          ) : (
            <div className="img-fluid">
              <div className="name-initials">
                {parentContainer === "clinic"
                  ? getInitials(providerDetail?.name)
                  : "HH"}
              </div>
            </div>
          )}
        </div>

        <div className="doc-profile-detail">
          <div className="hos-name-flex-wrap">
            <div className="doc-fullname">
              {parentContainer === "clinic"
                ? providerDetail?.name
                : "Hospital name"}
            </div>
          </div>
          {parentContainer === "clinic" && (
            <div className="doc_profile">
              <div className="doc-info-text">
                <ul className="doctor_spec_brief_list">
                  {providerDetail && providerDetail.main_speciality ? (
                    <li className="doc-spec-box font-color-primary">
                      {providerDetail?.main_speciality?.speciality_name}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>

              {providerDetail &&
              providerDetail?.other_specialities?.length > 0 ? (
                <div className="doctor_city mb-5">
                  <span className="text-bold">Other specialities:</span>{" "}
                  {providerDetail?.other_specialities
                    ?.map((obj) => {
                      return obj.speciality_name;
                    })
                    .join(", ")}
                </div>
              ) : (
                ""
              )}

              <div className="doc_appt_types">
                {providerDetail?.takes_in_clinic && (
                  <div className="doc_appt_box inperson_type">
                    <div className="doc_appt_icon mr-5">
                      <HiUser color="white" size={"12px"} />
                    </div>
                    <div>In-person visits</div>
                  </div>
                )}
                {providerDetail?.takes_teleconsultation && (
                  <div className="doc_appt_box video_visits">
                    <div className="doc_appt_icon mr-5">
                      <FaVideo color="white" size={"10px"} />
                    </div>
                    <div>Video visits</div>
                  </div>
                )}

                {providerDetail?.takes_appointment && (
                  <div className="doc_appt_box appts">
                    <div className="doc_appt_icon mr-5">
                      <BsFillCalendarEventFill color="white" size="8px" />
                    </div>
                    <div>Appointments</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {parentContainer !== "clinic" && (
            <div className="location_wrapper">
              <div className="loc_left_section hos_loc_left_section">
                <div className="location-item hospital-location-item tp-2 lft-2">
                  <MdLocationOn color="#353949" size="18px" />
                  120 Fraser Ave, Santa Monica, CA 90405
                </div>

                <div className="location-item hospital-location-item tp-3">
                  <AiOutlineClockCircle color="#353949" size={"16px"} />

                  <div>
                    <div className="">
                      <span>
                        <b>Mon-Fri:</b> 9am - 5pm{" "}
                      </span>{" "}
                      &nbsp;&nbsp;
                      <span>
                        <b> Sat: </b>9am - 2pm
                      </span>
                    </div>
                  </div>
                </div>

                <div className="location-item hospital-location-item tp-3">
                  <MdPhone color="#353949" size={"16px"} />
                  310-203-2555
                </div>

                <div className="location-item hospital-location-item tp-3">
                  <BiLinkAlt color="#353949" size={"16px"} />
                  <a href="#" target="_blank" className="blue-link">
                    www.practo.com/drtomd
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileTopSectionShowComponent;
