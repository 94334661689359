import { useEffect, useState } from "react";

const Countdown = ({ seconds, handleResendCode })=> {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return <div className="blue-text font-size-14">{timeLeft <= 0 ? <a href="#" onClick={(e) => handleResendCode(e)}>
  Resend code
</a> : `${timeLeft} sec`}</div>;
}

export default Countdown;