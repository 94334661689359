import { formatedShortDate } from "./helpers";
import SuccessIcon from "./images/appt_success.svg";
import Button from "./Button";
import { MdLocationPin } from "react-icons/md";

const SuccessContainer = ({
  patientName,
  apptTime,
  selectedApptDate,
  doctorDetails,
  fees,
  selectedApptType,
  selectedLocation,
  handleAppDownload,
  selectedWalkinDate,
  selectedDaySlot,
  parentContainer,
  displayFees,
  fromTelemedQueue,
}) => {
  return (
    <div>
      <div className="">
        <div className="ba_success_icon_box">
          <img src={SuccessIcon} alt="" />
        </div>
        <div className="name_greeting font-weight-bold">Hi {patientName}!</div>
        <div className="confirmed_appt_text">
          Your appointment has been confirmed
        </div>
        <div className="apt-confirm apt-confirm-box">
          <div className="apt-date-time-top">
            <div className="apt-time">
              {selectedApptType === "WALKIN"
                ? `${selectedDaySlot} slot`
                : apptTime}
            </div>
            {!fromTelemedQueue && (
              <div className="apt-date">
                {selectedApptType === "WALKIN"
                  ? formatedShortDate(selectedWalkinDate)
                  : formatedShortDate(selectedApptDate)}
              </div>
            )}
          </div>
          <div className="apt-block">
            <div className="apt-block-icon doctor"></div>
            <div className="apt-block-content">
              <div className="apt-block-text" id="appt_doctor_name">
                {doctorDetails?.name}
              </div>
              <div
                className="apt-block-text-grey"
                id="selected_doctor_speciality"
              >
                {doctorDetails?.speciality}
              </div>
            </div>
          </div>
          {(fees && parentContainer !== "clinic") ||
          (fees && parentContainer === "clinic" && displayFees) ? (
            <div className="apt-block" id="location_fees">
              <div className="apt-block-icon fees"></div>
              <div className="apt-block-content ml-2">
                <div className="apt-block-text">
                  <span id="appt_fees">{fees}</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="apt-block">
            <div
              className={`apt-block-icon ${
                selectedApptType === "ONCALL" || selectedApptType === "WALKIN"
                  ? "inperson"
                  : "video"
              }`}
            ></div>
            <div className="apt-block-content">
              <div className="apt-block-text" id="patient_appt_type">
                {selectedApptType === "ONCALL" || selectedApptType === "WALKIN"
                  ? "In-person"
                  : "Teleconsultation"}
              </div>
            </div>
          </div>
          {(selectedApptType === "ONCALL" || selectedApptType === "WALKIN") && (
            <div className="apt-block">
              <div className="apt-block-icon loc-icon-block">
                <MdLocationPin size={"18px"} />
              </div>
              <div className="apt-block-content">
                <div
                  className="apt-block-text font-weight-300"
                  id="appt_location"
                >
                  {selectedLocation?.label}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="whats_next_wrapper">
          <h2>What's next</h2>

          <div className="whats_next_steps font-weight-bold">
            <div>To modify or manage your bookings</div>
          </div>
          <div className="form_fields_wrapper">
            <Button
              type="primary"
              className="full_width"
              onClick={handleAppDownload}
            >
              Download app
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessContainer;
