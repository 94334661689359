import React from "react";

const LanguageShowComponent = ({ doctorDetails }) => {
  return (
    <div className="mt-10">
      <p className="font-size-14 font-weight-semibold mb-0">
        {doctorDetails &&
          doctorDetails.languages.length > 0 &&
          doctorDetails.languages.join(", ")}
      </p>
    </div>
  );
};

export default LanguageShowComponent;
