import "./styles/appointments.css";
import "./styles/button.css";
import Select from "react-select";
import { Toaster } from "react-hot-toast";
import Button from "./Button";
import { customStyles } from "./helpers";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SuccessContainer from "./SuccessContainer";
import DaySlotsContainer from "./DaySlotsContainer";
import AppointmentTypeTabs from "./AppointmentTypeTabs";
import ListSkeletonView from "./ListSkeletonView";
import PatientDetailForm from "./PatientDetailForm";
import VerifyOTPForm from "./VerifyOTPForm";
import VerifyMobileNumberForm from "./VerifyMobileNumberForm";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const BookAppointmentView = ({
  doctorDetails,
  showSuccessScreen,
  selectedApptType,
  apptTime,
  stepNo,
  handleSelectApptType,
  selectedLocation,
  handleLocationChange,
  clinicLocations,
  fees,
  useKulcareApptsFlag,
  doctorAvailabilityDays,
  handleSelectingPrefferedTime,
  handleShowVerifyPhoneNumber,
  selectedApptDate,
  handleSetApptDate,
  timeSlots,
  handleSelectApptTime,
  dateTimeString,
  showPatientPhoneField,
  setMobileNumber,
  mobileNumber,
  handleVerifyMobileNumber,
  showVerifyOTPField,
  showTimer,
  handleResendCode,
  verificationCode,
  handleOTPChange,
  handleOTPVerification,
  showPatientForm,
  clinicPatientList,
  handleSelectClinicPatient,
  handlePatientNameChange,
  patientName,
  patientGender,
  handleGenderChange,
  handleSetPatientAge,
  patientAge,
  handleConfirmAppointment,
  handleAppDownload,
  handleEditTimings,
  doctorWalkinTimeSlots,
  daysGapforFetchSchedule,
  selectedDaySlot,
  selectedWalkinDate,
  selectedScheduleIndex,
  isProcessing,
  isFetchingTimeSlots,
  walkingNoOfDays,
  handleMoveWalkingCarousal,
  timeSlotDayLimit
}) => {
  let slicedAvailabilityArray = [];

  if (doctorAvailabilityDays?.length > 0) {
    slicedAvailabilityArray = doctorAvailabilityDays.slice(
      selectedScheduleIndex
    );
  }

  return (
    <div className="appointment_main_wrapper">
      {(doctorDetails.takes_in_clinic ||
        doctorDetails.takes_appointment ||
        doctorDetails.takes_teleconsultation) &&
        doctorDetails.use_kulcare_appts && (
          <div className="appt_wrapper">
            {!showSuccessScreen && (
              <>
                <h1 className="font-size-16 font-weight-bold">
                  Book appointment
                </h1>
                <div className="appointmnet-form-wrapper">
                  {selectedApptType !== "WALKIN" && (
                    <AppointmentTypeTabs
                      doctorDetails={doctorDetails}
                      selectedApptType={selectedApptType}
                      apptTime={apptTime}
                      stepNo={stepNo}
                      handleSelectApptType={handleSelectApptType}
                    />
                  )}

                  {/* tabs */}
                  {(selectedApptType === "ONCALL" ||
                    selectedApptType === "WALKIN") && (
                    <div className="form_field appt-location-select">
                      <>
                        <label className="">
                          {stepNo === 1 && selectedApptType !== "WALKIN"
                            ? "Select clinic location"
                            : "Clinic location"}
                        </label>
                        <Select
                          value={selectedLocation}
                          onChange={handleLocationChange}
                          options={clinicLocations}
                          styles={customStyles}
                          isDisabled={
                            stepNo !== 1 || selectedApptType === "WALKIN"
                          }
                        />
                      </>
                    </div>
                  )}
                  {/* fee */}
                  {fees &&
                    useKulcareApptsFlag &&
                    selectedApptType !== "WALKIN" && (
                      <div
                        className={`fees-info appt-booking-amount font-weight-bold fee_pos ${
                          selectedApptType === "TELEMEDICINE" ? "" : ""
                        }`}
                      >
                        Consultation fees:{" "}
                        <span className="font-weight-bold ml-5">
                          {fees ? ` ₹${fees}` : "--"}
                        </span>
                      </div>
                    )}

                  {/* time slots */}
                  {stepNo === 1 &&
                    useKulcareApptsFlag &&
                    selectedApptType === "WALKIN" && (
                      <div className="day-slot-wrapper">
                        <a
                          href="#"
                          className={`walking-appt-arrow left ${
                            doctorWalkinTimeSlots[0]?.date ===
                            moment().format("YYYY-MM-DD")
                           ? "disabled" : ""}`}
                          onClick={(e) => handleMoveWalkingCarousal(e, "back")}
                        >
                          <IoIosArrowBack size={14}/>
                        </a>
                        <a
                          href="#"
                          className="walking-appt-arrow right"
                          onClick={(e) =>
                            handleMoveWalkingCarousal(e, "forward")
                          }
                        >
                          <IoIosArrowForward size={14}/>
                        </a>
                        {
                          !isFetchingTimeSlots &&
                            doctorWalkinTimeSlots.length > 0 &&
                            doctorWalkinTimeSlots.map((elem, i) => {
                              return (
                                <DaySlotsContainer
                                  doctorAvailabilityObj={elem}
                                  // daySlotObj={dayObj}
                                  handleSelectingPrefferedTime={
                                    handleSelectingPrefferedTime
                                  }
                                  doctorWalkinTimeSlots={doctorWalkinTimeSlots}
                                  selectedDaySlot={selectedDaySlot}
                                  selectedWalkinDate={selectedWalkinDate}
                                />
                              );
                            })
                          // : (
                          //   <ListSkeletonView />
                          // )
                        }
                        {isFetchingTimeSlots && <ListSkeletonView />}
                        {/* {doctorAvailabilityDays.length > 0 &&
                        doctorWalkinTimeSlots.length > 0 ? (
                          slicedAvailabilityArray.map((elem, i) => {
                            if (i < walkingNoOfDays) {
                              return (
                                <div>
                                  <div className="app-slot-heading mb-15">
                                    <div className="day mb-5">{elem.day}</div>
                                    <div className="text">
                                      {moment(elem.full_date).format("MMM DD")}
                                    </div>
                                  </div>
                                  {elem?.static_day_slots?.length > 0 &&
                                    elem.static_day_slots.map((dayObj) => {
                                      return (
                                        <DaySlotsContainer
                                          doctorAvailabilityObj={elem}
                                          daySlotObj={dayObj}
                                          handleSelectingPrefferedTime={
                                            handleSelectingPrefferedTime
                                          }
                                          doctorWalkinTimeSlots={
                                            doctorWalkinTimeSlots
                                          }
                                          selectedDaySlot={selectedDaySlot}
                                          selectedWalkinDate={
                                            selectedWalkinDate
                                          }
                                        />
                                      );
                                    })}
                                </div>
                              );
                            }
                          })
                        ) : (
                          <ListSkeletonView />
                        )} */}
                      </div>
                    )}
                  {/* step 1: start */}
                  {stepNo === 1 &&
                    useKulcareApptsFlag &&
                    selectedApptType === "WALKIN" && (
                      <div>
                        <Button
                          type="primary"
                          className={`full_width ${
                            isProcessing ? "disabled" : ""
                          }`}
                          onClick={() => handleShowVerifyPhoneNumber()}
                        >
                          Continue
                        </Button>
                      </div>
                    )}

                  {stepNo === 1 &&
                    useKulcareApptsFlag &&
                    selectedApptType !== "WALKIN" && (
                      <div className="step_1_wrap">
                        <div className="appt-calender-wrapper">
                          {/* calendar : start */}
                          <DatePicker
                            selected={selectedApptDate}
                            onChange={handleSetApptDate}
                            minDate={new Date()}
                            inline={true}
                            popperClassName="dob-calendar-popper"
                            formatWeekDay={(nameOfDay) =>
                              nameOfDay.substring(0, 3)
                            }
                            maxDate={new Date(moment().add(timeSlotDayLimit, "days"))}
                          />
                          {/* calendar : end */}
                          <div className="time-slots-section">
                            <div className="time-slots-main-block">
                              <div className="time-slots-block">
                                {/* <div className="slot-day">{formatedShortDate(selectedApptDate)}</div> */}
                                {isFetchingTimeSlots && (
                                  <div className="skl-card-img skl-skeleton mt-12"></div>
                                )}
                                {/* slots */}
                                <div className="time-slots-wrapper">
                                  {timeSlots &&
                                    timeSlots.length > 0 &&
                                    !isFetchingTimeSlots &&
                                    timeSlots.map((obj, index) => {
                                      return (
                                        <a
                                          href=""
                                          className={`appt-time ${
                                            apptTime === obj?.start_time
                                              ? "selected"
                                              : ""
                                          }`}
                                          key={`${obj?.start_time}_${obj?.end_time}_${obj.index}`}
                                          onClick={(e) =>
                                            handleSelectApptTime(e, obj)
                                          }
                                        >
                                          {obj?.start_time}
                                        </a>
                                      );
                                    })}

                                  {timeSlots.length === 0 &&
                                    !isFetchingTimeSlots && (
                                      <div className="no-slot-block">
                                        {!selectedLocation &&
                                        selectedApptType === "ONCALL"
                                          ? "Please select a location to load slots"
                                          : "No slots available"}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {doctorDetails.verified && (
                            <div>
                              <Button
                                type="primary"
                                className="full_width"
                                onClick={() => handleShowVerifyPhoneNumber()}
                              >
                                Continue
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                  {!useKulcareApptsFlag && selectedLocation && (
                    <div>
                      Appointment through kulcare are not enabled for this
                      location.
                    </div>
                  )}

                  {/* step 1: end */}
                  {stepNo === 2 && (
                    <div className="step_2_wrap">
                      <div className="more_action_wrap">
                        {selectedApptType === "WALKIN" && selectedDaySlot ? (
                          <div className="font-weight-bold text-capitalize">
                            {`${selectedDaySlot} slot`}
                          </div>
                        ) : (
                          <div className="font-weight-bold">
                            {dateTimeString}
                          </div>
                        )}
                        <a
                          href="#"
                          className="back_to_slot"
                          onClick={handleEditTimings}
                        >
                          Change
                        </a>
                      </div>

                      {/* enter mobile number block: start */}
                      {showPatientPhoneField && (
                        <VerifyMobileNumberForm
                          setMobileNumber={setMobileNumber}
                          mobileNumber={mobileNumber}
                          handleVerifyMobileNumber={handleVerifyMobileNumber}
                          isProcessing={isProcessing}
                        />
                      )}
                      {/* enter mobile number block: end */}

                      {/* enter mobile number block: start */}
                      {showVerifyOTPField && (
                        <VerifyOTPForm
                          showTimer={showTimer}
                          mobileNumber={mobileNumber}
                          handleResendCode={handleResendCode}
                          verificationCode={verificationCode}
                          handleOTPChange={handleOTPChange}
                          handleOTPVerification={handleOTPVerification}
                          isProcessing={isProcessing}
                        />
                      )}
                      {/* enter mobile number block: end */}

                      {/* show patient list and patient form */}
                      {showPatientForm && (
                        <PatientDetailForm
                          clinicPatientList={clinicPatientList}
                          handleSelectClinicPatient={handleSelectClinicPatient}
                          mobileNumber={mobileNumber}
                          handlePatientNameChange={handlePatientNameChange}
                          patientName={patientName}
                          patientGender={patientGender}
                          handleGenderChange={handleGenderChange}
                          customStyles={customStyles}
                          handleSetPatientAge={handleSetPatientAge}
                          patientAge={patientAge}
                          handleConfirmAppointment={handleConfirmAppointment}
                          isProcessing={isProcessing}
                        />
                      )}
                    </div>
                  )}
                  {/* step 2: end */}
                </div>
              </>
            )}
            {/* step 3: start */}
            {showSuccessScreen && (
              <SuccessContainer
                patientName={patientName}
                apptTime={apptTime}
                selectedApptDate={selectedApptDate}
                doctorDetails={doctorDetails}
                fees={fees}
                selectedApptType={selectedApptType}
                selectedLocation={selectedLocation}
                handleAppDownload={handleAppDownload}
                selectedWalkinDate={selectedWalkinDate}
                selectedDaySlot={selectedDaySlot}
              />
            )}
            {/* step 3: end */}
          </div>
        )}

      <Toaster />
    </div>
  );
};

export default BookAppointmentView;
