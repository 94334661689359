import React from "react";
import BookAppointmentContainer from "./BookAppointmentContainer";
import SkeletonView from "./SkeletonView";

const Appointments = ({
  doctorDetails,
  isProcessing,
  apptType,
  selectedApptDayObj,
  doctorAvailableDays,
  selectedClinicDoctorId,
  selectedDoctorLocation,
  daysGapforFetchSchedule,
  selectedScheduleIndex

}) => {
  return (
    <>
      {isProcessing && <SkeletonView />}
      {doctorDetails && (
        <BookAppointmentContainer
          doctorId={doctorDetails.doctor_id}
          doctorDetails={doctorDetails}
          isProcessing={isProcessing}
          apptType={apptType}
          selectedApptDayObj={selectedApptDayObj}
          doctorAvailableDays={doctorAvailableDays}
          selectedClinicDoctorId={selectedClinicDoctorId}
          selectedDoctorLocation={selectedDoctorLocation}
          daysGapforFetchSchedule={daysGapforFetchSchedule}
          selectedScheduleIndex={selectedScheduleIndex}
        />
      )}
    </>
  );
};

export default Appointments;
