import { useState, useEffect } from "react";
import "../../Appointments/styles/appointments.css";
import "../../Appointments/styles/button.css";
import {
  getTimeSlots,
  sendVerifyCode,
  phoneOTPVerification,
  fetchClinicPatients,
  createAppointment,
  createTeleconsultationOrder,
  resendVerifyCode,
  fetchDoctorDetail,
} from "../../Appointments/bookAppointmentService";
import {
  formatedShortDate,
  convertTime12to24,
  loadScript,
  getMobileOperatingSystem,
  handleSetLocationFee,
  setAddressLine,
} from "../../Appointments/helpers";
import moment from "moment";
import toast from "react-hot-toast";
import { logMixpanelEventBasic } from "../../../helpers/utils";
import BookAppointmentView from "./BookAppointmentView";

const defaultAppointmentSlot = 15;

const BookAppointmentContainer = ({ clinicDetail }) => {
  const noOfDays = 1;
  const [showPatientPhoneField, setShowPatientPhoneField] = useState(false);
  const [showVerifyOTPField, setShowVerifyOTPField] = useState(false);
  const [selectedApptType, setSelectedApptType] = useState(
    clinicDetail?.takes_teleconsultation ? "TELEMEDICINE" : "ONCALL"
  );
  const [clinicLocations, setClinicLocations] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [fees, setFees] = useState(null);
  const [displayFees, setDisplayFees] = useState(clinicDetail?.display_fees);
  const [selectedApptDate, setSelectedApptDate] = useState(new Date());
  const [daysGapFromNow, setDaysGapFromNow] = useState(0);
  const [apptTime, setApptTime] = useState(null);
  const [clinicDoctorId, setClinicDoctorId] = useState(null);
  const [stepNo, setStepNo] = useState(1);
  const [dateTimeString, setDateTimeString] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [verifyId, setVerifyId] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [clinicId, setClinicId] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [patientAge, setPatientAge] = useState(null);
  const [patientGender, setPatientGender] = useState({
    value: "Male",
    label: "Male",
  });
  const [clinicPatientList, setClinicPatientList] = useState([]);
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [clinicPatientId, setClinicPatientId] = useState(null);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [preferredTimeInISO, setPreferredTimeInISO] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [useKulcareApptsFlag, setUseKulcareApptsFlag] = useState(
    clinicDetail.use_kulcare_appts
  );

  const [clinicDoctors, setClinicDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [doctorId, setDoctorId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [isFetchingTimeSlots, setIsFetchingTimeSlots] = useState(false);
  const [timeSlotDayLimit, setTimeSlotDayLimit] = useState(
    defaultAppointmentSlot
  );

  const handleFetchDoctorProfile = (id, apptType) => {
    setProfileLoading(true);
    fetchDoctorDetail(id, clinicDetail?.clinic_group_id).then((response) => {
      setProfileLoading(false);
      if (response?.data?.success) {
        const doctorProfileDetail = response?.data?.data;
        let clinicLocations = doctorProfileDetail.clinic_locations;

        clinicLocations = clinicLocations
          .filter(
            (obj) =>
              obj.schedule &&
              obj.schedule.length > 0 &&
              obj.use_kulcare_appts &&
              (obj.takes_in_clinic || obj.takes_appointment)
          )
          .map((clinicObj) => ({
            label: setAddressLine(clinicObj),
            value: clinicObj?.clinic?.id,
            ...clinicObj,
          }));

        setClinicLocations(clinicLocations);
        setSelectedLocation(clinicLocations[0]);
        setUseKulcareApptsFlag(clinicLocations[0].use_kulcare_appts);
        setClinicDoctorId(clinicLocations[0].clinic_doctor_id);
        setFees(handleSetLocationFee(clinicLocations[0]));
        if (apptType)
          handleFetchTimeSlots(
            apptType ? apptType : selectedApptType,
            daysGapFromNow,
            clinicLocations[0].clinic_doctor_id
          );
      }
      // fetch doctor profile and set doctor's clinic location array
    });
  };

  useEffect(() => {
    // set clinic common doctors
    if (
      clinicDetail?.common_doctors &&
      clinicDetail?.common_doctors?.length > 0
    ) {
      let doctors = clinicDetail.common_doctors;

      doctors = doctors
        .filter((obj) => obj.schedules_present && obj.verified)
        .map((docObj) => ({ label: docObj.name, value: docObj.id, ...docObj }));
      // set time slot limit for booking online appointments
      setTimeSlotDayLimit(
        clinicDetail?.time_slot_day_limit || defaultAppointmentSlot
      );
      if (doctors.length > 0) {
        setSelectedDoctor(doctors[0]);
        setClinicDoctors(doctors);
        if (selectedApptType === "TELEMEDICINE") {
          setDoctorId(doctors[0].id);
          // setFees(doctors[0].max_telemedicine_fees);
          loadScript(
            "https://checkout.razorpay.com/v1/checkout.js",
            "razorpay"
          );
          handleFetchTimeSlots(selectedApptType, daysGapFromNow, doctors[0].id);
        } else {
          handleFetchDoctorProfile(doctors[0].id);
        }
      }
    }
  }, [clinicDetail]);

  // handle fetch time slots
  const handleFetchTimeSlots = (selectedApptType, daysGapFromNow, id) => {
    if (!id) return false;
    setIsFetchingTimeSlots(true);
    getTimeSlots(
      id,
      noOfDays,
      daysGapFromNow,
      selectedApptType === "TELEMEDICINE"
    ).then((response) => {
      setIsFetchingTimeSlots(false);
      if (response?.data?.success) {
        setTimeSlots(response.data.data[0].timeslots);
      }
    });
  };

  // handle select appt ype
  const handleSelectApptType = (e, apptType) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic(
      "Patient Visit Type Selected",
      null,
      null,
      "Clinic profile"
    );

    setSelectedApptType(apptType);
    setSelectedLocation(null);
    if (apptType === "TELEMEDICINE") {
      setSelectedDoctor(clinicDoctors[0]);
      // setFees(clinicDoctors[0].max_telemedicine_fees);
      setDoctorId(clinicDoctors[0].id);
      // fetch time slots
      handleFetchTimeSlots(apptType, daysGapFromNow, clinicDoctors[0].id);
    } else {
      if (clinicDoctors.length > 0) {
        setSelectedDoctor(clinicDoctors[0]);
        handleFetchDoctorProfile(clinicDoctors[0].id, apptType);
        setFees(null);
      } else {
        setSelectedLocation();
        setFees(null);
        setTimeSlots([]);
        setUseKulcareApptsFlag(true);
      }
    }
  };

  // handle select doctor location
  const handleLocationChange = (locObj) => {
    logMixpanelEventBasic(
      "Clinic Location Selected",
      null,
      null,
      "Clinic Profile"
    );
    setSelectedLocation(locObj);
    setClinicDoctorId(locObj.clinic_doctor_id);
    setClinicId(locObj.clinicId);
    // setDaysGapFromNow(0);
    setUseKulcareApptsFlag(locObj.use_kulcare_appts);

    setFees(handleSetLocationFee(locObj));
    handleFetchTimeSlots(
      selectedApptType,
      daysGapFromNow,
      locObj.clinic_doctor_id
    );
  };

  const handleSetApptDate = (val) => {
    logMixpanelEventBasic(
      "Next Availability Viewed",
      null,
      null,
      "Clinic Profile"
    );
    let apptDate = moment(val); // or whatever start date you have

    const today = moment().startOf("day");
    let daysGap = Math.floor(moment.duration(apptDate - today).asDays());
    setSelectedApptDate(new Date(val));
    setDaysGapFromNow(daysGap);
    handleFetchTimeSlots(
      selectedApptType,
      daysGap,
      selectedApptType === "TELEMEDICINE" ? doctorId : clinicDoctorId
    );
  };

  const handleSelectApptTime = (e, timeObj) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic(
      "Patient Visit Time Selected",
      null,
      null,
      "Clinic Profile"
    );
    setApptTime(timeObj?.start_time);
    setClinicDoctorId(timeObj.clinic_doctor_id);
    setClinicId(timeObj.clinic_id);
    if (selectedApptType === "TELEMEDICINE") setFees(timeObj.telemedicine_fees);
  };

  const handleShowVerifyPhoneNumber = () => {
    if (apptTime && clinicId) {
      setDateTimeString(
        `${formatedShortDate(selectedApptDate)} at ${apptTime}`
      );
      setStepNo(2);
      setShowPatientPhoneField(true);
    } else {
      toast.error("Please select the clinic location and timings");
    }
  };

  const handleVerifyMobileNumber = () => {
    if (!mobileNumber || (mobileNumber && mobileNumber.trim() === "")) {
      toast.error("Please enter your mobile number");
      return false;
    }
    logMixpanelEventBasic(
      "Patient Phone Number Added",
      null,
      null,
      "Clinic Profile"
    );

    const params = {
      clinic_doctor_id: clinicDoctorId,
      country_code: 91,
      phone_number: mobileNumber,
      appointment_type: selectedApptType,
      payment_option: "BEFORE",
    };
    const preferredTime =
      moment(selectedApptDate).format("MMMM D, YYYY") +
      " " +
      convertTime12to24(apptTime);

    const preferredTimeTemp = new Date(preferredTime);
    const preferredTimeInISO = preferredTimeTemp.toISOString();
    setPreferredTimeInISO(preferredTimeInISO);
    params.preferred_time = preferredTimeInISO;

    setIsProcessing(true);
    sendVerifyCode(params)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          setVerifyId(response.data.data.id);
          setShowPatientPhoneField(false);
          setShowVerifyOTPField(true);
          setShowTimer(true);
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleOTPVerification = () => {
    if (!verificationCode) {
      toast.error("Please enter OTP sent on your mobile number");
      return false;
    }
    const params = {
      verification_code: verificationCode,
    };
    setShowTimer(false);
    setShowPatientPhoneField(false);
    setIsProcessing(true);
    phoneOTPVerification(verifyId, params)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          logMixpanelEventBasic(
            "Patient Phone Number Verified",
            null,
            null,
            "Clinic Profile"
          );
          setShowPatientForm(true);
          setShowVerifyOTPField(false);
          handleFetchingClinicPatients();
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleFetchingClinicPatients = () => {
    fetchClinicPatients(clinicId, mobileNumber).then((response) => {
      if (response?.data?.data?.length > 0) {
        if (response.data.data.length > 1)
          setClinicPatientList(response.data.data);
        else {
          const clinicPatient = response.data.data[0].attributes;
          setPatientName(clinicPatient.name);
          if (clinicPatient.gender) {
            const genderObj = {
              label: clinicPatient.gender,
              value: clinicPatient.gender,
            };
            setPatientGender(genderObj);
          }
          if (clinicPatient.age) setPatientAge(clinicPatient.age);
        }
      }
    });
  };

  const handleOTPChange = (val) => {
    setVerificationCode(val);
  };

  const handlePatientNameChange = (e) => {
    setPatientName(e.target.value);
  };

  const handleSetPatientAge = (e) => {
    setPatientAge(e.target.value);
  };

  const handleGenderChange = (obj) => {
    setPatientGender(obj);
  };

  const handleConfirmAppointment = () => {
    if (
      !patientName ||
      (patientName && patientName.trim() === "") ||
      !patientGender
    ) {
      toast.error("Please enter patient details");
      return false;
    }

    const params = {
      clinic_doctor_id: clinicDoctorId,
      name: patientName,
      country_code: 91,
      phone_number: mobileNumber,
      gender: patientGender.value,
      age: patientAge,
      clinic_patient_id: clinicPatientId,
    };
    setIsProcessing(true);
    createAppointment(verifyId, params)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          logMixpanelEventBasic(
            "Patient Visit Added",
            null,
            null,
            "Clinic Profile"
          );

          if (
            selectedApptType === "ONCALL" ||
            (selectedApptType === "TELEMEDICINE" && !fees)
          ) {
            setShowSuccessScreen(true);
            setShowPatientForm(false);
          } else {
            handleCreatingTelemedConsultation(response.data.data);
          }
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleCreatingTelemedConsultation = (apptObj) => {
    var orderParams = {
      clinic_doctor_id: clinicDoctorId,
      patient_id: apptObj.pid,
      appt_verification_id: verifyId,
      slot_start_time: preferredTimeInISO,
      payment_option: "BEFORE",
    };

    setIsProcessing(true);
    createTeleconsultationOrder(orderParams, apptObj.t)
      .then((response) => {
        setIsProcessing(false);
        if (response?.data?.success) {
          var options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: "kulcare",
            description: "Telemedicine Payment",
            order_id: response.data.data.razorpay_order_id,
            image:
              "https://kulcare-assets.s3-ap-southeast-1.amazonaws.com/kulcare_logo_black.png",
            handler: (response) => {
              setShowSuccessScreen(true);
              setShowPatientForm(false);
            },
            prefill: {
              name: patientName,
              email: "",
              contact: `91${mobileNumber}`,
            },
            notes: {
              address: "",
            },
            theme: {
              color: "#078DFF",
            },
          };

          let rzp = new window.Razorpay(options);
          rzp.open();
        } else {
          toast.error(response.data.errors[0].title);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const handleSelectClinicPatient = (patientObj) => {
    setClinicPatientId(patientObj.id);
    setPatientName(patientObj.attributes.name);
    setPatientGender({
      label: patientObj.attributes.gender,
      value: patientObj.attributes.gender,
    });
    setPatientAge(patientObj.attributes.age);
  };

  const handleEditTimings = (e) => {
    if (e) e.preventDefault();
    logMixpanelEventBasic("Patient Visit Edited", null, null, "Clinic Profile");
    setStepNo(1);
    setShowTimer(false);
    setShowVerifyOTPField(false);
  };

  const handleResendCode = () => {
    setShowTimer(false);
    resendVerifyCode(verifyId).then((response) => {
      if (response?.data?.success) {
        setShowPatientPhoneField(false);
        setShowVerifyOTPField(true);
        setShowTimer(true);
      } else {
        toast.error(response.data.errors[0].title);
      }
    });
  };

  /**
   * @function handleAppDownload
   * @description When user click on download
   */
  const handleAppDownload = () => {
    var os = getMobileOperatingSystem();
    logMixpanelEventBasic(
      "Patient App Downloaded",
      null,
      null,
      "Clinic Profile"
    );
    if (os === "Windows Phone" || os === "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.patient_healthcare_app&hl=en_IN",
        "_blank"
      );
    } else {
      window.open(
        "https://apps.apple.com/us/app/kulcare/id1253938993",
        "_blank"
      );
    }
  };

  // handle select doctor location
  const handleDoctorChange = (docObj) => {
    setSelectedDoctor(docObj);
    if (selectedApptType === "ONCALL") {
      setClinicLocations([]);
      setSelectedLocation(null);
      setClinicDoctorId(null);
      setClinicId(null);
      // setDaysGapFromNow(0);
      setUseKulcareApptsFlag(clinicDetail.use_kulcare_appts);

      setFees(null);
      handleFetchDoctorProfile(docObj?.id);
    } else {
      setDoctorId(docObj.id);
      // setFees(docObj.max_telemedicine_fees);
      handleFetchTimeSlots(selectedApptType, daysGapFromNow, docObj.id);
    }
  };

  return (
    <BookAppointmentView
      showSuccessScreen={showSuccessScreen}
      selectedApptType={selectedApptType}
      apptTime={apptTime}
      stepNo={stepNo}
      handleSelectApptType={handleSelectApptType}
      selectedLocation={selectedLocation}
      handleLocationChange={handleLocationChange}
      clinicLocations={clinicLocations}
      fees={fees}
      useKulcareApptsFlag={useKulcareApptsFlag}
      handleShowVerifyPhoneNumber={handleShowVerifyPhoneNumber}
      selectedApptDate={selectedApptDate}
      handleSetApptDate={handleSetApptDate}
      timeSlots={timeSlots}
      handleSelectApptTime={handleSelectApptTime}
      dateTimeString={dateTimeString}
      showPatientPhoneField={showPatientPhoneField}
      setMobileNumber={setMobileNumber}
      mobileNumber={mobileNumber}
      handleVerifyMobileNumber={handleVerifyMobileNumber}
      showVerifyOTPField={showVerifyOTPField}
      showTimer={showTimer}
      handleResendCode={handleResendCode}
      verificationCode={verificationCode}
      handleOTPChange={handleOTPChange}
      handleOTPVerification={handleOTPVerification}
      showPatientForm={showPatientForm}
      clinicPatientList={clinicPatientList}
      handleSelectClinicPatient={handleSelectClinicPatient}
      handlePatientNameChange={handlePatientNameChange}
      patientName={patientName}
      patientGender={patientGender}
      handleGenderChange={handleGenderChange}
      handleSetPatientAge={handleSetPatientAge}
      patientAge={patientAge}
      handleConfirmAppointment={handleConfirmAppointment}
      handleAppDownload={handleAppDownload}
      handleEditTimings={handleEditTimings}
      clinicDetail={clinicDetail}
      clinicDoctors={clinicDoctors}
      selectedDoctor={selectedDoctor}
      handleDoctorChange={handleDoctorChange}
      displayFees={displayFees}
      isProcessing={isProcessing}
      profileLoading={profileLoading}
      isFetchingTimeSlots={isFetchingTimeSlots}
      timeSlotDayLimit={timeSlotDayLimit}
    />
  );
};

export default BookAppointmentContainer;
