import Dialog from "rc-dialog";
import React, { useState } from "react";
import SearchComponent from "./SearchComponent";

const SearchModalComponent = ({
  searchModal,
  setSearchModal,
  handleCityChange,
  handleSearchTypeChange,
  selectedLocation,
  searchText,
  setSearchText,
  setSearchOnEnter,
  selectedSearchType,
  locations,
  getCityOptions
}) => {
  return (
    <Dialog
      title="Search"
      className="search-bar-modal"
      visible={searchModal}
      onClose={() => setSearchModal(false)}
    >
      <SearchComponent
        parentContainer={"modal"}
        handleCityChange={handleCityChange}
        handleSearchTypeChange={handleSearchTypeChange}
        selectedLocation={selectedLocation}
        searchText={searchText}
        setSearchText={setSearchText}
        setSearchOnEnter={setSearchOnEnter}
        selectedSearchType={selectedSearchType}
        locations={locations}
        searchModal={searchModal}
        setSearchModal={setSearchModal}
        getCityOptions={getCityOptions}
      />
    </Dialog>
  );
};

export default SearchModalComponent;
