import Button from "../Button";
import moment from "moment";
import { FaVideo } from "react-icons/fa";
import { HiUser } from "react-icons/hi";
import { BsFillCalendarEventFill } from "react-icons/bs";
import haversine from "haversine-distance";
import {
  encryptId,
  removeDuplicates,
  getInitials,
  getDocDefaultImage,
  showDiscountedTag,
  showDiscountedRange,
} from "../../helpers/utils";
import DoctorPhoto from "../../images/person-icon.svg";

const removeDuplicatesWithIndex = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

const dayMapObj = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

const handleSetScheduleDays = (doctorScheduleDays) => {
  const sortedArr = doctorScheduleDays.sort((a, b) => {
    return dayMapObj[a] - dayMapObj[b];
  });

  let tempArr = [];

  for (let j = 0; j < 7; j++) {
    for (let i = 0; i < sortedArr.length; i++) {
      let obj = {
        day: sortedArr[i],
        date: moment().add(j, "weeks").day(sortedArr[i]).format("YYYY-MM-DD"),
        full_date: moment().add(j, "weeks").day(sortedArr[i]).toISOString(),
      };
      tempArr.push(obj);
    }
  }
  const finalScheduleDayArray = removeDuplicates(tempArr, "date");
  return finalScheduleDayArray;
};

const buildArrayOfAvailableDays = (schedules) => {
  const allScheduleDays = [];
  for (let i = 0; i < schedules.length; i++) {
    let allDays = [];
    for (var j = 0; j < schedules[i].days.length; j++) {
      allDays.push(schedules[i].days[j]);
    }
    allScheduleDays.push(allDays);
  }
  let finalArrayOfDays = allScheduleDays.reduce(
    (elem1, elem2) => elem1.concat(elem2),
    []
  );
  finalArrayOfDays = removeDuplicatesWithIndex(finalArrayOfDays);
  return finalArrayOfDays;
};

const ifAvailableToday = (doctor) => {
  const { schedules } = doctor;
  let isAvailableToday = false;
  const todayString = moment().format("ddd");
  const allScheduleDays = buildArrayOfAvailableDays(schedules);
  if (allScheduleDays.includes(todayString)) isAvailableToday = true;
  return isAvailableToday;
};

const DoctorSearchItem = ({
  doctor,
  handleItemHover,
  itemIndex,
  handleShowBookAppointment,
  selectedLocation,
  handleGotoDoctorDetails,
}) => {
  let doctorSpl = [];
  if (doctor.speciality) {
    doctorSpl.push(doctor.speciality);
  }
  if (doctor.education) {
    doctorSpl.push(doctor.education);
  }

  let docLocation = [];
  if (doctor.location.address_line_1) {
    docLocation.push(doctor.location.address_line_1);
  }
  if (doctor.location.address_line_2) {
    docLocation.push(doctor.location.address_line_2);
  }

  const doctorScheduleDays = buildArrayOfAvailableDays(doctor.schedules) || [];
  let doctorAvailableDays = handleSetScheduleDays(doctorScheduleDays);
  // filter out the days that are before today
  doctorAvailableDays = doctorAvailableDays.filter((obj) => {
    if (
      moment(obj.date).isAfter(moment().format("YYYY-MM-DD")) ||
      moment(obj.date).isSame(moment().format("YYYY-MM-DD"))
    )
      return obj;
  });

  return (
    <div
      onMouseOver={() => handleItemHover(itemIndex)}
      className="doc-search-list-wrapper flex"
    >
      <div className="search-item-left">
        {doctor.photo && doctor.photo.trim() ? (
          <div className="search-image">
            <img
              src={
                doctor.photo && doctor.photo.trim() !== ""
                  ? `${doctor.photo}`
                  : `${DoctorPhoto}`
              }
              alt="doctor_photo"
            ></img>
          </div>
        ) : (
          <div
            className="search-image"
            style={{ backgroundImage: `url(${getDocDefaultImage(doctor)})` }}
          >
            <div className="name-initials">{getInitials(doctor?.name)}</div>
          </div>
        )}
        {showDiscountedRange(doctor)}
      </div>
      <div className="search-info">
        <div className="name" onClick={() => handleGotoDoctorDetails(doctor)}>
          Dr. {doctor.name}
        </div>
        {doctorSpl.length > 0 && (
          <div className="specialities">{doctorSpl.join(", ")}</div>
        )}
        {doctor && doctor?.other_specialities?.length > 0 ? (
          <div className="location">
            <div className="location-text">
              <span className="text-bold pr-5">Other specialities:</span>{" "}
              {doctor?.other_specialities
                ?.map((obj) => {
                  return obj.name;
                })
                .join(", ")}
            </div>
          </div>
        ) : (
          ""
        )}

        {docLocation.length > 0 && (
          <div className="location">
            <span className="location-text">{docLocation.join(", ")} </span>

            {doctor.location_coordinates &&
              doctor.location_coordinates.lat &&
              selectedLocation &&
              selectedLocation.lat && (
                <span className="search-distance">
                  {`${(
                    haversine(selectedLocation, doctor.location_coordinates) /
                    1000
                  ).toFixed(1)} km`}
                </span>
              )}
          </div>
        )}
        <div className="appointment-types">
          {doctor.takes_in_clinic && (
            <span className="in-person">
              {" "}
              <i>
                <HiUser className="vertical-center" />
              </i>{" "}
              In-person visits
            </span>
          )}
          {doctor.takes_teleconsultation && (
            <span className="video-visit">
              {" "}
              <i>
                <FaVideo className="vertical-center" />
              </i>{" "}
              Video visits
            </span>
          )}
          {doctor.takes_appointment && (
            <span className="appointment">
              {" "}
              <i>
                <BsFillCalendarEventFill className="vertical-center" />
              </i>{" "}
              Appointments
            </span>
          )}
        </div>
        {(doctor.fees || doctor.telemedicine_fees) && (
          <div className="fee-types">
            {doctor.fees && <span>₹ {doctor.fees} Consultation fee</span>}
            {doctor.telemedicine_fees && (
              <span>₹ {doctor.telemedicine_fees} Video consult. fee</span>
            )}
          </div>
        )}
        <div className="lang-reviews">
          {doctor?.languages?.length > 0 && (
            <span className="languages">{doctor.languages.join(", ")}</span>
          )}
          {doctor.reviews_count ? (
            <a
              href={`/doctors/${encryptId(doctor.doctor_id)}/#review`}
              className="reviews-count"
            >
              {doctor.reviews_count}{" "}
              {doctor.reviews_count > 1 ? "reviews" : "review"}
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
      {doctor.use_kulcare_appts && doctor.verified && (
        <div className="search-actions">
          <div className="availabile-today">
            {ifAvailableToday(doctor) ? "Available today" : "Unavailable today"}
          </div>
          {doctorAvailableDays.length > 0 && (
            <div className="availability-section">
              {doctorAvailableDays.map((elem, i) => {
                if (i < 3) {
                  return (
                    <div
                      className="time-slot"
                      onClick={() =>
                        handleShowBookAppointment(
                          "WALKIN",
                          doctor,
                          i,
                          elem,
                          doctorAvailableDays
                        )
                      }
                    >
                      {showDiscountedTag(elem.day, doctor)}
                      <span className="day">{elem.day}</span>
                      <span className="text">Join Queue</span>
                    </div>
                  );
                }
              })}
            </div>
          )}

          <div className="search-buttons">
            {doctor.takes_in_clinic && doctor.fees && (
              <Button
                type={"secondary"}
                size="small"
                block
                onClick={() => handleShowBookAppointment("ONCALL", doctor)}
              >
                Book appointment
              </Button>
            )}
            {doctor.takes_teleconsultation && doctor.telemedicine_fees ? (
              <Button
                type={"primary"}
                size="small"
                block
                onClick={() =>
                  handleShowBookAppointment("TELEMEDICINE", doctor)
                }
              >
                Book teleconsult
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default DoctorSearchItem;
