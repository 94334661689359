import React from "react";
import SelectSearch from "react-select-search";
import fuzzySearch from "../../helpers/fuzzySearch";

const searchTypes = [
  { name: "Doctors", value: "doctors" },
  { name: "Hospitals", value: "hospitals" },
];
const SearchComponent = ({
  handleCityChange,
  handleSearchTypeChange,
  selectedLocation,
  searchText,
  setSearchText,
  setSearchOnEnter,
  selectedSearchType,
  locations,
  parentContainer,
  searchModal,
  setSearchModal,
  getCityOptions,
}) => {
  const handleSearchModal = (e) => {
    if (e.key === "Enter") {
      setSearchOnEnter(true);
      setSearchModal(false);
    }
  };

  const handleOnClickSearch = (e) => {
    setSearchModal(false);
    setSearchOnEnter(true);
  };

  const handleOnClearSearch = () => {
    setSearchText("");
    setSearchOnEnter(false);
  };
  return (
    <div className={`search-bar-wrap ${parentContainer}`}>
      {process.env.REACT_APP_ENVIRONMENT !== "production" && (
        <div className={`search-type-icon ${selectedSearchType}`}>
          <SelectSearch
            options={searchTypes}
            name="hospitals"
            placeholder="Select"
            search
            value={selectedSearchType}
            filterOptions={fuzzySearch}
            onChange={handleSearchTypeChange}
          />
        </div>
      )}
      <input
        placeholder="Find a doctor"
        value={searchText}
        searchModal={searchModal}
        onChange={(e) => {
          setSearchText(e.target.value);
          setSearchOnEnter(false);
        }}
        onKeyPress={(e) => handleSearchModal(e)}
      />
      <div className="location-search">
        <SelectSearch
          options={locations}
          value={selectedLocation}
          name="location"
          placeholder="Select city"
          search
          filterOptions={fuzzySearch}
          onChange={handleCityChange}
          debounce={5}
          getOptions={getCityOptions}
        />
      </div>
      <div className="btn-wrapper">
        <button className="btn-apply" onClick={() => handleOnClickSearch()}>
          Apply
        </button>
        <button className="btn-cancel" onClick={() => handleOnClearSearch()}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default SearchComponent;
