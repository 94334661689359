import React, { Component } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import "./fonts.css";
import "./profile.css";
import "./doctor-profile.css";
import { decryptId } from "../../helpers/utils";
import {
  fetchDoctorProfileDetailES,
  fetchListedDoctorDetail,
} from "../Appointments/bookAppointmentService";
import InplaceEditWidget from "./InplaceEditWidget";
import WorkShowComponent from "./Work/WorkShowComponent";
import EducationShowComponent from "./Education/EducationShowComponent";
import AboutShowComponent from "./About/AboutShowComponent";
import CertificationsShowComponent from "./Certifications/CertificationsShowComponent";
import LanguageShowComponent from "./Languages/LanguageShowComponent";
import LocationShowComponent from "./Locations/LocationShowComponent";
import ProfileTopSectionShowComponent from "./ProfileTopSection/ProfileTopSectionShowComponent";
import TimingsShowComponent from "./Timings/TimingsShowComponent";
import ReviewsContainer from "./Reviews/ReviewsContainer";
import { ProfileSkeleton } from "./ProfileSkeleton";

class DoctorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workVisible: "show",
      educationVisible: "show",
      aboutVisible: "show",
      certificationsVisible: "show",
      languagesVisible: "show",
      locationsVisible: "show",
      showbackdrop: false,
      profileVisible: "show",
      doctorDetails: null,
      doctorId: null,
      isProcessing: true,
      basicInfoComplete: false,
      experienceInfoComplete: false,
      educationInfoComplete: false,
      certificationsComplete: false,
      previewImage: "",
      uploading: false,
      apiArray: [],
      showDeleteModal: false,
      previewVisible: false,
      fileList: [],
      displayLogoImages: [],
      showProfileCompletionModal: false,
      listedFeature: false,
      showProfileModal: false,
      listingParamsFulfilled: false,
      availabilityAndFeesComplete: false,
      selectedExperience: null,
      selectedEducation: null,
      selectedCertification: null,
      componentViewMode: "self",
      doctorClinicLocations: [],
      timingsVisible: "show",
      reviewsVisible: "show",
      screenSize: "big",
    };
    this.reviewRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    const params = this.props.match?.params;
    // fid = file id
    const { did } = params;
    if (did) {
      this.setState({ doctorId: decryptId(did) }, () => {
        // this.fetchUserClinicLocations();
        this.fetchDoctorDetails(this.state.doctorId, true, true);
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  /**
   * @function handleResize
   * @desc This funtion sets the screen type
   * in local state
   */
  handleResize = () => {
    const windowSize = window.innerWidth;
    const screenSize = windowSize > 768 ? "big" : "small";
    this.setState({ screenSize });
  };

  /**
   * @function fetchDoctorDetails
   * @param docId
   * @desc This function handles fetching doctor detail
   */
  fetchDoctorDetails = (docId) => {
    this.setState({ isProcessing: true });
    // getClinicDoctorDetail(docId, getClinicDoctorId())
    fetchDoctorProfileDetailES(docId)
      .then((response) => {
        if (response && response.data.hits.total.value > 0) {
          this.setState(
            {
              doctorDetails: response.data.hits.hits[0]._source,
            }
            // () => {
            //   const isReviewHashPresent = window?.location?.hash;
            //   if (isReviewHashPresent?.includes("review"))
            //     this.reviewRef?.current?.scrollIntoView();
            // }
          );
        } else {
          const params = this.props.match?.params;
          // fid = file id
          const { did } = params;
          this.handleFetchListedDoctor(did);
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  handleFetchListedDoctor = (did) => {
    this.setState({ isProcessing: true });
    // getClinicDoctorDetail(docId, getClinicDoctorId())
    fetchListedDoctorDetail(did)
      .then((response) => {
        if (response?.data?.success) {
          this.setState({
            doctorDetails: response?.data?.data,
          });
        }
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function toggleProcessing
   * @desc This function handles toggling processing
   */
  toggleProcessing = () => {
    const { isProcessing } = this.state;
    this.setState({ isProcessing: !isProcessing });
  };

  /**
   * @func handleCancel
   * @desc it calncles the preview mode
   */
  handleCancel = () => this.setState({ previewVisible: false });

  /**
   * @func processErrors
   * @description this will shows error notification that we get while uploading image
   */
  processErrors = () => {
    this.setState({ uploading: false });
  };

  /**
   * @function handleDeleteExp
   * @desc This function handles deleting experience
   */
  handleDeleteExp = (expId, e) => {
    if (e) e.preventDefault();
  };

  /**
   * @function handleDeleteExp
   * @desc This function handles deleting experience
   */
  handleDeleteEdu = (eduId, e) => {
    if (e) e.preventDefault();
  };

  handleEditExp = (experience, e) => {};

  handleEditEdu = (education, e) => {};

  handleEditCert = (certificate, e) => {};

  /**
   * @function handleDelete
   * @desc This function handles deleting certification data
   */
  handleDeleteCert = (certificationId) => {};

  toggleProfileViewMode = (e) => {
    if (e) e.preventDefault();
    const { componentViewMode } = this.state;
    this.setState({
      componentViewMode: componentViewMode === "self" ? "public" : "self",
    });
  };

  render() {
    const {
      workVisible,
      educationVisible,
      aboutVisible,
      certificationsVisible,
      languagesVisible,
      locationsVisible,
      profileVisible,
      doctorDetails,
      timingsVisible,
      componentViewMode,
      isProcessing,
      screenSize,
    } = this.state;

    return (
      <div>
        {isProcessing && <ProfileSkeleton />}

        {!isProcessing && (
          <div className="doctor-profile-container">
            <div className="profile-section-left">
              <InplaceEditWidget
                ShowComponent={
                  <ProfileTopSectionShowComponent
                    widgetStateName={"profileVisible"}
                    visible={profileVisible}
                    doctorDetails={doctorDetails}
                  />
                }
                visible={profileVisible}
                widgetTitle={null}
              />

              {screenSize === "small" && (
                <InplaceEditWidget
                  ShowComponent={
                    <TimingsShowComponent
                      widgetStateName={"timingsVisible"}
                      doctorDetails={doctorDetails}
                      componentViewMode={componentViewMode}
                      isProcessing={isProcessing}
                    />
                  }
                  visible={timingsVisible}
                  widgetTitle={""}
                />
              )}

              {doctorDetails?.clinic_locations.length > 0 &&
                doctorDetails?.show_locations && (
                  <InplaceEditWidget
                    ShowComponent={
                      <LocationShowComponent
                        locations={doctorDetails?.clinic_locations || []}
                      />
                    }
                    visible={locationsVisible}
                    widgetTitle={"Current clinic locations"}
                  />
                )}

              {doctorDetails?.experiences_list.length > 0 &&
                doctorDetails?.show_experience && (
                  <InplaceEditWidget
                    ShowComponent={
                      <WorkShowComponent
                        doctorDetails={doctorDetails}
                        visible={workVisible}
                        widgetStateName={"workVisible"}
                        handleEditExp={this.handleEditExp}
                        handleDeleteExp={this.handleDeleteExp}
                        componentViewMode={componentViewMode}
                      />
                    }
                    visible={workVisible}
                    widgetTitle={"Work"}
                  />
                )}
              {doctorDetails?.education_list.length > 0 &&
                doctorDetails?.show_education && (
                  <InplaceEditWidget
                    ShowComponent={
                      <EducationShowComponent
                        widgetStateName={"educationVisible"}
                        doctorDetails={doctorDetails}
                        handleEditEdu={this.handleEditEdu}
                        handleDeleteEdu={this.handleDeleteEdu}
                        componentViewMode={componentViewMode}
                      />
                    }
                    visible={educationVisible}
                    widgetTitle={"Education"}
                  />
                )}
              {doctorDetails?.bio && doctorDetails?.show_about && (
                <InplaceEditWidget
                  ShowComponent={
                    <AboutShowComponent
                      doctorDetails={doctorDetails}
                      widgetStateName={"aboutVisible"}
                      visible={aboutVisible}
                    />
                  }
                  visible={aboutVisible}
                  widgetTitle={"About"}
                />
              )}
              {doctorDetails?.certifications_list.length > 0 &&
                doctorDetails?.show_certifications && (
                  <InplaceEditWidget
                    ShowComponent={
                      <CertificationsShowComponent
                        doctorDetails={doctorDetails}
                        visible={certificationsVisible}
                        widgetStateName={"certificationsVisible"}
                        handleEditCert={this.handleEditCert}
                        handleDeleteCert={this.handleDeleteCert}
                        componentViewMode={componentViewMode}
                      />
                    }
                    visible={certificationsVisible}
                    widgetTitle={"Certifications & Awards"}
                    widgetStateName={"certificationsVisible"}
                    componentType="list"
                    componentViewMode={componentViewMode}
                  />
                )}
              {doctorDetails?.languages?.length > 0 &&
                doctorDetails?.show_languages && (
                  <InplaceEditWidget
                    ShowComponent={
                      <LanguageShowComponent
                        widgetStateName={"languagesVisible"}
                        doctorDetails={doctorDetails}
                      />
                    }
                    visible={languagesVisible}
                    widgetTitle={"Languages Spoken"}
                  />
                )}
            </div>
            {/* left sections end  */}

            {/* right section start  */}
            <div className="profile-section-right">
              {screenSize === "big" && (
                <InplaceEditWidget
                  ShowComponent={
                    <TimingsShowComponent
                      widgetStateName={"timingsVisible"}
                      doctorDetails={doctorDetails}
                      componentViewMode={componentViewMode}
                      isProcessing={isProcessing}
                    />
                  }
                  visible={timingsVisible}
                  widgetTitle={""}
                />
              )}

              {/* {doctorDetails?.show_reviews && (
            <InplaceEditWidget
              ShowComponent={
                <ReviewsContainer
                  widgetStateName={"reviewsVisible"}
                  doctorDetails={doctorDetails}
                  componentViewMode={componentViewMode}
                  doctorId={doctorDetails?.id}
                  reviewRef={this.reviewRef}
                />
              }
              EditComponent={null}
              visible={reviewsVisible}
              widgetTitle={""}
              widgetStateName={"reviewsVisible"}
              componentType="paragraph"
              toggleSectionVisibility={this.toggleSectionVisibility}
            />
          )} */}
            </div>
            {/* right section end  */}
          </div>
        )}
      </div>
    );
  }
}

export default DoctorProfile;
