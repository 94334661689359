import "../../Appointments/styles/appointments.css";
import "../../Appointments/styles/button.css";
import Select from "react-select";
import { Toaster } from "react-hot-toast";
import Button from "../../Button";
import { customStyles } from "../../Appointments/helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SuccessContainer from "../../Appointments/SuccessContainer";
import AppointmentTypeTabs from "../../Appointments/AppointmentTypeTabs";
import PatientDetailForm from "../../Appointments/PatientDetailForm";
import VerifyOTPForm from "../../Appointments/VerifyOTPForm";
import VerifyMobileNumberForm from "../../Appointments/VerifyMobileNumberForm";
import moment from "moment";

const BookAppointmentView = ({
  showSuccessScreen,
  selectedApptType,
  apptTime,
  stepNo,
  handleSelectApptType,
  selectedLocation,
  handleLocationChange,
  clinicLocations,
  fees,
  useKulcareApptsFlag,
  handleShowVerifyPhoneNumber,
  selectedApptDate,
  handleSetApptDate,
  timeSlots,
  handleSelectApptTime,
  dateTimeString,
  showPatientPhoneField,
  setMobileNumber,
  mobileNumber,
  handleVerifyMobileNumber,
  showVerifyOTPField,
  showTimer,
  handleResendCode,
  verificationCode,
  handleOTPChange,
  handleOTPVerification,
  showPatientForm,
  clinicPatientList,
  handleSelectClinicPatient,
  handlePatientNameChange,
  patientName,
  patientGender,
  handleGenderChange,
  handleSetPatientAge,
  patientAge,
  handleConfirmAppointment,
  handleAppDownload,
  handleEditTimings,
  selectedWalkinDate,
  clinicDetail,
  clinicDoctors,
  selectedDoctor,
  handleDoctorChange,
  displayFees,
  isProcessing,
  profileLoading,
  isFetchingTimeSlots,
  timeSlotDayLimit,
}) => {
  return (
    <div className="appointment_main_wrapper">
      {(clinicDetail?.takes_in_clinic ||
        clinicDetail?.takes_appointment ||
        clinicDetail?.takes_teleconsultation) && (
        <div className="appt_wrapper">
          {!showSuccessScreen && (
            <>
              <h1 className="font-size-16 font-weight-bold">
                Book appointment
              </h1>
              <div className="appointmnet-form-wrapper">
                {/* tabs start */}
                <AppointmentTypeTabs
                  doctorDetails={clinicDetail}
                  selectedApptType={selectedApptType}
                  apptTime={apptTime}
                  stepNo={stepNo}
                  handleSelectApptType={handleSelectApptType}
                />

                {/* tabs end*/}

                <div className="form_field appt-location-select">
                  <>
                    <label className="">
                      {stepNo === 1 ? "Select doctor" : "Doctor"}
                    </label>
                    <Select
                      value={selectedDoctor}
                      onChange={handleDoctorChange}
                      options={clinicDoctors}
                      styles={customStyles}
                      isDisabled={stepNo !== 1}
                    />
                  </>
                </div>

                {selectedApptType === "ONCALL" && (
                  <div className="form_field appt-location-select">
                    <>
                      <label className="">
                        {stepNo === 1
                          ? "Select clinic location"
                          : "Clinic location"}
                      </label>
                      <Select
                        value={selectedLocation}
                        onChange={handleLocationChange}
                        options={clinicLocations}
                        styles={customStyles}
                        isDisabled={stepNo !== 1}
                      />
                    </>
                  </div>
                )}
                {/* fee */}
                {fees && displayFees && fees !== 0 ? (
                  <div
                    className={`fees-info appt-booking-amount font-weight-bold fee_pos ${
                      selectedApptType === "TELEMEDICINE" ? "" : ""
                    }`}
                  >
                    Consultation fees:{" "}
                    <span className="font-weight-bold">
                      {fees ? ` ₹${fees}` : "--"}
                    </span>
                  </div>
                ) : (
                  ""
                )}

                {profileLoading && (
                  <>
                    <div className="skl-card-img skl-skeleton"></div>
                    <div className="skl-card-img skl-skeleton"></div>
                    <p className="skl-card-intro skl-skeleton"></p>
                  </>
                )}

                {stepNo === 1 &&
                  (useKulcareApptsFlag ||
                    selectedApptType === "TELEMEDICINE") &&
                  !profileLoading && (
                    <div className="step_1_wrap">
                      <div className="appt-calender-wrapper">
                        <DatePicker
                          selected={selectedApptDate}
                          onChange={handleSetApptDate}
                          minDate={new Date()}
                          inline={true}
                          popperClassName="dob-calendar-popper"
                          formatWeekDay={(nameOfDay) =>
                            nameOfDay.substring(0, 3)
                          }
                          maxDate={new Date(moment().add(timeSlotDayLimit, "days"))}
                        />
                        {/* calendar : start */}

                        {/* calendar : end */}
                        <div className="time-slots-section">
                          <div className="time-slots-main-block">
                            <div className="time-slots-block">
                              {/* <div className="slot-day">{formatedShortDate(selectedApptDate)}</div> */}
                              {isFetchingTimeSlots && (
                                <div className="skl-card-img skl-skeleton mt-12"></div>
                              )}
                              {/* slots */}
                              <div className="time-slots-wrapper">
                                {!isFetchingTimeSlots &&
                                  timeSlots &&
                                  timeSlots.length > 0 &&
                                  timeSlots.map((obj, index) => {
                                    return (
                                      <a
                                        href=""
                                        className={`appt-time ${
                                          apptTime === obj?.start_time
                                            ? "selected"
                                            : ""
                                        }`}
                                        key={`${obj?.start_time}_${obj?.end_time}_${obj.index}`}
                                        onClick={(e) =>
                                          handleSelectApptTime(e, obj)
                                        }
                                      >
                                        {obj?.start_time}
                                      </a>
                                    );
                                  })}

                                {timeSlots.length === 0 &&
                                  !isFetchingTimeSlots && (
                                    <div className="no-slot-block">
                                      {!selectedLocation &&
                                      selectedApptType === "ONCALL"
                                        ? "Please select a location to load slots"
                                        : "No slots available"}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Button
                            type="primary"
                            className={`full_width ${
                              isProcessing ? "disabled" : ""
                            }`}
                            onClick={() => handleShowVerifyPhoneNumber()}
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                {!useKulcareApptsFlag && selectedLocation && (
                  <div>
                    Appointment through kulcare are not enabled for this
                    location.
                  </div>
                )}

                {/* step 1: end */}
                {stepNo === 2 && (
                  <div className="step_2_wrap">
                    <div className="more_action_wrap">
                      {dateTimeString && (
                        <div className="font-weight-bold">{dateTimeString}</div>
                      )}
                      <a
                        href="#"
                        className="back_to_slot"
                        onClick={handleEditTimings}
                      >
                        Change
                      </a>
                    </div>

                    {/* enter mobile number block: start */}
                    {showPatientPhoneField && (
                      <VerifyMobileNumberForm
                        setMobileNumber={setMobileNumber}
                        mobileNumber={mobileNumber}
                        handleVerifyMobileNumber={handleVerifyMobileNumber}
                        isProcessing={isProcessing}
                      />
                    )}
                    {/* enter mobile number block: end */}

                    {/* enter mobile number block: start */}
                    {showVerifyOTPField && (
                      <VerifyOTPForm
                        showTimer={showTimer}
                        mobileNumber={mobileNumber}
                        handleResendCode={handleResendCode}
                        verificationCode={verificationCode}
                        handleOTPChange={handleOTPChange}
                        handleOTPVerification={handleOTPVerification}
                        isProcessing={isProcessing}
                      />
                    )}
                    {/* enter mobile number block: end */}

                    {/* show patient list and patient form */}
                    {showPatientForm && (
                      <PatientDetailForm
                        clinicPatientList={clinicPatientList}
                        handleSelectClinicPatient={handleSelectClinicPatient}
                        mobileNumber={mobileNumber}
                        handlePatientNameChange={handlePatientNameChange}
                        patientName={patientName}
                        patientGender={patientGender}
                        handleGenderChange={handleGenderChange}
                        customStyles={customStyles}
                        handleSetPatientAge={handleSetPatientAge}
                        patientAge={patientAge}
                        handleConfirmAppointment={handleConfirmAppointment}
                        isProcessing={isProcessing}
                      />
                    )}
                  </div>
                )}
                {/* step 2: end */}
              </div>
            </>
          )}
          {/* step 3: start */}
          {showSuccessScreen && selectedDoctor && (
            <SuccessContainer
              patientName={patientName}
              apptTime={apptTime}
              selectedApptDate={selectedApptDate}
              doctorDetails={selectedDoctor}
              fees={fees}
              selectedApptType={selectedApptType}
              selectedLocation={selectedLocation}
              handleAppDownload={handleAppDownload}
              displayFees={displayFees}
              parentContainer={"clinic_profile"}
            />
          )}
          {/* step 3: end */}
        </div>
      )}

      <Toaster />
    </div>
  );
};

export default BookAppointmentView;
